.balance-label, .balance-label-detail {
    background: #000;
    color: #fff;
    padding: 11px 10px;
    font-size: 19px;
    padding-left: 3rem !important;
    text-align: center;
    font-weight: 500;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    @include bp(380px) {
        font-size: 16px;
    }
    button {
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}
.inner-sidebar-content {
    background: #f0f8ff;
    min-height: calc(100vh - 106px);
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    .balance-sec {
        background: #262c32;
        border-radius: 8px;
        padding: 9px 10px;
        color: #fff;
        h5 {
            font-size: 17px;
            margin-bottom: 0.5rem;
        }
        h6 {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        span {
            background: #ffc800;
            border-radius: 6px;
            color: #000;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            padding: 4px 5px;
        }
    }
    .transaction-btn {
        padding: 12px 6px;
        line-height: normal;
        font-size: 15px;
        font-weight: 500;
        font-family: inherit;
        display: block;
    }
    .p2transfer-form {
        &.login-panel {
            label {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 0.5rem;
                display: block;
            }
            .form-switch .form-check-input {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
                background-position: 0;
                border-radius: 2em;
                margin-left: -2.5em;
                transition: background-position .15s ease-in-out;
                height: 13px !important;
                width: 13px !important;
                right: 0;
                left: unset;
                float: right;
                font-size: 24px;
                border: 1px solid transparent;
                border-color: #c0a024;
                &:checked {
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
                    background-position: 100%;
                    background-color: #17934e !important;
                    border-color: #00000026;
                }
            }
        }
        &.setting-panel .form-check {
            padding-left: 0;
            label {
                font-size: 15px !important;
                font-weight: 400 !important;
                margin-bottom: 0;
            }
        }
        .wallet-form-control {
            input {
                background: #d4e0e5;
                font-size: 19px;
                font-weight: 600;
                font-size: 15px;
                padding: 11px 12px;
                line-height: 1.5;
                height: auto;
                color: #212529;
                opacity: 0.6;
            }
            .theme-btn {
                height: 46px;
                display: flex;
                align-items: center;
                margin: 0;
                font-size: 20px;
                padding: 6px 29px;
                font-size: 20px;
                padding: 6px 29px;
                color: #000;
                background: #ffc800;
                border-color: #ffc800;
            }
        }
    }
    .form-floating {
        position: relative;
    }
    .login-panel .form-floating label {
        position: absolute;
        text-align: start;
        text-overflow: ellipsis;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        font-family: Inter;
        font-size: 13.44px;
        padding: 9px;
        display: flex;
        align-items: center;
        color: #212529;
        opacity: 0.7;
        transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    }
    .login-panel .form-floating .form-control {
        border: 2px solid #c0a024;
        border-radius: 0.5rem;
        font-family: Inter;
        height: 2.857142857142857rem;
        min-height: 2.857142857142857rem;
    }
    .form-floating>.form-control-plaintext~label:after, .form-floating>.form-control:focus~label:after, .form-floating>.form-control:not(:placeholder-shown)~label:after, .form-floating>.form-select~label:after {
        content: "";
        height: 1.5em;
        inset: 1rem 0.375rem;
        position: absolute;
        z-index: -1;
        background-color: #fff;
        top: 0;
    }
    .login-panel .form-floating>.form-control-plaintext~label, .p2transfer-form.login-panel .form-floating>.form-control:focus~label, .p2transfer-form.login-panel .form-floating>.form-control:not(:placeholder-shown)~label, .p2transfer-form.login-panel .form-floating>.form-select~label {
        background: #fff;
        height: auto;
        margin-left: 5px;
        max-width: max-content;
        opacity: 1 !important;
        padding: 0 8px !important;
        transform: translateY(-.7rem) translateX(.15rem);
    }
    .pbu-floating {
        label {
            margin-left: 65px;
        }
        span {
            top: 0;
            bottom: 0;
            border-right: 1px solid #00000026;
        }
        input {
            padding-left: 75px;
        }
    }
    .p2transfer-form.login-panel .pbu-floating .form-floating>.form-control-plaintext~label,
    .p2transfer-form.login-panel .pbu-floating .form-floating>.form-control:focus~label, .p2transfer-form.login-panel .pbu-floating .form-floating>.form-control:not(:placeholder-shown)~label,
    .p2transfer-form.login-panel .pbu-floating .form-floating>.form-select~label {
        margin-left: 69px;
    }
    .lower-btn {
        display: flex;
        margin: 0 -10px;
        button {
            width: 100%;
            height: 44px;
            margin: 0;
            background: #fff;
            font-size: 20px !important;
            border-radius: 6px;
            &.theme-btn {
                background: #ffc800;
                border-color: #ffc800;
                color: #000;
            }
        }
    }
    .overlay-wallet {
        align-items: center;
        background-color: rgb(38 44 50/.8);
        border-radius: 10px;
        color: #fff;
        display: flex;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        width: 100%;
    }
    //bet-histroy 
    .sports-tab-panel-item {
        >div {
            margin: 0 5px;
            font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            h3 {
                font-size: 12px;
                text-align: center;
            }
        }
        &.active {
            h3 {
                position: relative;
                &::after {
                    background: #000;
                    border-radius: 10px;
                    bottom: 0;
                    content: "";
                    height: 1.6875px;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    bottom: 0px;
                    width: 49px;
                }
            }
        }
    }
    .pl-filter {
        background-color: #262c32;
        .select-container {
            background: #262c32;
            padding: 8px;
            text-align: center;
        }
        .select-container {
            .dropdown-toggle {
                align-items: center;
                background: transparent !important;
                border-color: #ffc800;
                border-radius: 5px;
                color: #ffc800;
                display: flex;
                // font-size: 19px;
                font-weight: 500;
                justify-content: space-between;
                width: 100%;
                font-size: 15px;
                position: relative;
                padding: 5px 10px;
                text-transform: capitalize;
                font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                &::after {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBgENBAHr2nWeAAACD0lEQVRo3u2Yv0scQRTHP29js2I4IWVSeWkDKYRYCDEGCxFSSIqA/4XaeKKdZ+GpbdAmTZpoqkOsJAaE7JGTBNLedSm8QrQQQ0T3pdDxkIvZXdxfkPk2AwPvzft8dophwcbGxsbGBvU/KXR1qV//CoVC1vPEzqffFHp7DafZd9T33sDICOK2oNVCzi/g+FjVW4ONDdV6Hbq7swaIDnw5t6p3CJub8LsGR0eI+xEODgy3qF97BY0GoiUoFjs7yUvY3UXufYaxMZH+fjg9zRowCBy9eA5bW4juwNDQX7jK0Gw6oH3/vPKmwVXDvN6I0ODtij4oFBxExmFpKfCEnIqIDm54eAeVirQbeU9gYQFYh1IpxNkPYG8P/fUYRkfFeSFwcpI++PkHqFYRxmF4OLiQ17C6Ks7AT5iclM7G+RYRF7jZltsPypeIuMEDBeRFRFLgoQVkJSJp8MgC0hKRFriJE1WAyMAPmJ1F+Q6LiyFKDmFwEHEbsL19+RTt6cka/JonakHH+b73FsplhKcwMxOi5MaNQO7vg++nDR6bgLaIWhEqFUTfw9RUcMHVE/t6kpAPGJUJWF4W51kTpqfvOndsAtoiIt+IkI3j+eKJC4hdRELgiQu4s4iEwVOPEaHqeaB66+p7j2BlJet5kxOhtSLMz6t6VTg7u7nOzWU9X4oizB+bLw/BdbOex8bGxsbmf8wfxeWl3wymwowAAAAASUVORK5CYII=') !important;
                    background-repeat: no-repeat;
                    background-size: 13px;
                    border: 0;
                    height: 15px;
                    transition: .3s;
                    width: 23px;
                    content: "";
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                }
                &.show {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
            .dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
                position: relative;
            }
            .bet-history-dropdown .btn.show:active, .bet-history-dropdown .dropdown-toggle:active, .bet-history-dropdown .dropdown-toggle:hover {
                background: transparent !important;
            }
            .dropdown-toggle.show, .dropdown-toggle:active, .dropdown-toggle:hover {
                border-color: #ffc800 !important;
                color: #ffc800 !important;
            }
            .dropdown-menu {
                background: #262c32;
                border: 1px solid #ffc800;
                border-radius: 0;
                border-radius: 0 0 8px 7px;
                border-top: 0;
                color: #ffc800;
                margin-top: -7px;
                width: 100%;
                position: absolute;
                inset: 0px auto auto 0px;
                transform: translate3d(0px, 36px, 0px);
                display: none;
                z-index: 999;
                &.show {
                    display: block;
                }
                a {
                    font-size: 15px;
                    padding: 6px 12px;
                    display: block;
                    color: #ffc800;
                    font-weight: 500;
                    text-align: left;
                }
            }
            .pl-filter-first {
                align-items: center;
                display: flex;
                justify-content: space-between;
                &-time {
                    align-items: center;
                    border: 2px solid #ffc800;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-evenly;
                    padding: 3px;
                    width: 65%;
                    svg {
                        color: #ffc800;
                        font-size: 20px;
                    }
                    .truncate {
                        color: #fff;
                        font-size: 16px;
                        @include bp(480px) {
                            font-size: 4vw;
                        }
                    }
                }
                button {
                    background: #ffc800;
                    background-color: #ffc800;
                    border: none;
                    color: #000;
                    font-weight: 700;
                    margin: 0 !important;
                    font-size: 19.9733px;
                    padding: 7px 14px;
                    @include bp(480px) {
                        font-size: 4vw;
                        padding: 1vw 3vw;
                    }
                }
            }
        }
        .order-checkbox {
            font-size: 15px;
            color: #fff;
            label.text-yellow {
                font-family: Inter;
                font-weight: 600;
                margin-right: 13px;
            }
            .form-check {
                align-items: center;
                gap: 5px;
                margin-right: 5px;
                input {
                    width: 14px !important;
                    height: 14px !important;
                    margin: 0;
                    background-color: #fff !important;
                    appearance: auto;
                }
            }
        }
    }
    table {
        tr {
            border-bottom: 1px solid rgb(222, 226, 230);
            font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            td {
                padding: .5rem;
                font-size: 1rem;
                @include bp(480px) {
                    font-size: 4vw;
                }
                button {
                    margin: 0;
                    background: #ffc800;
                    border-color: #ffc800;
                    color: #000;
                    font-weight: 600;
                    padding: 9px 32px;
                    transition: .5s;
                    @include bp(480px) {
                        padding: 0.5vw 2.5vw !important;
                        font-size: 4vw !important;
                    }
                }
            }
        }
    }
}
.active-log-table {
    table {
        border: 1px solid rgb(222, 226, 230);
        border-radius: 0.5rem !important;
        th {
            background: #d5f2b8;
        }
        th, tr td {
            font-size: 15px;
            text-align: left;
            font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            strong {
                font-size: 15px;
            }
        }
        &.table-bordered>:not(caption)>*>* {
            padding: .5rem;
            border-width: 1px;
        }
    }
}
//.market-depth-modal  modal
.market-depth-modal {
    background: #fff;
    border-radius: 12px 12px 0 0;
    bottom: 0;
    box-shadow: 10px 10px 10px 10px;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99999;
    min-height: auto;
    @include bp(480px) {
        position: fixed;
    }
    .rdrDateDisplayWrapper {
        display: none;
    }
    .market-title {
        background: #000;
        color: #fff;
        padding: 13px 6px;
        text-align: center;
        position: relative;
        h4 {
            font-size: 19px;
            margin: 0;
        }
        button {
            // right: 0;
            top: 0;
            background: transparent;
            color: #fff;
            margin: auto;
            height: 100%;
        }
    }
    .calendar-button {
        align-items: center;
        display: flex;
        justify-content: space-around;
        width: 100%;
        button {
            border: none;
            color: #000;
            font-weight: 700;
            background: transparent;
            background-color: #ffc800;
            padding: 5px 10px;
        }
    }
}
.balance-deposit-sec {
    table th {
        background-color: #d4e0e5;
        padding: 8.773px;
        border-color: rgb(222, 226, 230);
        text-align: left;
        @include bp(mob) {
            font-size: 4vw;
        }
    }
    .table-bordered>:not(caption)>* {
        // border-width: 0 1px;
        border-width: 1px;
        border-color: rgb(222, 226, 230);
    }
    td {
        border-width: 1px;
        border-color: rgb(222, 226, 230);
    }
}
.bet-name {
    &.back {
        background: #a1d2f4;
        font-size: 12.48px;
    }
    &.lay {
        background: #fcc6d1;
        font-size: 12.48px;
    }
}
.master-pass {
    font-family: Inter;
    font-weight: 600;
    margin-left: 10px;
    padding-left: 15px;
    position: relative;
    @include bp(mob) {
        margin-left: 0;
        padding-left: 6px;
    }
}
.table>:not(caption)>*>* {
    padding: 5.973px;
    background-color: #fff;
}
.arrow-icon {
    font-size: 12px;
    margin: 0 5px;
    vertical-align: middle;
}
.current-bets-table {
    border-bottom: 8px solid #d4e0e5;
    border-radius: 14px;
    overflow: hidden;
    .table-bordered>:not(caption)>*>* {
        border-width: 0 1px;
        border-color: rgb(222, 226, 230);
    }
    &.show {
        border-color: #9cb1bd;
    }
}
.current-bets-table .odds-table th {
    background: #d4e0e5;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    padding-left: 26px;
    text-align: left;
    @include bp(mob) {
        font-size: 12px;
        padding-left: 18px;
    }
}
.current-bets-table .lay-back td {
    font-weight: 700;
    padding: 14px 19px;
    @include bp(mob) {
        padding: 12px 9px;
    }
}
.current-bets-table.show {
    border-color: #9cb1bd
}
.bets-table thead {
    background: #9cb1bd
}
.bets-table thead th {
    padding: 8px 11px;
    text-align: left;
    @include bp(mob) {
        font-size: 13px;
    }
}
.current-bets-table td {
    width: 33.33%;
    @include bp(480px) {
        font-size: 14px !important;
    }
}
.show-hide-bet {
    background: #d4e0e5;
    border: none;
    border-radius: 0 0 14px 14px;
    left: 50%;
    padding: 2px 47px 8px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}
.show-hide-bet.arrow-up {
    background: #9cb1bd
}
.show-hide-bet svg {
    font-size: 21px;
    transition: .4s
}
.show-hide-bet.arrow-up svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}
.all-bets {
    margin-bottom: 48px;
}


.pl-filter {
    background-color: #262c32;
    .select-container {
        background: #262c32;
        padding: 8px;
        text-align: center;
    }
    .select-container {
        .dropdown-toggle {
            align-items: center;
            background: transparent !important;
            border-color: #ffc800;
            border-radius: 5px;
            color: #ffc800;
            display: flex;
            // font-size: 19px;
            font-weight: 500;
            justify-content: space-between;
            width: 100%;
            font-size: 15px;
            position: relative;
            padding: 5px 10px;
            text-transform: capitalize;
            font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            &::after {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBgENBAHr2nWeAAACD0lEQVRo3u2Yv0scQRTHP29js2I4IWVSeWkDKYRYCDEGCxFSSIqA/4XaeKKdZ+GpbdAmTZpoqkOsJAaE7JGTBNLedSm8QrQQQ0T3pdDxkIvZXdxfkPk2AwPvzft8dophwcbGxsbGBvU/KXR1qV//CoVC1vPEzqffFHp7DafZd9T33sDICOK2oNVCzi/g+FjVW4ONDdV6Hbq7swaIDnw5t6p3CJub8LsGR0eI+xEODgy3qF97BY0GoiUoFjs7yUvY3UXufYaxMZH+fjg9zRowCBy9eA5bW4juwNDQX7jK0Gw6oH3/vPKmwVXDvN6I0ODtij4oFBxExmFpKfCEnIqIDm54eAeVirQbeU9gYQFYh1IpxNkPYG8P/fUYRkfFeSFwcpI++PkHqFYRxmF4OLiQ17C6Ks7AT5iclM7G+RYRF7jZltsPypeIuMEDBeRFRFLgoQVkJSJp8MgC0hKRFriJE1WAyMAPmJ1F+Q6LiyFKDmFwEHEbsL19+RTt6cka/JonakHH+b73FsplhKcwMxOi5MaNQO7vg++nDR6bgLaIWhEqFUTfw9RUcMHVE/t6kpAPGJUJWF4W51kTpqfvOndsAtoiIt+IkI3j+eKJC4hdRELgiQu4s4iEwVOPEaHqeaB66+p7j2BlJet5kxOhtSLMz6t6VTg7u7nOzWU9X4oizB+bLw/BdbOex8bGxsbmf8wfxeWl3wymwowAAAAASUVORK5CYII=') !important;
                background-repeat: no-repeat;
                background-size: 13px;
                border: 0;
                height: 15px;
                transition: .3s;
                width: 23px;
                content: "";
                display: inline-block;
                margin-left: 0.255em;
                vertical-align: 0.255em;
            }
            &.show {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        .dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
            position: relative;
        }
        .bet-history-dropdown .btn.show:active, .bet-history-dropdown .dropdown-toggle:active, .bet-history-dropdown .dropdown-toggle:hover {
            background: transparent !important;
        }
        .dropdown-toggle.show, .dropdown-toggle:active, .dropdown-toggle:hover {
            border-color: #ffc800 !important;
            color: #ffc800 !important;
        }
        .dropdown-menu {
            background: #262c32;
            border: 1px solid #ffc800;
            border-radius: 0;
            border-radius: 0 0 8px 7px;
            border-top: 0;
            color: #ffc800;
            margin-top: -7px;
            width: 100%;
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(0px, 36px, 0px);
            display: none;
            z-index: 999;
            &.show {
                display: block;
            }
            a {
                font-size: 15px;
                padding: 6px 12px;
                display: block;
                color: #ffc800;
                font-weight: 500;
                text-align: left;
            }
        }
        .pl-filter-first {
            align-items: center;
            display: flex;
            justify-content: space-between;
            &-time {
                align-items: center;
                border: 2px solid #ffc800;
                border-radius: 5px;
                display: flex;
                justify-content: space-evenly;
                padding: 3px;
                width: 65%;
                svg {
                    color: #ffc800;
                    font-size: 20px;
                }
                .truncate {
                    color: #fff;
                    font-size: 16px;
                    @include bp(480px) {
                        font-size: 4vw;
                    }
                }
            }
            button {
                background: #ffc800;
                background-color: #ffc800;
                border: none;
                color: #000;
                font-weight: 700;
                margin: 0 !important;
                font-size: 19.9733px;
                padding: 7px 14px;
                @include bp(480px) {
                    font-size: 4vw;
                    padding: 1vw 3vw;
                }
            }
        }
    }
}