.full-wrap {
    position: relative;
    max-width: 100%;
    // height: calc(100% - 105px);
    margin: 0 auto;
    @include bp(tab) {
        width: 100%;
        max-width: 1000%;
    }
    .col-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 118px);
        overflow: auto;
    }
    .col-right {
        width: 26%;
        padding-left: 15px;
        overflow: hidden;
        @include bp(tab) {
            display: none;
        }
    }
}
.slip-wrap {
    background-color: #fff;
    &.no-open-bet_slip {
        height: calc(100% - 0px);
        position: relative;
    }
    h3 {
        box-shadow: 0 2px 0 #0000001a;
        color: #fff;
        font-size: 12px;
        line-height: 25px;
        font-weight: normal;
        padding-left: 10px;
        background-repeat: repeat-x;
        background-image: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
        .to-expand,
        a {
            background-position: 100% -299px;
            background-image: url("../../../public/images/icon_image.png");
            background-repeat: no-repeat;
            display: block;
            color: #fff;
        }
    }
    p {
        font-size: 13px;
        padding: 15px 5px 2px;
        text-align: center;
    }
}
.tab_list {
    display: inline-flex;
    vertical-align: middle;
    width: 100%;
    justify-content: space-between;
    background-color: #172832;
    color: #fff;
    font-size: 3.46667vw;
    font-weight: 700;
    >ul {
        @include bp(tab) {
            border: 1px solid #fff !important;
            border-radius: 1.6vw !important;
            flex: 1 1 !important;
            margin: 1.6vw 1.86667vw !important;
            padding: 0 !important;
        }
    }
    ul.btn-group {
        border-left: 1px solid #3b5160;
        text-align: center;
        min-width: unset;
        background-color: #172832;
        width: 100%;
        padding: 5px;
        border-radius: 0;
        border: none;
        margin: 0;
    }
}
.col-matched,
.col-visit,
.col-home,
.col-draw {
    width: 12%;
    @include bp(tab) {
        display: none;
    }
}
.game-wrap,
.game-highlight-wrap,
.game-wrap-horse {
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
    background-color: #fff;
    @include bp(mini) {
        border-bottom-color: #070707;
        border-bottom: 2px solid;
    }
    h3 {
        background: #243a48;
        color: #fff;
        font-size: 12px;
        line-height: 25px;
        font-weight: normal;
        padding-left: 10px;
        .to-expand {
            flex: 1;
            background-position: 100% -1944px;
            background-image: url("../../../public/images/front-all-icon1.png");
            background-repeat: no-repeat;
            display: block;
            color: #fff;
            @include bp(mini) {
                text-align: center;
                background-image: unset !important;
            }
        }
        @include bp(tab) {
            background-image: linear-gradient(-180deg, #2e4b5e, #243a48 82%);
            color: #fff;
            font-size: 3.73333vw;
            line-height: 2.2;
            font-weight: bold;
            padding-left: 0;
        }
    }
}
.slip-head,
.matched-head {
    background-color: #ced5da;
    padding: 0 10px;
    box-shadow: inset 0 2px 0 #0000001a;
    display: flex;
    @include bp(tab) {
        display: none;
    }
    li {
        padding: 5px 0;
        text-align: center;
    }
    .col-matched {
        text-align: right;
    }
}
.col3 .col-game,
.col3 .game-list-col dt,
.game-list-col .col3 dt {
    width: calc(51.4% - 14px);
    @include bp(tab) {
        width: calc(100% - 35px) !important;
    }
    @include bp(tab) {
        width: calc(100% - 9.66667vw) !important;
    }
}
.game-list-col {
    position: relative;
    padding: 2px 6px 2px 10px;
    border-bottom: 1px solid #e0e6e6;
    display: flex;
    @include bp(tab) {
        padding: 1.6vw 0 1.86667vw 2.66667vw;
    }
    &:hover {
        background-color: #eff2f2;
    }
    dt {
        font-weight: bold;
        vertical-align: middle;
        padding: 2px 0 0px 0;
        .icon-in_play {
            margin-top: 4px;
            margin-right: 5px;
            background-position: -406px -2845px;
            height: 8px;
            width: 8px;
            background-image: url("../../../public/images/front-all-icon1.png");
            float: left;
            @include bp(mini) {
                margin-top: 6px;
            }
            position: relative;
            &:before {
                border: 1px solid rgba(51, 51, 51, .4);
                border-radius: 50%;
                content: "";
                height: 2.66667vw;
                margin-right: 1.33333vw;
                width: 2.66667vw;
                left: 2.66667vw;
                margin-top: 0.26667vw;
                position: absolute;
                top: 50%;
                background-color: #6bbd11;
            }
        }
        .game-low_liq {
            display: inline-block;
            border-radius: 3px;
            height: 16px;
            line-height: 16px;
            font-size: 10px;
            font-weight: bold;
            vertical-align: text-top;
            background-color: #d0021b;
            color: white;
        }
        span {
            padding: 0 3px;
            font-weight: normal;
        }
        a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 13px);
            margin-bottom: -2px;
            color: #2789ce !important;
            font-size: 12px;
            display: block;
            &:hover {
                color: #2789ce !important;
            }
            @include bp(mini) {
                display: block;
                font-size: 13.5px;
            }
            @include bp(tab) {
                font-size: 4vw;
            }
            &:hover {
                text-decoration: underline;
            }
            &.vsName {
                position: relative;
                padding-left: 14px;
                &::before {
                    border: 1px solid rgba(51, 51, 51, 0.4);
                    border-radius: 50%;
                    content: "";
                    height: 8px;
                    width: 8px;
                    display: block;
                    left: 0px;
                    position: absolute;
                    top: 5px;
                    @include bp(tab) {
                        height: 2.66667vw;
                        width: 2.66667vw;
                        top: 1.66667vw;
                    }
                }
                @include bp(mini) {
                    padding-left: 16px;
                }
                @include bp(tab) {
                    padding-left: 4.2vw;
                }
                &.active {
                    &::before {
                        background-color: #6bbd11;
                    }
                }
            }
        }
        .game-list-time {
            color: #777;
            // max-width: 105px;
            margin-left: 9px;
            clear: both;
            float: left;
            white-space: nowrap;
            @include bp(mini) {
                float: unset;
                margin-left: 0;
                color: #508d0e;
            }
            @include bp(tab) {
                font-size: 12px;
                padding-left: 0;
            }
            @include bp(412px) {
                font-size: 13.184px;
            }
        }
        .in_play {
            padding: 0;
            font-weight: bold !important;
            color: #508d0e;
            margin: 7px;
            @include bp(mini) {
                font-weight: 400;
                margin-left: 0;
            }
            @include bp(tab) {
                font-size: 3.2vw;
                line-height: 1.1;
                margin-right: 1.33333vw;
            }
        }
        .game-fancy,
        .game-sportsbook,
        .game-bookmaker,
        .game-live {
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            height: 16px;
            padding: 0 !important;
            width: 16px;
            background-color: #1876b2;
            float: left;
            font-size: 0;
            text-indent: -99999px;
            @include bp(mob) {
                width: 14px;
                height: 12.7px;
            }
            @include bp(tab) {
                width: 4.53333vw;
                height: 4vw;
                justify-content: center;
                margin-right: 1.33333vw !important;
                padding: 0;
                border-radius: .8vw;
            }
        }
        .game-live {
            vertical-align: text-top;
            margin-right: 0;
            &::after {
                content: "";
                background-size: cover;
                width: 11px;
                height: 11px;
                background-image: url("../../../public/images/in-play.svg");
                background-repeat: no-repeat;
                @include bp(mob) {
                    width: 7px;
                    height: 7px;
                }
                @include bp(tab) {
                    background-size: cover;
                    content: "";
                    height: 2.93333vw;
                    width: 2.93333vw;
                }
            }
        }
        .game-sportsbook {
            background-color: #e4550f;
            padding: 0 !important;
            &::after {
                content: "";
                background-size: cover;
                width: 11px;
                height: 11px;
                background-image: url("../../../public/images/p.svg");
                background-repeat: no-repeat;
                @include bp(mob) {
                    width: 9px;
                    height: 9px;
                }
            }
        }
        .game-E {
            display: inline-flex;
            right: 0;
            height: 16px;
            background-color: #fff;
            line-height: 16px;
            font-size: 10px;
            color: #1f5172;
            border-radius: 4px;
            border: 1px solid #1f5172;
            margin-right: 1px;
            padding: 0 3px 0 0;
            overflow: hidden;
            padding: 0 3px 0 0;
            vertical-align: text-top;
            box-sizing: border-box;
            float: left;
            &:not(*:root) {
                transform: scale(0.9);
            }
            i {
                position: relative;
                width: 18px;
                height: 100%;
                background-color: #1f5172;
                margin-right: 2px;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                display: flex;
                &::before {
                    content: "";
                    width: 7px;
                    height: 8px;
                    background-image: url("../../../public/images/e.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-left: -3px;
                    display: block;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    content: "";
                    border-left: 5px solid transparent;
                    border-bottom: 14px solid #fff;
                    display: block;
                }
            }
        }
        .game-fancy {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            width: 17px;
            height: 16px;
            padding: 0 !important;
            float: left;
            vertical-align: text-top;
            margin-right: 0;
            background-color: #0a92a5;
            font-size: 0;
            text-indent: -99999px;
            @include bp(mob) {
                height: 12.7px;
            }
        }
        .in-play {
            &.game-bookmaker,
            &.game-fancy,
            &.game-sportsbook,
            &.game-live {
                float: left;
                vertical-align: text-top;
                margin-right: 0;
                position: relative;
                overflow: hidden;
                width: 35px;
                @include bp(tab) {
                    height: 4vw;
                    width: 8.53333vw;
                    margin-right: 1.33333vw !important;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 17px;
                    height: 16px;
                    background-size: 10px;
                    background-size: 10px;
                    border-radius: 3px 0 0 3px;
                    display: block;
                    background-image: url("../../../public/images/time.svg");
                    background-repeat: no-repeat;
                    background-color: #60ba1e;
                    background-position: center;
                    @include bp(mob) {
                        height: 12.7px;
                    }
                    @include bp(tab) {
                        background-size: 2.66667vw;
                        content: "";
                        height: 4vw;
                        width: 4.26667vw;
                    }
                }
                &::after {
                    content: "";
                    background-size: cover;
                    width: 12px;
                    height: 12px;
                    margin-left: 19px;
                    background-image: url("../../../public/images/b.svg");
                    background-repeat: no-repeat;
                    @include bp(mob) {
                        width: 8px;
                        height: 9px;
                    }
                    @include bp(tab) {
                        background-size: 2.66667vw;
                        content: "";
                        height: 4vw;
                        width: 4.26667vw;
                        margin-left: 4.26667vw;
                        background-position: center;
                    }
                }
            }
            &.game-fancy {
                &::after {
                    background-image: url("../../../public/images/f.svg");
                }
            }
        }
    }
    dd {
        position: relative;
        align-self: center;
        float: left;
        a {
            width: 49%;
            font-size: 11px;
            line-height: 26px;
            padding: 4px 0;
            cursor: pointer;
            display: block;
            text-align: center;
            float: left;
        }
    }
    .col-matched,
    .col-visit,
    .col-home,
    .col-draw {
        color: #777;
        padding: 2px 2px 2px 0;
        text-align: right;
        line-height: 24px;
        border-right: 1px solid #eee;
        width: 12%;
        padding: 2px 3px;
    }
    .col-info {
        padding: 2px 0;
        float: left;
        .add-pin,
        .delete-pin {
            padding: 0;
            // background-position: -398px -57px;
            // height: 21px;
            width: 31px;
            background-image: url("./../../assets/images/pin.svg");
            background-repeat: no-repeat;
            float: left;
            text-align: center;
            display: block;
            font-size: 0;
            text-indent: -99999px;
            background-position: center;
            background-size: 20px;
            @include bp(tab) {
                height: 5.5vw;
                margin-right: 1.86667vw;
                width: 7.66667vw;
                background-size: contain;
                line-height: 30px;
                background-position: left;
            }
        }
    }
}
.suspend {
    position: absolute;
    width: 36.6%;
    height: calc(100% - 2px);
    background-color: #fff;
    border: 1px solid #d0021b;
    color: #d0021b;
    margin-left: calc(64% - 40px);
    z-index: 5;
    text-align: center;
}
dd .suspend {
    width: calc(100% - 8px);
    height: calc(100% - 6px);
    margin-left: 0;
}
.btn-back,
.btn-lay {
    color: #1e1e1e;
    font-weight: bold;
    margin-right: 1%;
    background-color: #72bbef;
    border-left-color: #fff;
}
.btn-lay {
    background-color: #faa9ba;
    border-left-color: #fff;
}
.close.game-wrap,
.close.game-highlight-wrap,
.close.game-wrap-horse {
    border-bottom-color: #eee;
}
.close.slip-wrap,
.close.matched-wrap,
.close.live-wrap,
.close.tv-wrap,
.close.game-wrap,
.close.game-highlight-wrap,
.close.game-wrap-horse,
.close.favor-wrap,
.close.sub_path {
    height: 25px;
    overflow: hidden;
    @include bp(tab) {
        height: 8.4vw;
    }
}
.disabled .col-visit a,
.disabled .col-home a,
.disabled .col-draw a,
.disabled .col-matched a,
.disabled.col-visit a,
.disabled.col-home a,
.disabled.col-draw a,
.disabled.col-matched a {
    position: relative;
    cursor: not-allowed;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        background: rgba(51, 51, 51, 0.2) url("../../../public/images/bg-disabled.png");
        width: 100%;
        height: 100%;
        display: block;
    }
}
.input-list,
.filter-list {
    margin-bottom: 5px;
    margin-right: 5px;
    display: block;
    li {
        list-style: none;
        line-height: 25px;
        margin: 0 5px 5px 0;
        white-space: nowrap;
        display: block;
        float: left;
    }
}
.function-wrap {
    position: relative;
    padding: 10px 10px 0;
    background-color: #e0e6e6;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    .filter-list {
        display: inline-block;
        width: 100%;
        li {
            margin: 0 5px 0px 0;
        }
        .filter-head {
            width: 85px;
            font-weight: bold;
            float: left;
        }
        .filter-content {
            width: calc(100% - 195px);
            height: 25px;
            span {
                margin-right: 5px;
                color: #243a48;
                padding-left: 10px;
                background-position: -23px -166px;
                background-image: url("../../../public/images/report.png");
                background-repeat: no-repeat;
                &.filter-first {
                    background: none;
                    padding-left: 0;
                }
            }
        }
        .filter-btn {
            width: 95px;
            .btn {
                width: 93px;
                margin: 0;
                font-weight: normal;
                background: #eee url("../../../public/images/bg-btn.gif") repeat-x;
                border: 1px solid #bbb;
                border-radius: 4px;
                color: #1e1e1e;
                line-height: 23px;
                font-size: 12px;
                display: block;
                text-align: center;
                padding: 0;
            }
        }
    }
}
.filter-pop {
    display: none;
    &:not(:target) {
        display: none;
        position: absolute;
        right: 0;
        width: 500px;
        border-radius: 4px;
        background-color: #fff;
        padding: 8px 8px 0;
        margin-left: -500px;
        z-index: 3;
        box-shadow: 0 4px 5px #00000080;
        ul {
            margin-bottom: 5px;
            display: inline-block;
            width: 100%;
            li {
                float: left;
                width: 240px;
                list-style: none;
                margin-bottom: 3px;
                label {
                    cursor: pointer;
                }
            }
        }
    }
    .btn-wrap {
        border-top: 1px solid #ccc;
        padding-top: 8px;
        margin-bottom: 7px;
        display: flex;
        .btn-send {
            width: 20%;
            margin-right: 10px;
            display: block;
            background: #121212 url("../../../public/images/bg-send-skyEX.png") repeat-x;
            color: #f8d61c;
            border-color: #000;
            &:hover {
                background: #121212 url("../../../public/images/bg-send_hover-skyEX.png") repeat-x;
            }
        }
        .btn {
            width: 15%;
            margin-right: 10px
        }
    }
}
.over-wrap {
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    .sport_list-wrap {
        background-color: #fff;
        border-bottom: 1px solid #7e97a7;
        .game-list-col {
            dt {
                width: 70px;
                color: #243a48;
                padding: 8px 0;
                font-weight: bold;
                vertical-align: middle;
                float: left;
            }
            dd {
                padding: 8px 5px 8px 0;
                display: flex;
                align-items: center;
                .fromto {
                    margin: 0 5px;
                    background-position: -20px -182px;
                    height: 8px;
                    width: 7px;
                    background-image: url("../../../public/images/report.png");
                    background-repeat: no-repeat;
                }
                a {
                    float: none;
                    display: inline;
                    font-size: 12px;
                    line-height: 15px;
                    width: auto;
                    padding: 0;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.no-data {
    background-color: #fff;
    padding: 10px 10px 5px !important;
    p {
        margin-bottom: 7px;
        text-align: left;
    }
}
.game-highlight-wrap {
    .game-wrap {
        border: none !important;
    }
    .highligths-txt {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 3px 20px;
        background-color: #ffb80c !important;
    }
    .highlight-sorting {
        display: flex;
        align-items: center;
        label {
            cursor: pointer;
            font-weight: normal;
            color: #1e1e1e;
            margin-right: 10px;
        }
        .select {
            width: 108px;
            height: 23px;
            border: 1px solid;
            border-color: #00000066;
            box-shadow: inset 0px 1px 0px 0px #ffffff80;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.2);
            margin-right: 2px;
            position: relative;
            margin-left: 7px;
            display: inline-block;
            position: relative;
            &::after {
                content: "▾";
                font-size: 11px;
                color: inherit;
                display: block;
                left: 95px;
                top: -2px;
                position: absolute;
                z-index: 0;
                pointer-events: none;
            }
            select {
                width: 108px;
                height: 23px;
                background: none;
                border: none;
                color: inherit;
                appearance: none;
                padding-left: 9px;
                margin: 0;
                z-index: 1;
                position: relative;
            }
        }
    }
}
.tab_content {
    .today_tab {
        background-color: #e0e6e6;
        border-bottom: 1px solid #7e97a7;
        margin-bottom: 10px;
        padding: 10px 10px 0;
        position: relative;
        .sports_filters {
            align-items: center;
            display: flex;
            &_left {
                display: flex;
                h5 {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                    white-space: nowrap;
                }
                ul {
                    display: flex;
                    align-items: center;
                    li {
                        color: #7e97a7;
                        list-style-type: disc;
                        padding: 0 20px 0 0;
                        white-space: nowrap;
                        &:first-child {
                            list-style-type: none;
                            margin-left: 10px;
                        }
                    }
                }
            }
            &_right {
                margin-bottom: 8px;
                margin-left: auto;
                .close_btn {
                    border: 1px solid #bbb;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 5px 30px;
                    margin-bottom: 0;
                    margin-right: 0;
                    cursor: pointer;
                }
                .tooltip_modal {
                    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.7);
                    max-width: 500px;
                    padding: 5px 0 0;
                    width: 100%;
                    min-width: 460px;
                    position: absolute;
                    top: 100%;
                    right: 6px;
                    border-radius: 8px;
                    padding-top: 5px;
                    background: #fff;
                    border: 1px solid rgba(0, 0, 0, .176);
                    z-index: 999;
                    .popover-arrow {
                        &::before, &::after {
                            border: 0 solid transparent;
                            content: "";
                            display: block;
                            position: absolute;
                        }
                        &::before {
                            border-bottom-color: rgba(0, 0, 0, .175);
                            top: -14px;
                            border-width: 0 8px 8px 8px;
                        }
                        &::after {
                            border-bottom-color: #fff;
                            top: -12px;
                            border-width: 0 8px 8px 8px;
                        }
                    }
                    .filter_menu_wrp {
                        &_top {
                            display: flex;
                            width: 100%;
                        }
                        &_left {
                            width: 50%;
                        }
                        &_right {
                            width: 50%;
                        }
                        .form-check {
                            display: block;
                            margin-bottom: 0.125rem;
                            min-height: 1.5rem;
                            input {
                                width: 15px !important;
                                height: 15px !important;
                                background-color: #fff;
                                vertical-align: middle;
                                margin-right: 6px;
                                border-radius: 0.25em;
                                background-position: 50%;
                                background-repeat: no-repeat;
                                background-size: contain;
                                border: 1px solid rgba(0, 0, 0, .25);
                                height: 12px !important;
                                width: 12px !important;
                                vertical-align: middle;
                                margin-bottom: 1px !important;
                                &:checked {
                                    background-image: url("../../../public/images/checkmark.svg");
                                    background-color: #0d6efd !important;
                                    border-color: #0d6efd;
                                }
                            }
                        }
                        &_bottom {
                            display: flex;
                            justify-content: center;
                            border-top: 1px solid #ccc;
                            padding: 5px;
                            li {
                                width: 50%;
                                button {
                                    font-size: 13px;
                                    min-height: 26px;
                                    width: calc(100% - 10px);
                                    cursor: pointer;
                                }
                            }
                            .btn-send {
                                background: #444444 !important;
                                color: rgb(255, 204, 47);
                                border-color: #444444 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .today_table {
        background: #fff;
        .game-list-col {
            padding-left: 0;
            align-items: center;
        }
        dt {
            width: calc(100% - 80px);
            padding: 8px;
            &:first-child {
                font-weight: 600;
                width: 80px;
            }
            .table_data {
                width: 100%;
                h5 {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: Tahoma, Helvetica, sans-serif;
                    color: #212529;
                    .angle_unicode {
                        color: #c5d0d7;
                        font-size: 20px;
                        vertical-align: sub;
                        display: inline-block;
                        padding: 0;
                    }
                }
                a {
                    display: inline;
                    vertical-align: middle;
                    font-weight: 700;
                    font-size: 12px;
                }
            }
        }
    }
}
.inplay_left {
    .marquee-box {
        margin-bottom: 0;
    }
}
.a-search {
    border-left: 1px solid hsla(0, 0%, 100%, .1);
    border-radius: 0;
    padding: 0;
    right: 0;
    width: 12.8vw;
    z-index: 2;
    height: 39px;
    display: block;
    position: relative;
    background: linear-gradient(-180deg, hsla(0, 0%, 100%, .15), rgba(0, 0, 0, .15)) !important;
    text-indent: -99999px;
    color: #fff;
    font-size: 0;
    @include bp(tab) {
        height: 12.26667vw;
    }
    &::after {
        background-image: url("../../../public/images/search.svg");
        background-size: cover;
        content: "";
        height: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 20px;
        background-repeat: no-repeat;
        @include bp(tab) {
            background-size: cover;
            content: "";
            height: 5.86667vw;
            left: 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 5.86667vw;
        }
    }
}
#eventInfo {
    >div {
        @include bp(mini) {
            display: flex;
            align-items: center;
            .in_play {
                margin: 0 7px;
                margin-left: 0;
                font-weight: normal !important;
            }
        }
        @include bp(tab) {
            margin-left: 4.5vw !important;
        }
    }
}
.footer-kv {
    display: none;
    @include bp(mini) {
        display: block;
        margin-bottom: 5.33333vw;
    }
}
////////////////////////////// new design /////////////// 
.sports {
    .search-games-sec {
        top: 0;
        padding: 0;
        .form-control {
            align-items: center;
            background: #000;
            display: flex;
            justify-content: space-between;
            padding: 4px 10px;
        }
        svg {
            path {
                fill: #fff;
            }
        }
        .search-listing {
            padding: 10px;
            height: calc(100vh - 73px);
        }
    }
}
.parlay-tab {
    width: 100%;
    background: #000;
    color: #fff;
    font-family: Ubuntu, sans-serif;
    .align-items-center {
        align-items: center !important;
    }
    .parlay-checkbox {
        background: #1b1f23;
        color: #6f8898;
        margin-right: 9px;
        padding: 8px 13px 8px 11px;
        align-items: center;
        gap: 6px;
        @include bp(mob) {
            padding: 2.8vw 1.867vw;
            gap: 1.867vw;
            margin-right: 0;
        }
        .form-switch {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            padding-left: 0;
            margin-bottom: 0.125rem;
            min-height: 1.5rem;
            font-size: 18px;
            @include bp(mob) {
                margin-bottom: 0;
            }
            input {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }
            label {
                position: relative;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                &::before {
                    content: "";
                    width: 36px;
                    height: 18px;
                    background: #fff;
                    position: relative;
                    display: inline-block;
                    border-radius: 46px;
                    box-sizing: border-box;
                    transition: 0.2s ease-in;
                    border: 1px solid transparent;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    left: 3px;
                    top: 2px;
                    z-index: 2;
                    background: #ccc;
                    box-sizing: border-box;
                    transition: 0.2s ease-in;
                }
            }
            input[type=checkbox]:checked {
                &+label:before {
                    background: #4BD865;
                    border: 1px solid #0d6efd;
                }
                &+label:after {
                    left: 20px;
                    background: #fff;
                }
            }
        }
        span {
            font-size: 14px;
            font-weight: 600;
            @include bp(mob) {
                font-size: 4vw;
            }
        }
    }
    .parlay-inner-tab {
        justify-content: space-between;
        width: 100%;
        >div {
            @include bp(mob) {
                width: 100%;
                >ul {
                    display: flex;
                    width: 100%;
                    justify-content: space-evenly;
                    align-items: center;
                }
            }
        }
        li {
            display: inline-block;
            font-size: 16px;
            margin-right: 10px;
            cursor: pointer;
            @include bp(mob) {
                font-size: 4vw;
                margin-right: 0;
            }
            &.only_mobile {
                display: none;
                margin: 0;
                @include bp(mob) {
                    display: flex;
                    svg {
                        width: 6.333vw;
                        font-size: 6.333vw;
                    }
                }
            }
            &.active {
                color: #fff;
                border-bottom: 3px solid #fff;
                @include bp(mob) {
                    font-weight: 700;
                }
            }
        }
    }
    .parlay-icons {
        align-items: center;
        display: flex;
        @include bp(mob) {
            display: none;
        }
        svg {
            font-size: 21px;
            margin-right: 7px;
            cursor: pointer;
        }
    }
}
.game-menu {
    padding: 9px 8px;
    background: #333;
    color: #fff;
    ul {
        background: #333;
        overflow-x: auto;
        display: flex;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            font-size: 14px;
            margin-right: 8px;
            padding: 3px 6px;
            font-weight: 700;
            cursor: pointer;
            display: flex;
            font-family: Inter;
            align-items: center;
            border-radius: 25px;
            @include bp(mob) {
                width: 21.332vw;
                height: 8vw;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 3.467vw;
            }
            &.active {
                // background: #ffc800;
                // color: #000;
                background: var(--bgColor);;
                color: var(--colorSecond);
            }
            svg {
                font-size: 21px;
                margin-right: 5px;
                vertical-align: middle;
                width: 21px;
                @include bp(mob) {
                    width: 4.533vw;
                    font-size: 4.533vw;
                    display: block;
                }
            }
        }
    }
}
.all-complete {
    align-items: center;
    background: #eef6fb;
    display: flex;
    justify-content: space-between;
    padding: 0.6428571428571429rem 0.5rem 0 !important;
    position: relative;
    @include bp(mob) {
        padding: 1.867vw !important;
        padding-bottom: 2.4vw !important;
        padding-top: 2.4vw;
    }
    .dropdown {
        width: 8.14285714286rem;
        position: relative;
        @include bp(mob) {
            width: 29.198vw;
        }
        &-toggle.active {
            &::after {
                transform: rotate(180deg);
            }
        }
        &-toggle:after {
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            border-right: 0.3em solid transparent;
            border-top: 0.3em solid;
            content: "";
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            background-position: left 6px top 4px;
            background-size: 12px;
            filter: brightness(0);
            position: absolute;
            right: 6px;
            top: 47%;
        }
    }
    button {
        background: #fff;
        border: none;
        color: #000;
        font-size: .7857142857142857rem;
        font-weight: 400;
        padding-left: 0.42857142857142855rem;
        padding-right: 0.42857142857142855rem;
        text-align: left;
        width: 100%;
        white-space: nowrap;
        position: relative;
        font-family: Ubuntu, sans-serif !important;
        @include bp(mob) {
            font-size: 2.933vw !important;
            padding: 1.867vw 1.6vw;
            border-radius: 1.6vw;
        }
        svg {
            vertical-align: middle;
            margin-right: 0.25rem !important;
        }
    }
    .dropdown-menu {
        background-color: #fff;
        border: none;
        padding: 0;
        width: 100%;
        display: block;
        position: absolute;
        z-index: 99;
        top: 29px;
        border-radius: 0.375rem;
        @include bp(mob) {
            top: 7vw;
        }
        .dropdown-item {
            background-color: initial;
            border: 0;
            border-radius: 0.375rem;
            clear: both;
            color: #212529;
            display: block;
            font-weight: 400;
            padding: 0.25rem 1rem;
            text-align: inherit;
            text-decoration: none;
            white-space: nowrap;
            width: 100%;
            @include bp(mob) {
                padding: 1.867vw 5.4vw;
                display: inline-block;
                font-size: 2.933vw;
            }
        }
        a {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 32px;
            display: inline-block;
        }
    }
}
.matchlist {
    padding: 1rem !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    padding-top: 0.25rem !important;
    background: #eef6fb;
    @include bp(mob) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        background: linear-gradient(-180deg, #d4e0e5, #eef6fb);
    }
    &-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include bp(mob) {
            padding: 2.4vw 1.867vw;
        }
        .sports-heading {
            border-left: 5px solid #000;
            font-weight: 700;
            padding-left: 7px;
            position: relative;
            font-size: 16px;
            @include bp(mob) {
                font-size: 4vw;
                line-height: 4vw;
            }
        }
        .all-complete-all {
            align-items: center;
            background: #fff;
            border: none;
            border-radius: 5px;
            color: #000;
            cursor: pointer;
            display: flex;
            font-size: .7857142857142857rem;
            font-weight: 400;
            padding: 2px 7px;
            @include bp(mob) {
                width: 15.465vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active {
                svg {
                    transform: rotate(0deg);
                }
            }
            svg {
                @include bp(mob) {
                    font-size: 2.933vw;
                }
            }
            span {
                @include bp(mob) {
                    font-size: 2.933vw;
                }
            }
            svg {
                transition: all 0.3s ease-in-out;
                transform: rotate(180deg);
            }
        }
    }
    .bookmaker-wrp {
        @include bp(mob) {
            // padding: 0 1.867vw;
            margin-bottom: 1.867vw;
            .sport-accordian {
                padding: 0 !important;
            }
        }
    }
}
.sport-accordian {
    padding: 0.25rem !important;
    position: relative;
    overflow: hidden;
    .accordion {
        &-header {
            margin-bottom: 0;
            width: 100%;
            &.active {
                button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        &-item {
            .accordion-button {
                border-radius: 10px;
                width: 100%;
                background: #000;
                // color: #ffc800;
                color: var(--bgColor);
                font-family: Ubuntu, sans-serif;
                font-weight: 500;
                padding: 0.5rem;
                font-size: 1rem;
                border: none;
                box-shadow: none;
                position: relative;
                text-align: left;
                display: flex;
                align-items: center;
                transition: all 0.5s ease-in-out;
                text-transform: capitalize;
                @include bp(mob) {
                    padding: 1.867vw;
                    padding-right: 3.4vw;
                    border-radius: 2.8vw;
                    font-size: 3.733vw;
                    font-weight: 400;
                }
                .sport-counter {
                    border-radius: 21px;
                    font-size: .7857142857142857rem;
                    font-weight: 800;
                    margin-right: 6px;
                    padding: 2px 0.7rem;
                    // background-color: #ffc800 !important;
                    background-color: var(--bgColor) !important;
                    border: none !important;
                    // color: #000 !important;
                    color: var(--colorSecond) !important;
                    @include bp(mob) {
                        border-radius: 4.667vw;
                        width: 7.199vw;
                        height: 4.666vw;
                        line-height: 5.6vw;
                        padding: 1.4vw;
                        font-size: 2.933vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                &::after {
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 1.25rem;
                    content: "";
                    flex-shrink: 0;
                    height: 1.25rem;
                    margin-left: auto;
                    transition: transform 0.4s ease-in-out;
                    width: 1.25rem;
                    filter: brightness(0) invert(1);
                }
            }
        }
        &-collapse {
            background-color: #fff;
            transition: max-height 0.5s ease-in-out;
            overflow: hidden;
            &:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            .accordion-body {
                padding-bottom: 0.25rem !important;
                padding-top: 0.25rem !important;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
                @include bp(mob) {
                    padding: 0 !important;
                }
                .sport-accord-body {
                    padding: 0.42857142857142855rem 0;
                    @include bp(mob) {
                        padding: 0 1.867vw !important;
                    }
                    &-wrp {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                       @include bp(mob) {
                            padding: 2.667vw 0;
                        }
                        .counter {
                            // color: #c69700;
                            color: var(--bgColor);
                            font-weight: 700;
                            font-size: 1rem;
                            min-width: 32px;
                            @include bp(mob) {
                                font-size: 3.733vw;
                            }
                        }
                        .fave {
                            background-position: 0 0;
                            height: 3.2142857143rem;
                            margin: -12px;
                            margin: -0.7031249999999999rem;
                            max-height: 45px;
                            max-width: 70px;
                            transform: scale(.5818181818181818);
                            -webkit-transform-origin: center center;
                            transform-origin: center center;
                            width: 5rem;
                            // position: absolute;
                            left: -3px;
                            @include bp(mob) {
                                margin: unset;
                                height: 6.076vw;
                                max-width: 32px;
                                // width: 100%;
                                transform: unset;
                            }
                            &.bg-star-sprit-black {
                                background-image: url("../../../public/images/mypin-star-sprit-black.png");
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                        .ps-4 {
                            padding-left: 1.5rem !important;
                            @include bp(mob) {
                                padding-left: 7vw !important;
                            }
                        }
                        .match-name {
                            font-size: .9285714285714286rem;
                            @include bp(mob) {
                                font-size: 3.467vw;
                            }
                        }
                    }
                    .sports-team-table {
                        .match-odd-table {
                            li {
                                align-items: center;
                                background: #eef6fb;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 2px;
                                padding: 0 0 0 15px;
                                &:first-child {
                                    margin-top: 2px;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                h2 {
                                    font-size: 12.48px !important;
                                    font-weight: 700;
                                    margin: 0;
                                    padding-right: 10px;
                                    @include bp(mob) {
                                        font-size: 3.733vw !important;
                                    }
                                }
                                >div {
                                    display: flex;
                                    text-align: center;
                                    text-align: center;
                                    >div {
                                        background: #000;
                                        border-radius: 6px;
                                        font-size: 13px !important;
                                        height: 34px !important;
                                        margin-right: 3px;
                                        padding: 2px;
                                        width: 52px;
                                        &:last-child {
                                            margin: 0;
                                        }
                                        @include bp(mob) {
                                            width: 13.957vw;
                                            height: auto !important;
                                            padding: 1.6vw 0;
                                        }
                                    }
                                }
                            }
                            .lay-back-table {
                                .back {
                                    position: relative;
                                    background-color: #7cc3f5;
                                    strong {
                                        font-size: 14.4px;
                                        line-height: 10px;
                                        @include bp(mob) {
                                            font-size: 4vw;
                                            line-height: 4vw;
                                        }
                                    }
                                    .single-disabled {
                                        &::before {
                                            background-color: rgb(38 44 50/.6);
                                            border-radius: 6px;
                                            content: "";
                                            height: 100%;
                                            left: 0;
                                            position: absolute;
                                            top: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                                .d-block {
                                    font-size: 8.64px;
                                    line-height: 8px;
                                    @include bp(mob) {
                                        font-size: 2.4vw;
                                        line-height: 2.4vw;
                                    }
                                }
                                .lay {
                                    background-color: #faa9ba;
                                    position: relative;
                                    strong {
                                        font-size: 14.4px;
                                        line-height: 10px;
                                        @include bp(mob) {
                                            font-size: 4vw;
                                            line-height: 4vw;
                                        }
                                    }
                                    .single-disabled {
                                        &::before {
                                            background-color: rgb(38 44 50/.6);
                                            border-radius: 6px;
                                            content: "";
                                            height: 100%;
                                            left: 0;
                                            position: absolute;
                                            top: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                            .back-lay-head {
                                border-radius: 8px 8px 0 0;
                                padding: 6px 0;
                                font-size: 9.6px;
                                line-height: 14px;
                                h4 {
                                    font-size: 13px;
                                    font-weight: 500;
                                    margin: 0;
                                    @include bp(mob) {
                                        font-size: 3.467vw;
                                        line-height: 4.667vw;
                                        font-weight: 700;
                                    }
                                }
                            }
                            .half-table {
                                border-right: 1px solid #00000078;
                                color: #00000094;
                                font-family: Ubuntu, sans-serif;
                                padding-left: 14px;
                                display: flex;
                                flex: 1 1 0%;
                                justify-content: start;
                                align-items: center;
                                width: 100%;
                                @include bp(mob) {
                                    font-size: 2.667vw;
                                    &-text {
                                        width: 13.957vw !important;
                                        font-size: 2.667vw;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .slide-down {
        max-height: 1000px;
        /* Adjust the value based on your content */
    }
    .slide-up {
        max-height: 0;
    }
}
.in-play {
    background: #52bf05;
    color: #fff;
    font-size: .5714285714285714rem;
    font-weight: 600;
    height: 10px;
    padding: 0 6px;
    @include bp(mob) {
        font-size: 2.133vw;
        height: 3.14vw;
    }
}
.icon-sports {
    margin-left: 0;
    position: relative;
    div {
        display: flex;
        align-items: center;
    }
    img {
        height: 10px;
        margin-right: 1px;
        vertical-align: middle;
        @include bp(mob) {
            height: 2.669vw;
        }
    }
}

.inplay-box{
    height: 100%;
    flex-shrink: 0;
    padding: 0 2px;
    width: 44px;
    .inplay{
        font-size: 10px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    &[data-is-inplay-type=true]{
        background-color: #52bf04;
        color: #ffffff;
    }
    &[data-is-today-type=true]{
        background-color: #ffd84d;
        color: #1e1e1e;
    }
    &[data-is-tomorrow-type=true]{
        background-color: #e2eaef;
        color: #ffffff;
    }
}