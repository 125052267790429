.multimarket {
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 1px;
    @include bp(mini) {
        width: 100%;
        max-width: 100%;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    &_left {
        flex: 0 0 auto;
        width: 15.65%;
        max-width: 100%;
        @include bp(mini) {
            display: none;
        }
        .left-menu {
            height: 100%;
            background-color: #fff !important;
            .topmenu-left {
                background: #222222;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;
                .barsicon {
                    a {
                        width: 36px;
                        height: 25px;
                        line-height: 25px;
                        text-align: center;
                        position: relative;
                        display: inline-block;
                        img {
                            width: 100%;
                            height: 100%;
                            &.hover-img {
                                display: none;
                            }
                        }
                        &:hover {
                            text-decoration: underline;
                            img {
                                display: none;
                            }
                            .hover-img {
                                display: inline-block;
                            }
                        }
                    }
                }
                .soprts-link {
                    padding: 4px 13px 5px 6px;
                    position: relative;
                    color: $menucolor1;
                }
            }
            ul {
                width: 100%;
                &.leftul {
                    position: relative;
                    height: 100%;
                    padding-left: 0;
                    margin: 0;
                    width: 100%;
                    li {
                        border-bottom: 1px solid #efe1c1;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-flow: wrap;
                        a {
                            line-height: 25px;
                            padding: 0 10px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #222 !important;
                            &:first-child {
                                width: calc(100% - 32px);
                            }
                            &:nth-child(2) {
                                height: 25px;
                                width: 32px;
                                float: right;
                                padding: 0 0 0 8px;
                                overflow: hidden;
                            }
                            &:hover {
                                background: #f2dca7;
                                text-decoration: underline;
                                .hoverleft {
                                    display: none;
                                }
                                .hover-img {
                                    display: block;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
                &.dropul {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #fff !important;
                    padding-left: 0;
                    list-style: none;
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
    &_center {
        flex: 0 0 auto;
        width: 100%;
        padding-right: 0;
        height: inherit;
        @include bp(mini) {
            width: 100%;
            padding: 0;
        }
        h5 {
            margin-bottom: 8px;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
        }
        p {
            line-height: 21px;
            font-size: 14px;
            font-weight: 400;
        }
        .cricket_wrap_main {
            overflow-y: auto;
            height: calc(100vh - 120px);
            .cricket_wrap {
                .cricket_img {
                    margin-bottom: 0px;
                    img {
                        width: 100%;
                        vertical-align: middle;
                    }
                }
                .highligths-txt {
                    font-size: 12px;
                    line-height: 25px;
                    padding-left: 10px;
                    background-color: $menucolor1;
                    color: $menufontcolor;
                    font-weight: normal;
                    margin: 0;
                    @include bp(mini) {
                        background-image: linear-gradient(-180deg, #2e4b5e, #243a48 82%) !important;
                        color: #fff !important;
                    }
                    @include bp(tab) {
                        font-size: 3.73333vw;
                        line-height: 2.2;
                        text-align: center;
                        font-weight: 700;
                    }
                }
            }
        }
        .live_data {
            overflow-y: auto;
            height: 100%;
            .live-tv-section {
                @include bp(mini) {
                    position: sticky;
                    top: 0;
                    z-index: 20;
                }
            }
        }
        .scoreboard {
            iframe {
                width: 100%;
                height: 210px;
                background: #1e1e1e;
            }
        }
        .live-tv-section {
            .accordian_item {
                margin-bottom: 0;
                border: none;
            }
        }
        .match-btn {
            justify-content: center;
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
            background-color: #fff;
            li {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 25px;
                    background: rgba(255, 255, 255, .5);
                }
                a {
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 25px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    display: flex;
                    &::before {
                        content: '';
                        width: 14px;
                        height: 14px;
                        margin-right: 2px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &.btn-pin {
                        padding-right: 12px;
                        background-image: url("../../../public/images/btn-pin-left.svg");
                        &::before {
                            background-image: url("../../../public/images/pin-white.svg");
                        }
                    }
                    &.btn-refresh {
                        padding-right: 12px;
                        background-image: url("../../../public/images/btn-pin-right.svg");
                        &::before {
                            background-image: url("../../../public/images/refresh-white.svg");
                        }
                    }
                }
            }
        }
        .twodiv-ireland {
            display: flex;
            align-items: center;
            position: relative;
            flex-flow: wrap;
            background: #fff;
            margin-bottom: 20px;
            .ireland-txt {
                height: 29px;
                line-height: 30px;
                font-weight: 700;
                padding-left: 10px;
                padding-right: 5px;
                display: inline-block;
                font-size: 13px;
                position: relative;
                background-color: $menucolor1;
                color: $menufontcolor;
                @include bp(mob) {
                    font-size: 8px;
                }
                &::after {
                    content: '';
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 29px;
                    display: inline-block;
                    position: absolute;
                    right: -19px;
                    top: 0;
                    border: solid 10px $menucolor1;
                    border-radius: 0 27px 0 0;
                }
            }
            .timeblockireland {
                font-weight: 700;
                padding: 0 25px;
                font-size: 13px;
                margin-top: 3px;
                .detail-in-play_text {
                    position: relative;
                    a {
                        font-weight: 700;
                        color: #508d0e;
                        padding-left: 20px;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 16px;
                            width: 16px;
                            border-radius: 3px 0 0 3px;
                            background-image: url("../../../public/images/front-all-icon1.png");
                            background-repeat: no-repeat;
                            background-position: -396px -2816px;
                        }
                    }
                }
            }
            .minmax-txt {
                padding: 0 6px;
                font-size: 12px;
                line-height: 16px;
                border-radius: 3px;
                position: absolute;
                left: 46%;
                display: flex;
                background-color: $menucolor1;
                color: $menufontcolor;
                @include bp(mob) {
                    font-size: 8px;
                    right: 4px;
                    left: unset;
                }
                span {
                    margin-right: 2px;
                    &:nth-child(2) {
                        margin-right: 8px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .match-detail {
            display: block;
            margin-bottom: 12px;
            .inplay-tableblock {
                td {
                    border-bottom: 1px solid #7e97a7;
                    &:nth-child(n+2) {
                        text-align: center;
                        width: 9.5%;
                        @include bp(mini) {
                            width: 18.66667vw;
                        }
                    }
                    &:first-child {
                        padding: 0.3rem 0.7rem;
                        img {
                            display: inline-block;
                        }
                    }
                    &.opnForm,
                    &.td_team1_back_1,
                    &.td_team1_lay_1,
                    &.td_team1_lay_2 {
                        @include bp(mini) {
                            display: none;
                        }
                    }
                }
                .betstr {
                    td {
                        border-left: 1px solid #f0ece1;
                        border-top: 0;
                        padding: 0;
                        vertical-align: bottom;
                        font-size: 13px;
                        font-weight: 600;
                        &:first-child {
                            font-size: 11px;
                            padding: 0.3rem 0.7rem;
                            padding-left: 5px;
                        }
                        &:nth-child(2) {
                            text-align: left;
                            @include bp(mini) {
                                display: none;
                            }
                        }
                        &:last-child {
                            text-align: right;
                            @include bp(mini) {
                                display: none;
                            }
                        }
                    }
                }
            }
            .fancy-section {
                .fancy-bet-txt {
                    .fancy_bet-head,
                    .sportsbook_bet-head {
                        border-bottom: 2px solid;
                        line-height: 1px;
                        position: relative;
                        display: inline-flex;
                        width: 100%;
                        h4 {
                            color: #fff;
                            font-size: 13px;
                            height: 24px;
                            margin: 0;
                            z-index: 1;
                            line-height: 25px;
                            position: relative;
                            float: left;
                            span {
                                height: 100%;
                                padding-left: 10px;
                                height: 25px;
                            }
                        }
                        .other-tab {
                            position: relative;
                            height: 25px;
                            line-height: 24px;
                            background-repeat: repeat-x;
                            color: #c5d0d7;
                            font-weight: bold;
                            padding: 0 10px;
                            margin-left: 7px;
                            z-index: 0;
                            background-position: 0 -12937px;
                            display: flex;
                            background-image: url("../../../public/images/bg.png");
                            background-repeat: repeat-x;
                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                background-image: url("../../../public/images/front-all-icon1.png");
                            }
                            &::before {
                                left: -11px;
                                background-position: -403px -656px;
                                height: 25px;
                                width: 11px;
                                background-repeat: no-repeat;
                            }
                            &::after {
                                right: -11px;
                                background-position: -403px -681px;
                                height: 25px;
                                width: 11px;
                            }
                        }
                    }
                    .sportsbook_bet-head {
                        border-bottom-color: #e4550f !important;
                        h4 {
                            span {
                                background-image: linear-gradient(180deg, #ef6c2c, #e4550f 82%);
                                padding-right: 10px;
                            }
                        }
                        .btn-head_rules {
                            align-items: center;
                            background-repeat: no-repeat;
                            background-size: 101% 101%;
                            display: flex;
                            font-size: 0;
                            height: 100%;
                            justify-content: center;
                            text-indent: -99999px;
                            vertical-align: bottom;
                            width: 33px;
                            height: 25px;
                            background-image: url("../../../public/images/bg-sportsbook_rules.svg");
                            &::before {
                                background-image: url("../../../public/images/note-icon.svg ");
                                background-repeat: no-repeat;
                                background-size: contain;
                                content: "";
                                display: block;
                                height: 15px;
                                width: 15px;
                            }
                        }
                        h4 {
                            display: inline-flex !important;
                            span {
                                display: inline-block;
                                line-height: 25px;
                                padding: 0 14px 0 8px;
                            }
                        }
                        &.fancy-head {
                            border-bottom-color: #0a92a5 !important;
                            h4 {
                                span {
                                    background-image: url("../../../public/images/bg.png");
                                    padding-right: 10px;
                                    background-position: 0 -7371px;
                                    position: relative;
                                    padding: 0 14px 0 8px;
                                    color: #076976;
                                    &::before {
                                        content: "";
                                        margin: 6px 6px 0 0;
                                        background-position: -399px -2883px;
                                        height: 15px;
                                        width: 15px;
                                        background-image: url("../../../public/images/front-all-icon1.png");
                                        background-repeat: no-repeat;
                                        float: left;
                                    }
                                }
                                .btn-head_rules {
                                    background-image: url("../../../public/images/front-all-icon1.png");
                                    vertical-align: top;
                                    margin-left: 0;
                                    background-position: -377px -1515px;
                                    height: 25px;
                                    width: 37px;
                                    display: inline-block;
                                    background-size: unset;
                                    &::before {
                                        content: unset;
                                    }
                                }
                            }
                        }
                    }
                    .fancy_bet_tab-wrap {
                        color: $menufontcolor;
                        &.premium {
                            background-image: linear-gradient(180deg, #e4550f 15%, #e4550f);
                            &.fancy_bet {
                                background-image: linear-gradient(180deg, #0a92a5 15%, #076875 100%);
                            }
                        }
                        .nav-pills {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            box-shadow: inset 0 1px 0 0 #00000033;
                            justify-content: center;
                            margin: 0;
                            overflow: auto;
                            padding: 3px;
                            .nav-item {
                                padding: 0 5px;
                                .nav-link {
                                    color: #243a48;
                                    font-size: 13px;
                                    font-weight: 600;
                                    padding: 2px 15px;
                                    position: relative;
                                    opacity: .8;
                                    border: 0;
                                    border-radius: 0.25rem;
                                    background: #ffffff57;
                                    line-height: 23.5px;
                                    &.active {
                                        background: #fff;
                                        opacity: .8;
                                    }
                                }
                            }
                        }
                    }
                }
                &.fancy_tab {
                    .ODDSBack,
                    .ODDSLay {
                        font-weight: bold;
                        span {
                            font-size: 11px;
                            font-weight: 400;
                        }
                    }
                    .fancy-suspend-tr {
                        td {
                            padding: 0;
                            border: none;
                        }
                        .fancy-suspend {
                            height: 60px;
                            top: -61px;
                        }
                    }
                }
            }
            table {
                &.premium-table tr td.refer-book {
                    display: none;
                }
                &.bets .special_bet {
                    background-color: #243a48;
                    color: #fff;
                    td {
                        border-bottom: 1px solid #7e97a7;
                        border-left: 1px solid #ddd;
                        font-weight: 700;
                        vertical-align: top;
                        width: 20%;
                        z-index: 1;
                        h3 {
                            position: relative;
                            line-height: 1;
                            margin: 0;
                            padding: 0 !important;
                            font-size: 1.75rem;
                            position: relative;
                            background-image: url("../../../public/images/front-all-icon1.png");
                            background-repeat: no-repeat;
                            background-position: 100% -1707px;
                            cursor: pointer;
                            @include bp(mini) {
                                background-image: none;
                            }
                            &.close {
                                background-position: 100% -1940px;
                            }
                            a {
                                color: #fff;
                                height: inherit;
                                &:hover {
                                    text-decoration: underline;
                                }
                                &.marketName,
                                &.multiMarketPin {
                                    display: table-cell !important;
                                    font-size: 15px !important;
                                    padding: 6px 5px !important;
                                    font-weight: 500;
                                    vertical-align: middle;
                                    svg {
                                        width: 11px;
                                        height: 15px;
                                    }
                                }
                                &.multiMarketPin {
                                    background: #202f38 !important;
                                    padding: 5px 10px !important;
                                }
                            }
                        }
                    }
                }
                .bets {
                    th {
                        border-bottom: 1px solid #7e97a7;
                        padding: 1px 10px;
                        position: relative;
                        font-weight: bold;
                        .fancy-th-layout dd,
                        .fancy-th-layout dt {
                            align-items: center;
                        }
                        .fancy-th-layout {
                            margin-bottom: 0;
                            dt {
                                flex: 1;
                                flex-wrap: wrap;
                                margin-bottom: 0;
                                margin-right: 5px;
                                text-align: left;
                                font-weight: bold;
                                p {
                                    font-weight: bold;
                                }
                                .collapse:not(.show) {
                                    display: none;
                                }
                            }
                        }
                    }
                    .back-1 {
                        background-color: #72e3a0;
                        text-align: center;
                    }
                    td {
                        a {
                            &.info {
                                align-items: center;
                                color: #1e1e1e;
                                cursor: pointer;
                                display: flex;
                                flex-flow: column;
                                height: 35px;
                                justify-content: center;
                                padding: 3px 0 2px;
                                position: relative;
                            }
                        }
                    }
                }
                .bets,
                .bets-GH,
                .bets-HS {
                    td {
                        border-bottom: 1px solid #7e97a7;
                        border-left: 1px solid #ddd;
                        font-weight: 700;
                        vertical-align: top;
                        width: 20%;
                        z-index: 1;
                    }
                }
            }
        }
    }
    &_right {
        flex: 0 0 auto;
        width: 24.85%;
        height: inherit;
        @include bp(mini) {
            display: none;
            &.show-mobile {
                display: block;
                position: absolute;
                z-index: 43;
                right: 0;
                left: 0;
                width: 100%;
                height: calc(100vh - 46px);
                top: 46px;
                bottom: 0;
            }
        }
        .right-side-block-section {
            background: #fff;
            height: 100%;
            overflow-y: auto;
            padding-left: 8px;
            .bet_slip {
                background: #fff;
                overflow-y: auto;
                &_head {
                    h4 {
                        background: #243a48;
                        font-size: 14px;
                        color: #fff;
                        margin: 0;
                        padding: 4px 10px;
                    }
                    .slip-head {
                        background-color: #ced5da;
                        padding: 0 10px;
                        display: flex;
                        align-items: center;
                        list-style: none;
                        font-size: 12px;
                        margin: 0;
                        li {
                            text-align: left;
                            font-size: 12px;
                            line-height: 18px;
                        }
                        .col-bet {
                            width: 43.23529%;
                            padding: 5px 0;
                        }
                        .col-odd {
                            width: 14.70588%;
                            padding: 5px 0;
                        }
                        .col-stake {
                            width: 16.17647%;
                            padding-left: 5px;
                        }
                        .col-profit {
                            width: 24.11765%;
                            padding: 5px 0;
                            text-align: right;
                        }
                    }
                    .inplay_live {
                        font-size: 11px;
                        position: relative;
                        margin: 0;
                        padding: 6px 10px 6px 25px;
                        background: #fff;
                        &::before {
                            position: absolute;
                            content: "";
                            left: 10px;
                            top: 9px;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: #6bbd11;
                        }
                    }
                    .match_odd {
                        &.BACK {
                            background: #beddf4;
                        }
                        &.LAY {
                            background: #faa9ba;
                        }
                        &.GREEN {
                            background: #72e3a0;
                        }
                        &_head {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #67afe5;
                            li {
                                display: inline-block;
                                padding: 5px;
                            }
                            &_first {
                                font-size: 12px;
                                font-weight: 700;
                                width: 80%;
                                span {
                                    font-size: 10px;
                                    font-weight: 400;
                                    display: block;
                                }
                            }
                            &_second {
                                width: 150px;
                            }
                            &_third {
                                width: 140px;
                            }
                            &_fourth {
                                width: 120px;
                                text-align: right;
                            }
                        }
                        &_body {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            padding: 5px;
                            margin: 0;
                            list-style: none;
                            border-bottom: 1px solid #67afe5;
                            li {
                                width: 17%;
                                padding: 3px;
                                a {
                                    background: #fff;
                                    color: inherit;
                                    display: block;
                                    font-weight: 700;
                                    text-align: center;
                                    border-radius: 3px;
                                    text-decoration: none;
                                    font-size: 12px;
                                    padding: 3px;
                                }
                            }
                        }
                        .min_bet {
                            p {
                                text-align: right;
                                font-size: 12px;
                                padding: 5px 10px;
                                margin: 0;
                                border-bottom: 1px solid #67afe5;
                            }
                        }
                    }
                }
                &_footer {
                    padding-top: 16px;
                    border-bottom: 1px solid #ddd;
                    margin-bottom: 20px;
                    .btn_group {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 5px 15px;
                        .cancel {
                            border: 1px solid #bbb;
                            border-radius: 4px;
                            color: #1e1e1e;
                            font-weight: 700;
                            line-height: 23px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 2px 30px;
                            background: #fff;
                            width: 45%;
                            text-align: center;
                        }
                        .place_bets {
                            background: #2c2c2c;
                            width: 50%;
                            display: inline-block;
                            text-align: center;
                            color: #ffc01a;
                            font-size: 12px;
                            font-weight: 700;
                            padding: 4px 30px;
                            border-radius: 3px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .bets-html {
                table {
                    text-align: left;
                    width: 100%;
                    tr {
                        border-bottom: 1px solid #dee2e6 !important;
                        border-color: #6c757d !important;
                        &:first-child {
                            border: none !important;
                        }
                        .bet-item {
                            padding: 4px 0;
                            .bet-side {
                                flex: 0 0 auto;
                                width: 56px;
                            }
                            .bet-selection-type {
                                flex: 0 0 auto;
                                width: calc(100% - 56px);
                                padding-left: 5px;
                                span {
                                    display: block;
                                    &.bet-selection {
                                        font-weight: 700;
                                        display: block;
                                        line-height: 1.5;
                                        white-space: break-spaces;
                                        font-size: 12px;
                                        word-break: break-all;
                                    }
                                    &.bet-type {
                                        font-size: 10px;
                                        line-height: 1;
                                    }
                                }
                            }
                            .slip_type {
                                margin: 0;
                                padding: 0.25rem 0.5rem;
                                font-size: .875rem;
                                border-radius: 0.2rem;
                                display: inline-block;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #212529;
                                text-align: center;
                                text-decoration: none;
                                vertical-align: middle;
                                cursor: pointer;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
.inplay_table {
    background: #fff;
    .table-responsive {
        overflow-x: auto;
    }
    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
    }
    .nav-tabs {
        border-bottom: 1px solid #3B5160;
        border: 1px solid #3B5160;
        width: 50%;
        border-radius: 5px;
        overflow: hidden;
        flex-wrap: nowrap;
        margin-bottom: 8px;
        li {
            &.nav-item {
                width: 33.33%;
                &:first-child {
                    .nav-link {
                        border-left: 0;
                    }
                }
                .nav-link {
                    font-weight: 700;
                    font-size: 12px;
                    padding: 5px;
                    border-radius: 0;
                    background: #fff;
                    border: 0;
                    border-left: 1px solid #3B5160;
                    display: inline-block;
                    margin: 0;
                    width: 100%;
                    color: #3B5160;
                    line-height: 18px;
                    &.active {
                        background: #3B5160;
                        color: #fff;
                    }
                }
            }
        }
    }
    .tab-content {
        overflow-y: auto;
        height: calc(100vh - 160px);
        padding-right: 1px;
        .inplay_table {
            table {
                caption-side: bottom;
                border-collapse: collapse;
                width: 100%;
                thead {
                    tr {
                        th {
                            background: #ced5da;
                            font-size: 12px;
                            font-weight: 400;
                            padding: 5px 3px;
                            text-align: center;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            padding: 5px 3px;
                            font-size: 12px;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            white-space: nowrap;
                            background: #fff;
                            &:first-child {
                                text-align: left;
                                width: 100%;
                            }
                            a {
                                &.heading_title {
                                    font-weight: 700;
                                    padding-right: 5px;
                                    position: relative;
                                    color: #2789ce;
                                    padding-left: 25px;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                    &::before {
                                        position: absolute;
                                        content: "";
                                        width: 8px;
                                        height: 8px;
                                        background: #C5D0D7;
                                        border: solid 1px #a4a9af;
                                        border-radius: 50%;
                                        top: 3px;
                                        left: 9px;
                                    }
                                    &.in-play {
                                        &::before {
                                            background: #6BBD11;
                                            border: solid 1px #6BBD11;
                                        }
                                    }
                                }
                                &.pin_icon {
                                    width: 18px;
                                    height: 18px;
                                    display: inline-block;
                                    text-align: center;
                                    background: url("../../../public/images/pin.png") no-repeat;
                                    background-size: 100% !important;
                                    &:hover {
                                        background: url("../../../public/images/selected-pin.png") no-repeat;
                                    }
                                    &.selected {
                                        background: url("../../../public/images/selected-pin.png") no-repeat;
                                        &:hover {
                                            background: url("../../../public/images/pin.png") no-repeat;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.text-muted {
    color: #6c757d !important;
}
.inplay-tableblock {
    border-top: 1px solid #7e97a7;
    padding: 15px 0;
    position: relative;
}
.table {
    background-color: transparent;
    margin-bottom: 1rem;
    max-width: 100%;
    width: 100%;
}
.bg-white {
    background-color: #fff !important;
}
.to-lose,
.to-win {
    background-position: 0;
    background-repeat: no-repeat;
    display: inline-block;
    font-weight: 400;
    padding-left: 13px;
    white-space: nowrap;
}
.to-win {
    background-image: url("../../../public/images/arrow-green.png");
    color: #508d0e !important;
}
.to-lose {
    background-image: url("../../../public/images/arrow-red.jpg");
    color: red;
}
.fancy-suspend-tr {
    border-bottom: 0 !important;
    padding: 0 !important;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    th {
        border: none !important;
        padding: 0 !important;
    }
    .fancy-suspend-td {
        position: relative;
        border: none !important;
        padding: 0 !important;
    }
}
.suspend-white {
    background: rgba(36, 58, 72, 0.4);
    color: #fff;
    // height: 55px;
    height: 50px;
    line-height: 46px;
    position: absolute !important;
    text-align: center;
    // top: -55px;
    top: -50px;
    width: 100%;
    z-index: 5;
}
.fancybet-block>div {
    color: #fff;
    position: relative;
}
.mobile-bookmark-min-max-popup {
    color: #000;
    position: absolute;
    right: 10px;
    top: 2px;
}
.zeroopa {
    font-weight: 400;
    opacity: .7;
}
.fancy-suspend-tr .fancy-suspend-td .fancy-suspend,
.fancy-suspend-white {
    background: rgba(36, 58, 72, 0.4);
    color: #fff;
    height: 44px;
    line-height: 44px;
    position: absolute !important;
    text-align: center;
    top: -45px;
    width: 100%;
    z-index: 5;
}
.fancy_table {
    width: 100%;
    background-color: #fff;
    @media screen and (max-width:992px) {
        display: none;
    }
    &.bets {
        h3 {
            background-image: none !important;
        }
        .back-1 {
            background-color: #72bbef;
            border-left-color: #fff;
        }
        .bet-all {
            td {
                border-left-width: 0 !important;
                display: table-cell !important;
                text-align: center;
                padding: 2px 0;
            }
        }
        th {
            border-bottom: 1px solid #7e97a7;
            padding: 4px 5px 3px 10px;
            text-align: left;
        }
        td {
            width: 8.97436% !important;
            border-bottom: 1px solid #7e97a7;
            border-left: 1px solid #ddd;
            font-weight: bold;
            vertical-align: top;
            &.td-fancy_merge {
                width: 17.94872% !important;
                @include bp(mini) {
                    display: none;
                }
                .fancy-info {
                    // width: calc(70% - 3%);
                    // margin: 4px 0 0 3%;
                    dt {
                        font-size: 11px;
                        color: #7e97a7;
                        margin-right: 2px;
                        font-weight: normal;
                    }
                    dd {
                        font-size: 11px;
                        white-space: nowrap;
                        &:last-of-type {
                            margin-right: 0;
                            font-weight: normal;
                        }
                    }
                }
            }
            &.refer-book {
                display: block;
                @include bp(mini) {
                    display: none !important;
                }
            }
            &.multi_select {
                ul {
                    border-bottom: 1px solid #7e97a7;
                    display: flex;
                    li {
                        width: calc(50%);
                        // border-left: #fff 1px solid;
                        display: inline-block;
                        text-align: center;
                        &.lay-1 {
                            background-color: #faa9ba;
                            border-left-color: #fff;
                            display: block;
                            float: left;
                        }
                        a {
                            position: relative;
                            height: 43px;
                            color: #1e1e1e;
                            padding: 3px 0 2px;
                            cursor: pointer;
                            display: inline-block;
                            span {
                                font-weight: normal;
                                font-size: 11px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .fancy-suspend-tr {
        td {
            border: none !important;
        }
    }
    .fancy-th-layout {
        width: 100%;
        height: 30px;
        display: flex;
        dt {
            flex: 1;
            margin-right: 5px;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            p {
                color: #1e1e1e;
                font-size: 12px;
                line-height: 15px;
                font-weight: 700;
            }
        }
        .dd-tips {
            justify-content: space-between;
            width: 155px;
            display: flex;
            align-items: center;
        }
    }
}
.inplay-table {
    .mobileView {
        display: none;
        background-color: #dceaf4;
        border-bottom: 1px solid #7e97a7;
        padding-top: 2.66667vw;
        width: 100%;
        &.active {
            @include bp(mini) {
                display: table-row;
            }
        }
        td {
            padding: 0 !important;
            padding-top: 2.66667vw !important;
        }
        .btn-list {
            padding: 0 1.86667vw 1.86667vw;
            display: flex;
            li {
                width: 46vw;
                margin-right: 10px;
                overflow: hidden;
                &:last-child {
                    margin-right: 0;
                }
            }
            button {
                background-image: linear-gradient(-180deg, #fff, #eee 89%);
                border: 1px solid #aaa;
                font-weight: 700;
                line-height: 2.6;
                color: #1e1e1e;
                font-size: 16px;
                height: 100%;
                width: 100%;
                &.disabled {
                    background-image: linear-gradient(-180deg, #f6ecc6, #e7dcb4);
                    border-color: #dbd39f;
                    color: #999;
                }
                &.active {
                    background-image: linear-gradient(-180deg, #464646 15%, #121212);
                    border-color: #000;
                    // color: #67f0c2;
                }
            }
            p {
                color: #1e1e1e;
                text-align: center;
                & .dynamic-min-bet {
                    margin-top: 0;
                    padding: 0;
                    font-size: 2.93333vw;
                }
            }
        }
        .input-num {
            font-size: 4vw;
            line-height: 10.13333vw;
            font-weight: 700;
            background: #fff;
            border: 1px solid #aaa;
            box-shadow: inset 0 0.53333vw 0 0 #0000001a;
            border-radius: 1.6vw;
            cursor: text;
            overflow: hidden;
            display: flex;
            a {
                width: 12vw;
                height: 10.66667vw;
                background-image: linear-gradient(-180deg, #fff, #eee 89%);
                justify-content: center;
                align-items: center;
                font-size: 0;
                text-indent: -99999px;
            }
            span,
            input {
                position: relative;
                flex: 1;
                color: #7e97a7;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                text-align: center;
                &.typed {
                    color: #1e1e1e;
                    max-width: 150px;
                    font-size: 23px;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    text-align: center;
                    font-weight: bold;
                    border: none;
                    box-shadow: none;
                }
                &.typeing {
                    color: #1e1e1e;
                    background-color: #fff0ca;
                    box-shadow: inset 0 0.26667vw 1.33333vw #a1802d99;
                    border-color: #be7809;
                }
            }
            a {
                display: flex;
                &::before {
                    content: "";
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                &.icon-minus {
                    border-radius: 1.6vw 0 0 1.6vw;
                    border-right: 1px solid #aaa;
                    &::before {
                        width: 4.53333vw;
                        height: 10px;
                        background-image: url("../../../public/images/minus.svg");
                        display: block;
                    }
                }
                &.icon-plus {
                    border-radius: 0 1.6vw 1.6vw 0;
                    border-left: 1px solid #aaa;
                    &::before {
                        width: 4.53333vw;
                        height: 4.8vw;
                        background-image: url("../../../public/images/plus.svg");
                        display: block;
                    }
                }
            }
        }
        .coin-list {
            margin-bottom: 1.86667vw;
            background-image: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
            display: flex;
            li {
                flex: 1;
                border-right: 1px solid #ffffff26;
                a {
                    color: #fff;
                    line-height: 2.46;
                    background-image: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
                    text-align: center;
                    display: block;
                }
            }
        }
        .keyboard-wrap {
            border-top: 1px solid #aaa;
            margin-bottom: 1.86667vw;
            display: flex;
            ul {
                flex: 1;
                flex-wrap: wrap;
                display: flex;
                li {
                    flex: 1 1 16.66667%;
                    a {
                        font-size: 4vw;
                        color: #1e1e1e;
                        line-height: 10.4vw;
                        background-color: #fff;
                        border: 1px solid #aaa;
                        border-width: 0 0 1px 1px;
                        display: block;
                        box-sizing: border-box;
                    }
                }
            }
            a {
                color: #1e1e1e;
                line-height: 10.4vw;
                background-color: #fff;
                border: 1px solid #aaa;
                border-width: 0 0 1px 1px;
                text-align: center;
                &.btn-delete {
                    flex: 0 1 14.66667vw;
                    justify-content: center;
                    align-items: center;
                    font-size: 0;
                    text-indent: -99999px;
                    display: flex;
                    &::before {
                        content: "";
                        width: 4.8vw;
                        height: 4.2vw;
                        background: url("../../../public/images/backspace.svg") no-repeat;
                        background-size: contain;
                        display: block;
                    }
                }
            }
        }
    }
}
.bets-wrap {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 0;
    &.fancy-bet {
        display: inline-block;
        width: 100%;
        position: relative;
        background-color: #fff;
        border-bottom: 1px solid #7e97a7;
        margin-bottom: 0;
        >h5 {
            margin-bottom: 0;
            position: relative;
            height: 38px;
            font-size: 12px;
            line-height: 38px;
            padding: 0 0 0 10px;
            align-items: center;
            justify-content: space-between;
            color: #3b5160;
            background-color: #e4f1f9;
            font-weight: bold;
            display: flex;
            span {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .btn-fancy_info {
                flex-basis: 25px;
                width: 25px;
                height: 100%;
                padding: 0;
                margin: 0;
                background-color: initial;
                line-height: inherit;
                align-items: center;
                justify-content: center;
                font-size: 0;
                text-indent: -99999px;
                display: flex;
                &::before {
                    content: "";
                    width: 14px;
                    height: 14px;
                    background: url("../../assets/images/info.svg") no-repeat;
                    background-size: contain;
                    display: block;
                }
            }
            .fancy_info-popup,
            .limit_info-popup {
                display: none;
                position: absolute;
                top: 3px;
                right: 3.973px;
                background-color: #fff;
                border-radius: 3px;
                align-items: flex-start;
                z-index: 9;
                width: 71px;
                height: 29px;
                box-shadow: 0 6px 10px #000000b3;
                &.active {
                    display: flex;
                }
                dl:last-of-type {
                    border-right: 1px solid #e0e6e6;
                }
                dl {
                    padding: 0 3.973px 3.973px;
                }
                dt {
                    height: 10px;
                    color: #577c94;
                    font-size: 8px;
                    line-height: 10px;
                    padding: 2.5px 0 10px;
                }
                dd {
                    line-height: 11px;
                    font-size: 11px;
                }
                .close {
                    width: 21px;
                    height: 21px;
                    justify-content: center;
                    align-items: center;
                    font-size: 0;
                    text-indent: -99999px;
                    display: flex;
                    &::before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        background: url("../../assets/images/close.svg") no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
    .bets-selections {
        position: relative;
        min-height: 36px;
        border-bottom: 1px solid #7e97a7;
        display: flex;
        &:last-child {
            border-bottom-width: 0;
        }
        dt {
            padding: 6px 2px;
            position: relative;
            flex: 1;
            font-size: 13px;
            font-weight: 700;
            align-items: center;
            flex-wrap: wrap;
            overflow: hidden;
            list-style: 16.64px;
            .tips {
                width: 100%;
                font-size: 9.3px;
                list-style-type: none;
                font-weight: 400;
                display: block;
                dt {
                    padding: 0;
                    line-height: 11px;
                }
                dd {
                    color: #1e1e1e;
                    line-height: 10px;
                    margin-top: 1.7px;
                    border-right: 1px solid #fff;
                    &:last-child {
                        border-right-width: 0;
                    }
                }
            }
            .tips-mob {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                a {
                    max-width: 50px;
                    width: 100%;
                }
            }
        }
        >dd {
            width: 58px;
            border-right: 1px solid #fff;
            display: flex;
            box-sizing: border-box;
            &.mode-land {
                display: none;
            }
        }
        dd {
            border-right: 1px solid #fff;
            width: 58px;
        }
        .suspend-fancy {
            display: flex;
            width: 118px;
            position: absolute;
            right: 0;
            height: 100%;
            background-color: #0006;
            text-shadow: 0 0.26667vw 1.06667vw #00000080;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 700;
            p {
                color: #fff;
                opacity: .8;
                text-transform: capitalize;
                font-size: 11px;
            }
        }
        a,
        p {
            width: 100%;
            font-weight: 700;
            color: #1e1e1e;
            padding: 5.1px;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 400;
                font-size: 10px;
                display: block;
            }
            &.lay-1 {
                background-color: #faa9ba;
            }
            &.back-1 {
                background-color: #72bbef;
            }
        }
    }
}
.fancy_mobile {
    display: none;
    @media screen and (max-width:992px) {
        display: block;
        .fancy_table {
            display: block;
        }
    }
}
.highlightLabel {
    display: none;
    @media screen and (max-width:992px) {
        display: block;
    }
    .wrap-highlight {
        background-image: linear-gradient(-180deg, #000, #000 71%);
        border-bottom-color: #000;
        position: relative;
        height: 44px;
        background-color: #1e1e1e;
        overflow: hidden;
        width: 100%;
        @include bp(mini) {
            background-image: linear-gradient(180deg, #ffcc2e, #ffbd14);
            border-bottom-color: #070707;
        }
        @include bp(tab) {
            height: 12.26667vw;
        }
        .a-search {
            display: none;
            @include bp(tab) {
                display: block;
                position: absolute;
                background-image: linear-gradient(180deg, #525252, #2d2d2d) !important;
            }
            &::before {
                background-image: linear-gradient(90deg, transparent, #000 110%);
                content: "";
                right: 12.7vw;
                width: 8.26667vw;
                z-index: -1;
                height: 12.26667vw;
                position: absolute;
            }
        }
        ul {
            padding-right: 6px;
            width: 100%;
            overflow: auto;
            display: inline-block;
            white-space: nowrap;
            @include bp(tab) {
                padding-right: 21.33333vw;
            }
            li {
                position: relative;
                padding-top: 10px;
                margin-left: 6px;
                margin-top: 3px;
                display: inline-block;
                @include bp(mini) {
                    padding-top: 11px;
                }
                @include bp(tab) {
                    margin-left: 1.86667vw;
                    padding-top: 2vw;
                    position: relative;
                }
                a {
                    font-weight: 700;
                    color: #fff;
                    line-height: 30px;
                    padding: 0 6px;
                    border-radius: 5px 5px 0 0;
                    font-size: 11px;
                    display: flex;
                    @include bp(tab) {
                        border-radius: 1.6vw 1.6vw 0 0;
                        color: #fff;
                        font-weight: 700;
                        line-height: 9.6vw;
                        padding: 0 1.86667vw;
                        font-size: 3.46667vw;
                    }
                    img {
                        width: 18px;
                        height: 18px;
                        margin-top: 7px;
                        margin-right: 5px;
                        &.icon-cricket {
                            background: url("../../assets/images/cricket.svg") no-repeat;
                            background-size: contain !important;
                        }
                        &.icon-soccer {
                            background: url("../../assets/images/socer-black.svg") no-repeat;
                            background-size: contain !important;
                        }
                        &.icon-tennis {
                            background: url("../../assets/images/tennis-black.svg") no-repeat;
                            background-size: contain !important;
                        }
                        @include bp(tab) {
                            height: 5.33333vw;
                            margin: 2.13333vw 1.6vw 0 0;
                            width: 5.33333vw;
                        }
                    }
                }
                &.select {
                    img {
                        &.icon-cricket {
                            background: url("../../assets/images/cricket-y.svg") no-repeat;
                            background-size: contain !important;
                        }
                        &.icon-soccer {
                            background: url("../../assets/images/socer-yellow.svg") no-repeat;
                            background-size: contain !important;
                        }
                        &.icon-tennis {
                            background: url("../../assets/images/tenis-yellow.svg") no-repeat;
                            background-size: contain !important;
                        }
                    }
                }
                &.icon-casino {
                    .tag-new {
                        background: url("../../../public/images/new-tooltip.svg") no-repeat;
                        background-size: contain;
                        color: #fff;
                        -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .6));
                        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .6));
                        position: absolute;
                        z-index: 2;
                        text-align: center;
                        width: 35px;
                        height: 19.8672px;
                        font-size: 9.936px;
                        right: 3px;
                        top: 2px;
                        @include bp(tab) {
                            font-size: 2.4vw;
                            font-weight: 700;
                            height: 4.8vw;
                            line-height: 4vw;
                            width: 8.53333vw;
                            right: 2.13333vw;
                            top: 0.53333vw;
                        }
                    }
                    a {
                        background-image: linear-gradient(-180deg, #3b4cf5 15%, #8e0424) !important;
                        color: #fff;
                        &::after {
                            background: url("../../../public/images/redirect.svg");
                            background-size: cover;
                            height: 10px;
                            left: 7px;
                            margin-right: 7px;
                            position: relative;
                            top: 10px;
                            width: 10px;
                            content: "";
                            display: block;
                            @include bp(tab) {
                                height: 2.66667vw;
                                left: 1.06667vw;
                                margin-right: 1.06667vw;
                                top: 3.73333vw;
                                width: 2.66667vw;
                            }
                        }
                    }
                }
                .tag-live {
                    top: 2px;
                }
                &.select {
                    a {
                        color: #000;
                        background-image: linear-gradient(-180deg, #67f0c2 15%, #67f0c2);
                        @include bp(mini) {
                            background-image: linear-gradient(180deg, #474747, #070707) !important;
                            color: #ffb200;
                        }
                    }
                }
            }
        }
    }
}
.btn-book {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: normal;
}
.team1 {
    font-size: 14px;
}
.back1btn,
.lay1btn {
    font-weight: bold;
    font-size: 13px;
    span {
        font-size: 12px;
        font-weight: normal;
    }
}
.out_line_box {
    width: 90%;
    display: inline-block;
    border: 2px solid white;
    border-radius: 7px;
}
.fancy-info {
    position: absolute;
    top: 0;
    right: 2px;
    top: 2px;
    font-weight: normal;
    width: auto;
    height: auto;
    margin: 0;
    display: flex;
    // align-items: ;
    dt {
        font-size: 12px;
        span {
            width: 56px;
            text-align: center;
            background-color: #bed5d8;
            border-radius: 3px;
            color: #3b5160;
            font-size: 12px;
            display: inline-block;
        }
    }
    dd {
        margin-right: 8px;
        font-size: 12px;
    }
}
//// new design 
.fullmarket {
    // height: 87vh;
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    // /* Track */
    // ::-webkit-scrollbar-track {
    //     background: #f1f1f1;
    // }
    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: #888;
    // }
    // /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background: #555;
    // }
    .live-header {
        align-items: center;
        display: flex;
        font-size: 14px;
        height: 40px;
        justify-content: space-around;
        font-weight: 700;
        background-color: #fff;
        span {
            padding: 0 2px;
        }
    }
    .balance-label-detail {
        align-items: center;
        display: flex;
        height: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background: #000;
        color: #fff;
        background-color: #1e1e1e;
        font-size: 0.85rem;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        .balance-label-inner span {
            font-size: .7142857142857143rem;
        }
    }
    .match-odds-sec {
        .match-odd-header {
            &::after {
                background: #000;
                bottom: 4px;
                content: "";
                height: 3px;
                left: 16px;
                position: absolute;
                width: 100%;
            }
            &-outer {
                height: 2.4285714285714284rem;
                .match-odd-header-text {
                    font-size: 13.44px;
                    line-height: 1.5rem;
                    padding-left: 16px;
                    margin-right: 7px;
                    position: relative;
                    cursor: pointer;
                }
            }
        }
    }
    .match-odd-table li {
        align-items: center;
        background: #eef6fb;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        padding: 0 0 0 15px
    }
    .match-odd-table li>div {
        display: flex;
        text-align: center
    }
    .match-odd-table li h2 {
        font-size: 12.48px !important;
        font-weight: 700;
        margin: 0;
        padding-right: 10px
    }
    .lay-back-table .back {
        background-color: #7cc3f5
    }
    .lay-back-table .back strong {
        font-size: 14.4px;
        line-height: 10px
    }
    .lay-back-table .lay strong {
        // font-size: 1.0714285714285714rem;
        font-size: 14.4px;
        line-height: 10px
    }
    .d-flex.back-lay-head.justify-content-end.ms-auto.pb-1 {
        font-size: 9.6px;
        line-height: 14px;
        width: 104px
    }
    .lay-back-table .lay {
        background-color: #faa9ba
    }
    .match-odd-table li>div>div {
        background: #000;
        border-radius: 6px;
        font-size: 13px !important;
        height: 43px !important;
        margin-right: 3px;
        padding: 2px;
        width: 52px;
        position: relative;
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .match-odd-table li>div>div:last-child {
        margin: 0
    }
    .match-odd-table li>div>div:last-child strong {
        // font-size: 1.0714285714285714rem;
        font-size: 14.4px;
        line-height: 10px
    }
    .lay-back-table .d-block {
        font-size: 8.64px;
        line-height: 8px
    }
    .bg-bookmaker-market {
        background: linear-gradient(90deg, #7cc3f5, #c4abee 54%, #faa9ba);
        border-radius: 7px;
        padding: 4px;
        width: 104px !important;
        position: relative;
    }
    .bg-bookmaker-market div {
        height: 34px !important;
    }
    .bg-bookmaker-market>div {
        border: 1px solid #fff;
    }
    .bg-bookmaker-market>div, .overlay-match {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .bg-bookmaker-market {
        .overlay-match {
            width: 104px !important;
            height: 51px !important;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 21;
            pointer-events: none;
        }
    }
    .overlay-match {
        background: #6f8898 !important;
        border-radius: 6px;
        color: #fff;
        font-size: .7857142857142857rem;
        height: 100%;
        opacity: .8;
        position: absolute !important;
        right: 0;
        top: 0;
        width: 107px !important;
        font-size: 14px;
        pointer-events: none;
    }
    .market-depth-sec button img {
        margin-right: 4px;
        max-width: 24px
    }
    .market-depth-sec button {
        background-color: #000;
        font-size: 14.08px;
        font-family: Ubuntu, sans-serif;
        padding: 6px;
        color: #fff;
        border: none;
        border-radius: 4.48px !important;
        display: flex;
        gap: 5px;
        >div {
            width: 18px;
            height: 18px;
            background-color: #fff;
            border-radius: 50px;
            padding: 2px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .market-depth-sec button:hover {
        background-color: #000;
        border-color: #000
    }
    .min-max svg {
        background: #6f8898;
        border-radius: 100%;
        color: #fff;
        font-size: 14px;
        margin-right: 5px;
        padding: 4px
    }
    .info-btn svg {
        // color: #8b6b05
        color: var(--darkColor);

    }
    .info-btn {
        // background: #ffc800 !important;
        background: var(--bgColor) !important;
        margin-right: 7px;
        z-index: 10
    }
    .min-max span {
        margin-right: 5px
    }
    .min-max span, .min-max strong {
        font-size: 10.56px
    }
    .min-max {
        color: #6f8898;
        font-family: 'Lato';
    }
    .match-odd-header-tie:after {
        background: #000;
        bottom: 4px;
        content: "";
        height: 3px;
        left: 16px;
        position: absolute;
        width: 65px
    }
    .bookmaker {
        .accordion {
            &-item {
                border: none;
                border-radius: 10px !important;
                overflow: hidden;
            }
            &-header {
                margin-bottom: 0;
                font-size: calc(1.325rem + .9vw);
                &.active {
                    button {
                        &::after {
                            transform: rotate(-180deg);
                        }
                    }
                }
                button {
                    // background: #ffc800;
                    background: var(--bgColor);
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    // color: #000;
                    color: var(--color);;
                    font-size: 1rem;
                    padding: 8px 19px 8px 1px;
                    width: 100%;
                    display: block;
                    position: relative;
                    >div {
                        padding-left: 10px;
                    }
                    &::after {
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                        // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 1.25rem;
                        content: "";
                        flex-shrink: 0;
                        height: 1.25rem;
                        margin-left: auto;
                        transition: transform 0.4s ease-in-out;
                        width: 1.25rem;
                        right: 15px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        z-index: 99;
                        position: absolute;
                    }
                }
            }
            &-collapse {
                transition: max-height 0.5s ease-in-out;
                overflow: hidden;
            }
        }
    }
    .fancy-sport-tab {
        border-radius: 16px;
        overflow: hidden;
        .fancy-sport-header {
            background: #000;
            border-radius: 10px 10px 0 0;
            padding-top: 12px;
            .taber {
                // background: #735a08;
                background: var(--darkColor);
                border-radius: 10px 10px 0 0;
                margin: 0 11px;
                width: max-content;
                button {
                    // color: #ffc800;
                    color: var(--bgColor);
                    &.active-taber {
                        // background: #ffc800;
                        // color: #000;
                        background: var(--bgColor);
                        color: var(--colorSecond);
                    }
                }
            }
            button {
                margin-bottom: 0;
                background: transparent;
                border: none;
                border-radius: 7px 7px 0 0;
                font-family: Inter;
                padding: 7px 23px;
                text-align: center;
                font-size: 14px;
            }
        }
        .fancy-sports-inner-tab {
            // background: #ffc800;
            // color: #000;
            background: var(--bgColor);
            color: var(--colorSecond);
            display: flex;
            button {
                z-index: 10;
                margin: 0;
                line-height: normal;
                height: 100%;
            }
        }
        .sport-listing {
            padding-top: 16px;
            h2 {
                font-size: calc(1.325rem + .9vw);
                position: relative;
                button {
                    background-color: #eef6fb;
                    box-shadow: none;
                    color: #000;
                    font-size: 12.48px !important;
                    border-radius: 8px !important;
                    font-weight: 600;
                    opacity: 1;
                    padding: 10px 17px 10px 0;
                    width: 100%;
                    text-align: left;
                    border: none;
                    display: flex;
                    align-items: center;
                    font-family: 'Lato';
                    svg {
                        height: 18px;
                        margin: 1px 6px;
                        vertical-align: middle;
                    }
                    &::after {
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: contain;
                        content: "";
                        flex-shrink: 0;
                        height: 15px;
                        margin-left: auto;
                        transition: transform 0.4s ease-in-out;
                        width: 15px;
                        right: 15px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        z-index: 99;
                        position: absolute;
                        @include bp(mob) {
                            right: 2vw;
                        }
                    }
                }
            }
            .row {
                display: flex;
                flex-wrap: wrap;
                div {
                    padding: 0;
                    &:nth-child(n) {
                        padding-right: 3px;
                    }
                    &:nth-child(2n) {
                        padding-left: 3px;
                    }
                }
            }
            .accordion {
                &-header {
                    margin-bottom: 3px;
                    &.active {
                        button {
                            &::after {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
                &-collapse {
                    transition: max-height 0.2s ease-in-out;
                    overflow: hidden;
                }
            }
            &-score {
                margin-top: 4px;
            }
            .team-wise-score {
                background: #a1d2f4;
                border-radius: 0 0 10px 10px;
                line-height: 15px;
                margin-bottom: 3px;
                padding: 4px 0 !important;
                >span {
                    font-size: 9.6px !important;
                    font-weight: 700;
                }
                strong {
                    font-size: 14.4px !important;
                }
            }
        }
        .sports-tab-panel {
            h3 {
                font-size: 12.48px !important;
                font-weight: 700;
                position: relative;
                text-align: center;
                margin-bottom: 0 !important;
                &.active:after {
                    background: #000;
                    border-radius: 10px;
                    bottom: 0;
                    content: "";
                    height: 2.6875px;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    top: 15px;
                    width: 73px;
                    @include bp(mob) {
                        width: 49px;
                    }
                }
            }
            ul li {
                font-family: Inter;
                font-size: 18px;
                font-weight: 700;
                padding: 10px 16px;
                &:first-child {
                    padding-left: 0
                }
            }
        }
        ::-webkit-scrollbar {
            height: 2px
        }
    }
    .back, .lay {
        .single-disabled {
            pointer-events: none;
            &:before {
                background-color: rgb(38 44 50/.6);
                border-radius: 6px;
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
//info popup
.info-detail {
    background: #fff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999999;
    .title {
        background: #000;
        color: #fff;
        font-family: Inter;
        padding: 14px 0;
        text-align: center;
        h4 {
            font-size: 17px;
            margin: 0;
        }
        button {
            color: #fff;
            background: transparent;
            height: 100%;
        }
    }
    .info-body {
        height: calc(100% - 42px);
        overflow-y: auto;
        padding: 10px;
    }
    .info-rule {
        padding-left: 31px;
        li {
            font-size: 15px;
            margin-bottom: 14px;
            padding: 0 13px 0 4px;
            font-family: Inter;
            list-style: auto;
        }
    }
}
.spark-back {
    -webkit-animation: sparkBack .6s ease;
    animation: sparkBack .6s ease
}
@keyframes sparkBack {
    0% {
        background-color: #72bbef;
        color: #1e1e1e
    }
    50% {
        background-color: #f8e71c;
        color: #fff
    }
    to {
        background-color: #72bbef;
        color: #1e1e1e
    }
}
.spark-lay {
    -webkit-animation: sparkLay .6s ease;
    animation: sparkLay .6s ease
}
@-webkit-keyframes sparkLay {
    0% {
        background-color: #faa9ba;
        color: #1e1e1e;
    }
    50% {
        background-color: #26f1f8;
        color: #fff;
    }
    100% {
        background-color: #faa9ba;
        color: #1e1e1e;
    }
}
@keyframes sparkLay {
    0% {
        background-color: #faa9ba;
        color: #1e1e1e;
    }
    50% {
        background-color: #26f1f8;
        color: #fff;
    }
    100% {
        background-color: #faa9ba;
        color: #1e1e1e;
    }
}
.back-active {
    background-color: #379de6 !important
}
.lay-active {
    background-color: #f66987 !important
}
.slide-down {
    max-height: 1000px;
    /* Adjust the value based on your content */
}
.slide-up {
    max-height: 0;
}
.leading-normal {
    background: #ffffff91;
    border-radius: 19px;
    margin: 0 auto;
    padding: 2px 11px !important;
    width: -webkit-max-content;
    width: max-content;
    >div {
        font-size: 13px !important;
        font-weight: 700 !important;
        padding: 0;
    }
    span {
        display: flex;
        align-items: center;
        font-size: 13px !important;
        font-weight: 700 !important;
    }
}
.footer-bets.plays-bet {
    background: #fff;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    box-shadow: 0 -0.2rem 3rem 0 #000;
    left: 0;
    min-height: auto;
    padding: 8px 12px;
    position: fixed;
    width: 100%;
    z-index: 10000;
    .fs-4 {
        font-size: 17.28px !important;
    }
    .player-body {
        .keyboard-div {
            display: flex;
            flex-wrap: wrap;
            >div {
                padding: 0 3px;
            }
            :disabled {
                background-color: #d4e0e5 !important;
                color: #000 !important;
            }
        }
        .add-bet {
            background: #eef6fb;
            padding: 5px !important;
            span {
                color: #74828b;
                font-family: Ubuntu, sans-serif;
                font-size: .7857142857142857rem;
                margin-bottom: 8px;
            }
            button {
                background: #ffc800;
                border: none;
                border-radius: 4px;
                color: #000;
                display: flex;
                font-size: 35px;
                height: 36px;
                justify-content: center;
                line-height: 18px;
                margin: 0 4px;
                width: 50px;
                text-align: center;
                text-decoration: none;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                display: flex;
                align-items: center;
                font-weight: normal;
                font-family: inherit;
                &:disabled {
                    opacity: 0.65;
                }
            }
            .input-form-control {
                border: 1px solid #000;
                font-family: inherit;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 600;
                padding: 5px 8px;
                text-align: center;
                width: calc(100% - 64px);
                margin: 0;
                box-shadow: none;
                height: 36px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #000;
                position: relative;
                &.is-focus {
                    &::after {
                        animation: inputBlink-bfe2783f 1.2s step-start infinite;
                        content: "|";
                        font-weight: 400;
                        display: inline-block;
                    }
                }
            }
        }
        .fix-bet-btn {
            button {
                font-size: 14px;
                font-size: 13.44px !important;
                font-weight: 700;
                margin-right: 4px;
                padding: 7px;
                text-align: center;
                width: calc(25% - 4px);
                border-radius: 5px;
                font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                @include bp(mob) {
                    border-radius: 1vw;
                }
                &.bg-yellow {
                    font-size: 13.44px !important;
                    padding: 4px 6px
                }
            }
        }
        button.bg-yellow.btn.btn-primary.setting_icon {
            display: flex;
            align-items: center;
            height: 31px;
            border-radius: 5px;
        }
        .bet-numeric button {
            font-size: 13.4px !important;
            margin: 1px;
            padding: 9px 14px;
            width: calc(16.66% - 2px) !important;
        }
        .betting-numbers {
            margin-top: 10px;
            button {
                background: #eef6fb;
                border-color: #eef6fb;
                color: #000;
                font-size: 17px;
            }
        }
        .cross-bet {
            height: 90px !important;
            width: 80px !important;
            button {
                width: 100%;
                text-align: center;
                img {
                    margin: auto;
                    max-width: 25px !important;
                }
            }
        }
    }
}
.min-max {
    color: #6f8898;
    font-family: 'Lato';
    span, strong {
        font-size: 10.56px;
    }
    span {
        margin-right: 5px;
    }
    svg {
        background: #6f8898;
        border-radius: 100%;
        color: #fff;
        font-size: 14px;
        margin-right: 5px;
        padding: 4px;
    }
}
.place-bet-button:disabled {
    background-color: #d4e0e5 !important;
}
.bet-name {
    padding: 4px 14px;
    border-radius: 22px;
    color: #000;
    font-weight: 700;
    margin-right: 9px;
}
.topnav {
    align-items: center;
    background-color: #1e1e1e;
    display: flex;
    height: 42px;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    width: 100%;
    p {
        color: #f2f2f2;
        display: block;
        font-size: 14px;
        margin-bottom: 0;
        padding: 2px;
        text-align: center;
        text-decoration: none;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        border-bottom: 2px solid transparent;
        &:hover, &.active {
            border-bottom: 2px solid #fff;
        }
        &.active {
            font-weight: 700;
        }
    }
}
iframe {
    border: 0;
}
.sport-rules-sec {
    li {
        align-items: center;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAABvCAMAAADVG25SAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAP9QTFRFAAAA1dzhjqGtcomZb4eXeY+errzFmKm1cIiYztbcip6rw83TjJ+sd46d1t3hsL3GhZmngJWjv8rRxs/WpbS+hJmnl6i0fJKgm6y3i56rdo2ckKOvlqezdIubx9DW/////v//2+Hl8PL0na246+/x+Pn6nKy3tMDJ+vv8rbrD4+frepCf7O/yvcjQ+/z8z9fcwszTuMTM9ff4ip2qt8PLlaey197idYyb8/X28PP0kaSwwMrR9/j5lKaye5Gg6OzufpSioLC6/f393uTnkKKvdIuak6WxyNHXwcvSfpOimaq2y9TZwszTuMTMqLfAprW/jaCtytPZna64g5elobG7gON1+wAAAFV0Uk5TAA3/////////Ov+A//8H+f//mm3///////////////////////////////////////////////////////////////////9gjf//TYbG////U////1c97tIAAAHjSURBVHic7dnnUsJAFAXgxQQTlQgoUiwkdhSFCCpW7L2X938WURRCshs22QKD9/xiDnfyDbAbBhYhCAQC6VEiQ4oaPtHhgJymM2g/4kgQbpRRa0QZo+di7JyqGuO0XJz1zWwmQesluXCqPkHHTfJ5eaqq0XkpTpw6ReeleXkZ8MAD7397Gc+T4A2EF8u2k5tuXcRZz0R1XJ2dnQvh5U1HrPmEiqnNhUUdUy9lWD3TXF7RcbW1qojxzIKGra01QZ65XsTWG0lBnqVhazMlyDM38XWep1cq27ZdLrkv7Ffb9pYR2qtUGw+rFfeF/epWwOu9R1gYBI99vTiz3dVj3w+OlHbkers1qd5ervsy4ujtH1AsW25e4fBvTrB3dFz/TvvPH1/vd7qoh/Y6d3A3j9P9xev53QZEeM7I9lKkaTHeyalUzzojTovwzod14jR/z7q41MnTwTyj7trpHXUzV+66Y7q/fx+BBx544IHX557Y8xWvJ/b8yOuJPR/DeELP/zCe0PNNnIdy7Bzp/BbroWtWrnaD5wgeur1j0ZR7gkb0Gnl4DBsi5uuJCXjggef1jKfQ27uZSDCPOWk6j8NXQSDvWbKHDMnei2SP1ydI7aFXyR6K1+R6CL29f9QZ8xnEg0Agg5svPSREQ6osVdYAAAAASUVORK5CYII=');
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 30px;
        border-bottom: 1px solid #00000021;
        display: flex;
        font-family: Ubuntu, sans-serif;
        font-size: 20px;
        justify-content: space-between;
        padding: 13px 12px 13px 52px;
        width: 100%;
        span {
            font-size: 17px;
        }
        button {
            font-size: 18px;
            padding: 5px 10px;
            height: 38px;
            display: flex;
            align-items: center;
        }
    }
}
.bg-darklight {
    background-color: #d4e0e5;
    .row {
        display: flex;
        >div {
            padding: 0 7px;
        }
    }
}
.defultinput {
    border: 2px solid transparent;
    &.active {
        border: 2px solid rgb(192, 160, 36);
        border-radius: 0.375rem;
    }
}
.default-stake .betting-numbers .bet-numeric button {
    height: 100%;
    min-height: 53px;
    background: #eef6fb;
    border-color: #eef6fb;
    color: #000;
    font-size: 13.4px !important;
    margin: 1px;
    padding: 9px 14px;
    width: calc(16.66% - 2px) !important;
    img {
        margin: auto;
    }
}
.default-stake .betting-numbers {
    .footer-btn {
        font-size: 13.44px !important;
    }
}
.default-stake .form-control {
    padding: 10px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    appearance: none;
}
.sports-widget {
    background: #000;
    bottom: 0;
    color: #fff;
    padding: 10px;
    position: absolute;
    width: 100%;
    z-index: 9999;
    @include bp(480px) {
        position: fixed;
    }
    &-bottom-text {
        // color: #ffc800;
        color: var(--color);
        margin-top: 3px;
        display: block;
        font-size: 14px;
        text-transform: capitalize;
        @include bp(mob) {
            font-size: 3vw;
        }
    }
    ul {
        background: #fff;
        border: 2px solid transparent;
        border-radius: 6px;
        color: #000;
        cursor: pointer;
        padding: 7px 9px;
        li {
            display: flex;
            font-family: Inter;
            font-size: 13px;
            font-weight: 600;
            justify-content: space-between;
            span {
                color: #c69700;
                display: block
            }
            .text {
                color: #000;
                font-size: 11px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 85%
            }
        }
        &.widget-active {
            // border: 2px solid #ffc800;
            border: 2px solid var(--bgColor);
            box-shadow: inset 0 0 3px 1px #0006;
            // outline-color: #ffc800;
            outline-color: var(--bgColor);
            outline-style: solid;
            outline-width: 1px
        }
    }
    .home-banner-sec .slick-slide>div {
        margin: 0
    }
    .slick-slide>div {
        margin: 0 4px
    }
    .widgetCloseButton {
        align-items: center;
        background: linear-gradient(90deg, transparent, #000);
        bottom: 0;
        cursor: pointer;
        display: flex;
        font-size: 33px;
        justify-content: end;
        opacity: 1;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
        width: 4rem
    }
}
.market-data {
    background-color: rgb(255 255 255);
    color: #1e1e1e;
    font-family: Ubuntu, sans-serif;
    &.list-area {
        height: calc(100% - 2.35714rem);
    }
    .main-filter-ul {
        background-color: rgb(212 224 229);
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Ubuntu, sans-serif;
        color: #1e1e1e;
        .event-market-name {
            display: flex;
            align-items: center;
            justify-content: center;
            .mx-4px {
                margin-left: 5.1px;
                margin-right: 5.1px;
            }
        }
        .leading-tight {
            line-height: 1.25;
        }
        .text-13 {
            font-size: 16.67px;
        }
    }
    &-info {
        height: 57px;
        font-size: 17px;
        background: #000;
        color: #fff;
        line-height: 57px;
    }
    .icon-arrow-left, .icon-arrow-right {
        svg {
            width: 12px;
            height: 12px;
            transform: rotate(180deg);
            path {
                fill: rgb(26 142 225/1);
                stroke: rgb(26 142 225/1);
                stroke-width: 50px;
            }
        }
    }
    .icon-arrow-right {
        transform: rotate(180deg);
        path {
            fill: rgb(244, 73, 109) !important;
            stroke: rgb(244, 73, 109) !important;
            stroke-width: 50px;
        }
    }
    &-btn {
        div {
            width: 90px;
            height: 35px;
            align-items: center;
            font-size: 16px;
            font-family: Ubuntu, sans-serif;
            gap: 5px;
        }
    }
    &-list {
        >div {
            >div {
                height: 53px;
            }
        }
        div {
            font-size: 12.8px;
            >div {
                width: 101px;
                flex-wrap: wrap;
                text-align: center;
                line-height: normal;
            }
        }
        .upper-div {
            >div>div {
                height: 53px;
                align-content: center;
                font-size: 14px;
                div {
                    &:first-child {
                        font-size: 16px;
                    }
                }
            }
        }
        .lowwer-div {
            >div>div {
                height: 31px;
                align-content: center;
                font-size: 14px;
            }
        }
    }
}
.slight-delay {
    padding-left: 13px;
    font-size: 15px;
    margin-bottom: 15px;
}
.live_match {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
}
@keyframes inputBlink-bfe2783f {
    50% {
        opacity: 0;
    }
}

.cst_live_tv_section {
    // position: relative;
    // overflow: hidden;
    // width: 100%;
    // padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    /* Then style the iframe to fit in the container div with full height and width */
    // .cst_live_tv {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 100%;
    //     border: none;
    // }
    &.live_tv {
        iframe {
            @include bp(mob) {
                min-height: 57vw !important;
            }
        }
    }
    &.score_card {
        
        iframe {
            min-height: 184px;
            @include bp(mob) {
                min-height: 168px !important;
            }
            @include bp(480px)
            {
                min-height: 160px !important;
            }
        }
    }
}