.popup-wrp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &.popup-show {
    transition: opacity 0.15s linear;
    background-color: #00000080;
    display: block;

    .pop {
      transform: none;
    }
  }

  &.fade {
    transition: opacity 0.15s linear;

    &:not(.popup-show) {
      opacity: 0;
    }
  }

  .pop {
    max-width: 500px;
    margin: 28px auto;
    position: relative;
    width: auto;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
    }

    &-head {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px);

      h5 {
        font-size: 20px;
        font-weight: 500;
      }
    }

    &-close {
      box-sizing: content-box;
      width: 12px;
      height: 12px;
      padding: 8px;
      color: #000;
      background: url("../../assets/images/close.svg") no-repeat;
      border: 0;
      border-radius: 0.25rem;
      opacity: 0.5;
      background-size: 12px;
      background-position: center;
    }

    &-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      margin: 0 -12px;

      .d_flex {
        flex-wrap: wrap;

        .fieldset {
          padding: 0 12px;
          flex: 0 0 auto;
          width: 50%;
          margin-bottom: 8px;

          &.full {
            flex: 0 0 auto;
            width: 100%;
          }

          .form-control {
            display: block;
            width: 100%;
            height: 35px;
            padding: 6px 12px;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 4px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }

          div>span {
            margin-bottom: 2px;
            display: block;
          }
        }

        &.rolling {
          .fieldset {
            flex: 0 0 auto;
            width: 25%;
          }
        }
      }

      .addplayer {
        .fieldset {
          margin-bottom: 15px;
        }
      }
    }

    &-footer {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 0;
      align-items: center;
      justify-content: flex-end;
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px);
    }
  }
}

.form-check-input {
  width: 15px;
  height: 15px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  &[type=radio] {
    border-radius: 50%;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
      border-width: 3px;
      background-image: url(../../assets/images/white_round.svg);
    }
  }
}

.form-switch {
  padding-left: 30px;
  display: flex;
  align-items: center;
  position: relative;

  .form-check-input {
    float: left;
    width: 24px;
    background-image: url(../../assets/images/gray_round.svg);
    background-position: left center;
    border-radius: 22px;
    transition: background-position 0.15s ease-in-out;
    position: absolute;
    left: 0;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
      background-position: right center;
      background-image: url(../../assets/images/white_round.svg);
    }
  }
}

.view_winner {
  .modal-body {
    padding: 0 14px;
  }

  .form-check {
    &-label {
      font-size: 15px;
    }
  }
}

.betpopup {
  display: flex !important;
  align-items: center;
  justify-content: center;

  .pop {
    width: 100%;

    .pop-head {
      background-color: #ffcc2f;
      padding: 8px 15px;
    }

    .pop-body {
      padding: 15px;
      margin: 0;

      &_title {
        display: flex;
        justify-content: center;

        .lay {
          padding: 5px 10px;
          border-radius: 4px;
          font-size: 16px;
          width: 54px;
          text-align: center;
        }

        h3 {
          width: calc(100% - 54px);
          padding-left: 15px;
          padding-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: normal;
        }
      }

      ul {
        border: 1px solid #d3d3d3;
        display: flex;
        margin-top: 14px;
        border-radius: 3px;

        li {
          border-right: 1px solid #d3d3d3;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          padding: 10px 5px;
          font-size: 16px;
          text-align: center;

          &:last-child {
            border-right: 0;
          }

          span {
            display: block;
            width: 100%;
            font-size: 14px;

            &.liability_red {
              color: red;
            }

            &.liability_green {
              color: green;
            }
          }
        }
      }
    }

    .pop-footer {
      border: none;
      padding: 0 15px;

      .button-wrap {
        button {
          width: 50%;
          cursor: pointer;
          padding: 2px 20px;
          font-weight: normal;

          &:last-child {
            margin-right: 0;
          }

          &.submit-btn {
            background-color: #ffcc2f;
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.runposition {
  padding: 0 10px;

  &.popup-wrp .pop-body {
    margin: 0;

    table {
      margin-bottom: 0;
    }

    .table-bordered>:not(caption)>*>* {
      border-width: 0 1px;
      padding: 8px;
      border-color: rgb(222, 226, 236);
    }

    .table-bordered>:not(caption)>* {
      border-width: 1px 0;
      border-color: rgb(222, 226, 236);
    }
  }

  .pop-footer {
    .btn-outline-dark {
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }

  .text-left {
    text-align: left;
  }
}

.announcement-modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  outline: 0;
  z-index: 99999;
  &.modal-show{
    display: block;
  }

  .modal-content {
    max-width: 98%;
    margin: 0 auto;
    .modal-header{
      h4{
        font-size: 15px;
        color: #fff;
        padding: 10px 0;
        flex-grow: 1;
      }
      .close{
        background-image: url("../../../public/images/front-all-icon1.png");
        background-position: -394px -1395px;
        cursor: pointer;
        margin-right: -5px;
        margin-top: 6px;
        width: 26px;
        height: 26px;
      }
    }
    .modal-body{
      height: calc(100vh - 20px);
      background-color: #ffffff;
      border-radius: 8px 8px 0 0;
      overflow-y: auto;
      padding: 30px;
      .ver-tag{
        background-color: #000;
        color: #fff;
        border-radius: 20px;
        font-size: 14px;
        padding: 4px 13px;
        margin-bottom: 15px;
        display: inline-flex;
      }
      p{
        font-size: 16px;
        line-height: 20px;
        color: #1e1e1e;
        margin-bottom: 8px;
      }
      .line{
        margin: 16px 0;
        height: 0.7px;
        background-color: #d4e0e5;
      }
    }
  }
}