.home {
    // position: relative;
    max-width: 480px;
    height: calc(100% - 105px);
    margin: 0 auto;

    @include bp(mob) {
        max-width: 100%;
    }

    &_wrp {
        width: 100%;

        .marquee-box {
            margin-top: -5px;
            margin-bottom: 5px;
            align-items: center;
        }

        &_promobanner {
            margin: 0 auto;
            text-align: center;

            @include bp(tab) {
                margin-bottom: -3px;
                // margin-bottom: -18px;
            }

            .slick-slider {
                img {
                    width: 100%;
                    // height: 128px;
                    height: 120px;
                    object-fit: cover;
                }
            }
        }

        .gamehall-wrap-simple {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            @include bp(tab) {
                // padding: 1.6vw;
                // grid-gap: 5px;
                grid-gap: 1.6vw;
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 1.6vw;
            }

            a {
                position: relative;
                grid-column: span 2;
                cursor: pointer;
                text-decoration: none;
                color: #2789ce;
                outline: none;

                img {
                    position: relative;
                    z-index: 2;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .entrance-half {
                grid-column: span 1;

                @include bp(mini) {
                    grid-column: span 2;
                }

                @include bp(tab) {
                    grid-column: span 1;
                }
            }

            // a {
            //     @include bp(mob) {
            //         grid-column: span 4;
            //     }
            // }
            .entrance-title {
                position: absolute;
                z-index: 4;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 30px;
                background-image: linear-gradient(270deg, rgba(69, 94, 104, 0) 4%, #000 97%);
                border-bottom: 5px solid #FFB80C;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include bp(1600px) {
                    height: 42px;
                }

                @include bp(mob) {
                    height: 35px;
                }

                @include bp(tab) {
                    height: auto;
                }

                dt {
                    flex: 1;
                    font-size: 17px;
                    line-height: 28px;
                    text-indent: 10px;
                    color: #fff;
                    font-weight: bold;
                    width: calc(100% - 98px);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    @include bp(1600px) {
                        line-height: 42px;
                        font-size: 18px;
                    }

                    @include bp(tab) {
                        font-size: 3.73333vw;
                        font-weight: 700;
                        text-indent: 1.33333vw;
                        line-height: 6.4vw;
                    }
                }

                dd {
                    line-height: 30px;
                    height: 100%;
                    background-image: url('../../assets/images/left_yellow_shap.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left;
                    text-indent: 15px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #000 !important;

                    @include bp(1600px) {
                        width: 118px;
                        font-size: 14px;
                        line-height: 42px;
                        text-indent: 23px;
                    }

                    @include bp(tab) {
                        font-size: 2.93333vw;
                        font-weight: 700;
                        height: 6.4vw;
                        line-height: 7.46667vw;
                        text-indent: 3.73333vw;
                        width: 18.13333vw;

                        .playnow_button {
                            width: 14.4vw;
                            text-align: left;
                            text-indent: 0vw;
                        }
                    }

                    // &:after {
                    //     position: absolute;
                    //     z-index: -1;
                    //     bottom: 0;
                    //     right: 0;
                    //     width: 64px;
                    //     height: 100%;
                    //     background-color: #FFB80C;
                    //     content: "";
                    //     @include bp(1600px) {
                    //         width: 75px;
                    //     }
                    //     @include bp(mob) {
                    //         width: 51px;
                    //     }
                    // }
                }
            }

            .on_live {
                position: absolute;
                z-index: 3;
                right: 0;
                width: 120px;
                padding: 6px;
                background-image: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.7) 82%, transparent 100%);

                @include bp(tab) {
                    background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, .7) 82%, transparent);
                    padding: 1.33333vw;
                    position: absolute;
                    right: 0;
                    width: 23%;
                    z-index: 3;
                }

                dt {
                    margin-bottom: 12px;
                }

                dd {
                    color: #fff;
                    font-size: 13px;
                    line-height: 16px;
                    margin-bottom: 7px;
                    vertical-align: top;
                    flex-wrap: wrap;
                    display: flex;

                    @include bp(tab) {
                        font-size: 2.66667vw;
                        line-height: 3.73333vw;
                        margin-bottom: 1.06667vw;
                    }

                    p {
                        flex: 1;
                        margin-bottom: 0;
                        line-height: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        @include bp(tab) {
                            font-size: 2.66667vw;
                            line-height: 3.73333vw;
                        }
                    }

                    span {
                        color: #333333;
                        background: #FFFFFF;
                        text-align: center;
                        border-radius: 3px;
                        padding: 0 3px;
                        min-width: 16px;
                        height: 16px;

                        @include bp(tab) {
                            border-radius: 0.53333vw;
                            height: 3.73333vw;
                            min-width: 3.73333vw;
                        }
                    }
                }

                .live_icon {
                    height: 20px;
                    line-height: 20px;
                    color: #fff;
                    padding-right: 5px;
                    font-size: 12px;
                    font-weight: bold;
                    background-image: linear-gradient(180deg, #FB3434 0%, #E80505 100%);
                    border-radius: 3px;
                    margin-bottom: 0;
                    display: inline-flex;

                    @include bp(tab) {
                        border-radius: 0.8vw;
                        font-weight: 700;
                        height: 4.26667vw;
                        line-height: 4.26667vw;
                        padding-right: 1.33333vw;
                        font-size: 3.46667vw;
                    }

                    span {
                        @include bp(tab) {
                            background-image: linear-gradient(180deg, #fff, #e8e8e8);
                            align-items: center;
                            justify-content: center;
                            line-height: 4.26667vw;
                            border-radius: 0.8vw 0 0 0.8vw;
                            height: 4.26667vw;
                            margin-right: 1.33333vw;
                            padding: 0 0.8vw;
                        }

                        &::before {
                            content: "";
                            width: 16px;
                            height: 10px;
                            background: url("../../assets/images/new.svg") no-repeat;
                            background-size: contain;

                            @include bp(tab) {
                                background-size: contain;
                                height: 2.66667vw;
                                width: 4.26667vw;
                            }
                        }
                    }
                }

                span {
                    height: 100%;
                    padding: 0 3px;
                    margin-right: 5px;
                    background-image: linear-gradient(180deg, #fff 0%, #E8E8E8 100%);
                    border-radius: 3px 0 0 3px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
            }
        }
    }
}

.update_status {
    &_block {
        padding: 0 12px;

        .d_flex {
            justify-content: space-between;

            .badge {
                margin-right: 5px;
            }
        }

        &_user {
            margin-bottom: 15px;
        }

        &_content {
            margin-bottom: 15px;

            &_item {
                flex: 0 0 auto;
                width: 33.33333333%;
                padding: 0 12px;

                .card {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #fff;
                    background-clip: border-box;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    border-radius: 0.25rem;

                    &.active {
                        &:hover {
                            background-color: $greenbg;
                            color: white;
                            cursor: pointer;

                            svg {
                                width: 35px;

                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }

                    &.suspend {
                        &:hover {
                            background-color: #dc3545;
                            color: white;
                            cursor: pointer;

                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }

                    &.locked {
                        &:hover {
                            background-color: #212529;
                            color: white;
                            cursor: pointer;

                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }

                    &.selected {
                        &.active {
                            background-color: $greenbg;
                            color: white;
                            cursor: pointer;

                            svg {
                                width: 35px;

                                path {
                                    fill: $white;
                                }
                            }
                        }

                        &.suspend {
                            background-color: #dc3545;
                            color: white;
                            cursor: pointer;

                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }

                        &.locked {
                            background-color: #212529;
                            color: white;
                            cursor: pointer;

                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }

                    &-body {
                        flex: 1 1 auto;
                        padding: 16px;
                        text-align: center;

                        svg {
                            width: 35px;
                            height: 40px;
                            margin-bottom: 10px;

                            path {
                                fill: $black;
                            }
                        }

                        .card-title {
                            margin-bottom: 8px;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

@keyframes marqueeAnimation {
    100% {
        transform: translateX(-2889px);
    }
}

.marquee-box {
    color: #fff;
    line-height: 35px;
    height: 29px;
    margin-bottom: 1px;
    background-repeat: repeat-x;
    display: flex;
    align-items: center;

    h4,
    h5 {
        position: relative;
        flex-basis: 42px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 0;
        border-right: 1px solid #0000003b;
        height: 25px;

        @include bp(mob) {
            padding: 0 3.733vw;
        }

        svg {
            font-size: 1.1428571428571428rem;

            path {
                fill: #000;
            }

            @include bp(mob) {
                font-size: 4.267vw;
            }
        }
    }

    .marquee {
        // flex: 1;
        overflow: hidden;
        z-index: 1;
        width: 100%;
        display: flex;

        .js-marquee-wrapper {
            animation-duration: 38s;
            animation-iteration-count: infinite;
            animation-name: marquee-efef9c71;
            animation-timing-function: linear;
            display: inline-flex;
            align-items: center;
            position: relative;
            // .js-marquee {
            //     margin-right: 0px;
            //     float: left;
            // }
        }

        a {
            height: 35px;
            line-height: 35px;
            font-size: 14px;
            font-weight: bold;
            color: #212529;
            // margin-right: 100px;
            align-items: center;
            float: left;
            // display: flex;
            font-family: Ubuntu, sans-serif;
            white-space: nowrap;

            @include bp(mob) {
                font-size: 2.933vw;
            }

            span {
                height: 15px;
                line-height: 15px;
                font-size: 11px;
                font-style: italic;
                font-weight: normal;
                color: #1c2834;
                background-color: #6ac2ff;
                border-radius: 2px;
                padding: 0 5px;
                margin-right: 5px;
            }
        }
    }
}

.slick-list,
.slick-slider {
    display: block;
    position: relative;
}

.games {
    &-slot {
        background: #1b1f23;
        color: #fff;
        padding: 0.5rem 0 16px;
        margin-top: -5px;

        &-item {
            width: 80px !important;
            margin: 0 4px;
            border-radius: 9px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 4px;
            text-align: center;

            @include bp(480px) {
                width: 16vw !important;
            }

            img {
                margin: 0 auto;
                max-width: 48px;
                object-fit: contain;

                @include bp(480px) {
                    max-width: 10.9vw;
                }
            }

            span {
                color: #ffffff73;
                display: block;
                font-size: .8571428571428571rem !important;
                font-weight: 600;

                @include bp(mob) {
                    font-size: 3.13vw !important;
                    font-weight: 800;
                }
            }

            &:hover {
                background: #494c4f;
            }

            &.active {
                background: #494c4f;

                span {
                    // color: #ffc800;
                    color: var(--color);
                }
            }
        }
    }

    &-inner {
        background: #f0f8ff;
        display: none;
        padding: 6px 6px !important;

        &.active {
            display: flex;
        }

        aside {
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 1px 4px 2px rgb(0 0 0/3%);
            flex: 0 0 48.9531px;
            height: 100%;
            margin-right: 6.72px !important;
            max-width: 48.9531px !important;
            padding: 4px 2px !important;

            li {
                align-items: center;
                border-radius: 11px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                height: 4.0rem !important;
                margin-bottom: 1rem;
                padding: 0.5rem 0 !important;
                text-align: center;
                overflow: hidden;

                &.active {
                    // background-color: #ffc800;
                    background-color: var(--bgColor);
                    border-radius: 0.5rem;

                    svg {
                        opacity: 1;
                    }
                }

                svg {
                    font-size: 30px;
                    opacity: .7;
                    vertical-align: middle;
                }

                span {
                    color: #5a5e62;
                    display: block;
                    font-size: .6428571428571429rem !important;
                    margin-top: 8px;
                }
            }
        }
    }

    &-wrapper {
        flex: 1 1;
        padding: 3px 1px 3px 6px;
        display: none;

        &.active {
            display: block;
        }

        .card {
            background-position: 100%;
            background-size: cover;
            border: none;
            border-radius: 9px;
            box-shadow: 0 1px 6px 4px rgba(0, 0, 0, .06);
            height: 6rem;
            margin-bottom: 0.5rem !important;
            padding: 6px 16px !important;

            &.card1 {
                background-image: url('./../../../public/images/HomeCategory/banner1-green.webp')
            }

            &.card2 {
                background-image: url('./../../../public/images/HomeCategory/banner2-green.webp')
            }

            &.card3 {
                background-image: url('./../../../public/images/HomeCategory/banner3-green.webp')
            }

            &.card4 {
                background-image: url('./../../../public/images/HomeCategory/banner4-green.webp')
            }

            &.card11 {
                background-image: url('./../../../public/images/HomeCategory/banner1.jpg')
            }

            &.card22 {
                background-image: url('./../../../public/images/HomeCategory/banner2.jpg')
            }

            &.card33 {
                background-image: url('./../../../public/images/HomeCategory/banner3.jpg')
            }

            &.card44 {
                background-image: url('./../../../public/images/HomeCategory/banner4.jpg')
            }

            span {
                font-size: 1.0714285714285714rem !important;
                font-weight: 700;
                opacity: .8;
                color: #212529;
            }

            strong {
                font-family: inherit;
                font-size: 3.4285714285714284rem !important;
                font-weight: 700;
                line-height: 50px;
            }
        }

        .games-card-inner {
            background: #dce6eb;
            margin-bottom: 12px;
            padding: 10px 20px;

            h2 {
                border-left: 3px solid #000;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 8px;
                padding-left: 5px;
            }

            .row {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .game-card {
            margin: 4px;
            padding: 0;
            width: calc(33.33% - 8px);
            position: relative !important;
            border-radius: 0.75rem;
            cursor: pointer;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
                vertical-align: middle;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.1, 1.1, 1);
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        }

        .viewmore {
            align-items: center !important;
            width: 100% !important;
            justify-content: center !important;

            button {
                background: black;
                border: none;
                color: #fff;
                font-size: 17px;
                font-weight: 400;
                line-height: 1.5;
                display: flex;
                align-items: center;
                margin: auto;
                padding: 6.72px 13.44px;
                border-radius: 6.72px;
                cursor: pointer;

                @include bp(480px) {
                    font-size: 12px;
                    padding: 4.72px 10.44px;
                    border-radius: 5.72px;
                }
            }
        }
    }
}

@keyframes marquee-efef9c71 {
    0% {
        left: 100%;
        transform: translate(0)
    }

    to {
        left: 0;
        transform: translate(-100%)
    }
}




.star-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-inplay {
    margin-bottom: 30px;
    .sport-accord-body-wrp {
        display: grid;
        grid-template-columns:55px 50px 1fr .5fr;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dedede;
    }

    .inplay-box {
        height: 100%;
        flex-shrink: 0;
        padding: 0 2px;
        width: 55px;

        .inplay {
            font-size: 10px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &[data-is-inplay-type=true] {
            background-color: #52bf04;
            color: #ffffff;
        }

        &[data-is-today-type=true] {
            background-color: #ffd84d;
            color: #1e1e1e;
            .inplay {
            color: #1e1e1e;
            }
        }

        &[data-is-tomorrow-type=true] {
            background-color: #e2eaef;
            .inplay {
                color: #1e1e1e;
                }
         
        }
    }

    .fave.bg-star-sprit-black {
        background-image: url("../../../public/images/mypin-star-sprit-black.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 4px 0;

        height: 6.076vw;
        width: 2rem;
    }

    .match-name {
        font-size: 3.467vw;
    }
    .inplay {
        // background: #52bf05;
        color: #fff;
        font-size: 0.5714285714rem;
        font-weight: 600;
        height: auto;
        padding: 0 6px;
    }
    .counter {
        color: var(--bgColor);
        font-weight: 700;
        font-size: 1rem;
        min-width: 32px;
    }
}