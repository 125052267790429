.footer {
    // margin: 20px 0 40px;
    padding: 14px 12px;
    &_support {
        display: block;
        max-width: 690px;
        background-color: transparent;
        border-radius: 8px;
        margin: 0 auto 20px auto;
        padding: 5px 0px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
        line-height: 22px;
        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.support-social {
                .extend-btn {
                    flex-basis: 32.4%;
                }
            }
            &_links {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                a {
                    width: 100%;
                    line-height: normal;
                    margin-top: 5px;
                    line-height: normal !important;
                }
            }
            .r_f_c {
                display: unset !important;
            }
        }
        .extend-btn {
            flex-basis: 49.4%;
            background-color: #fff;
            padding: 8px 4px;
            border-radius: 8px;
            border: 1px solid rgba(151, 151, 151, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 8px;
            img {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                display: inline-block;
            }
            a {
                color: rgba(0, 0, 0, 0.5);
                line-height: 40px;
                text-align: center;
                margin-right: 8px;
                &.split-line {
                    border-left: 1px solid rgba(0, 0, 0, 0.5);
                    padding-left: 10px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .f_c {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &_power {
        margin: 0 auto;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        max-width: 300px;
        justify-content: center;
        h3 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 1px solid rgba(0, 0, 0, 0.3);
            padding-right: 7px;
            margin-right: 7px;
            span {
                font-size: 10px;
                font-weight: normal;
            }
            .powerby {
                background-position: 0 -1330px;
                height: 18px;
                width: 107px;
                background-image: url('../../../public/images/login-image.png');
                background-repeat: no-repeat;
            }
        }
        .licence_embed {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: center;
            iframe {
                border: none;
            }
            a {
                img {
                    max-width: 157px;
                    margin: auto;
                    @include bp(mob) {
                        max-width: 118px;
                    }
                }
            }
        }
        p {
            flex: 1;
            font-size: 11px;
            line-height: 14px;
            margin: 0;
            padding-left: 5px;
            color: #000;
        }
    }
    .app-link {
        display: block;
        width: 100%;
        font-size: 10px;
        color: rgba(0, 0, 0, 1);
        margin-top: 20px;
        text-align: center;
        a {
            margin-bottom: 5px;
            display: block;
        }
        img {
            width: 155px;
            height: auto;
            margin: auto;
        }
    }
    .policy-link {
        max-width: 620px;
        width: 100%;
        padding-top: 8px;
        margin: 8px auto 15px;
        text-align: center;
        // padding-left: 32px;
        text-decoration: underline !important;
        font-size: 12px;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        li {
            display: inline-block;
            border-right: 1px solid #00000029;
            display: inline-block;
            font-size: 13.44px;
            line-height: 5px;
            padding: 10px 9px;
            @include bp(480px) {
                padding: 2vw 1.6vw;
            }
            &:last-child {
                border: none;
            }
            a {
                color: rgba(0, 0, 0, 1);
                text-decoration: none;
                font-size: 13.44px;
                line-height: 5px;
                @include bp(480px) {
                    font-size: 3vw;
                }
                @include bp(360px) {
                    font-size: 2.9vw;
                }
            }
        }
    }
    &_browser {
        display: flex;
        color: rgba(0, 0, 0, 0.6);
        font-size: 11px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
        >div {
            background-image: url('../../../public/images/login-image.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            height: 20px;
            width: 50px;
        }
    }
    .social_tab {
        margin-top: 10px;
        .row {
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width: 992px) {
                margin: 0;
            }
            .nav {
                &.nav-pills {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    position: relative;
                    width: 100%;
                    justify-content: center;
                    // &::before {
                    //     content: "";
                    //     display: block;
                    //     flex: 1 1;
                    //     align-self: flex-end;
                    //     height: 1px;
                    //     margin-bottom: -1px;
                    //     background-color: #00000026;
                    //     position: absolute;
                    //     bottom: 0;
                    //     width: 100%;
                    // }
                    .nav-item {
                        position: relative;
                        .nav-link {
                            position: relative;
                            padding: 10px 12px;
                            background: 0 0;
                            border: 0;
                            border-radius: 0.25rem;
                            display: block;
                            img {
                                width: 25px;
                                display: block;
                            }
                            svg {
                                font-size: 34px;
                                color: #000;
                            }
                            // &:hover,
                            // &.open {
                            //     opacity: 1;
                            //     .nav-link {
                            //         opacity: 1;
                            //     }
                            //     &::before {
                            //         content: "";
                            //         position: absolute;
                            //         border-left: 6px solid transparent;
                            //         border-right: 6px solid transparent;
                            //         top: 100%;
                            //         left: 50%;
                            //         margin-left: -6px;
                            //         border-top: 6px solid #ccc9bf;
                            //         margin-top: 1px;
                            //         z-index: 1;
                            //     }
                            //     &::after {
                            //         content: "";
                            //         border-top: 6px solid #f0ece1;
                            //         margin-top: 0;
                            //         z-index: 1;
                            //         position: absolute;
                            //         border-left: 6px solid transparent;
                            //         border-right: 6px solid transparent;
                            //         top: 100%;
                            //         left: 50%;
                            //         z-index: 1;
                            //         margin-left: -6px;
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
    .brand-wrap {
        margin-top: 30px;
        width: 131px;
        display: block;
        margin: 0 auto 30px;
        border: 1px solid rgba(0, 0, 0, .3);
        border-radius: 8px;
        padding: 10px;
        h3 {
            line-height: 15px;
            margin-top: -10px;
            text-align: left;
            color: #484745;
            span {
                font-size: 10px;
                font-weight: 400;
            }
            img {
                width: 107px;
            }
        }
    }
    .browser-wraper {
        font-size: 11px;
        text-align: center;
    }
}
.support-wrap {
    background-color: #ffffff80;
    color: #0009;
    font-size: 13px;
    .support-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        .support-detail {
            display: none;
            &.open {
                display: block;
            }
            a {
                margin-right: 8px;
                color: #000;
                text-decoration: underline;
                &:nth-child(2n) {
                    padding: 0 8px;
                    border-left: 1px solid #0003;
                    border-right: 1px solid #0003;
                }
                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                    border-right: 0;
                }
            }
        }
    }
}
.r_f_c {
    display: unset !important;
}
.support-Telegram {
    @media screen and (max-width :576px) {
        width: 18px !important;
        height: 18px !important;
    }
}
.bottom-navigation {
    background: #fff;
    border-top: 1px solid #00000024;
    bottom: 0;
    position: absolute;
    width: 100%;
    @include bp(480px) {
        position: fixed;
    }
    ul {
        display: flex;
        justify-content: space-between;
        li {
            border-radius: 10px;
            cursor: pointer;
            font-family: Inter;
            font-size: 0.785714rem;
            padding: 6px 20px;
            text-align: center;
            @include bp(mob) {
                padding: 6px 8px;
            }
            &.active {
                background: #ffc800;
            }
            span {
                font-size: 0.785714rem;
                font-family: Inter;
            }
        }
    }
    div {
        display: block;
        margin: 0 auto;
        max-width: 37px;
        @include bp(mob) {
            max-width: 31px;
        }
    }
}
.bottomfooter {
    padding-bottom: 85px;
}
.mybet {
    &_tab {
        background: rgb(212, 224, 229);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 0px;
        div {
            border-bottom: none;
            padding-bottom: 6px;
            font-size: 15px;
            &:first-child {
                margin-right: 20px;
            }
            &.active {
                border-bottom: 4px solid rgb(0, 0, 0);
            }
            span {
                padding: 2px 5px;
                background-color: #212529;
                color: #fff;
            }
        }
    }
}
.market-depth-body {
    .match-border-sec {
        border-bottom: 4px solid #000;
        margin: 0 auto;
        padding-bottom: 6px;
        width: -webkit-max-content;
        width: max-content;
        span {
            background-color: #000;
            color: #fff;
        }
    }
    // li {
    //     border-bottom: 1px solid #0000001f;
    //     padding: 10px;
    //     padding: 13px 18px;
    //     @include bp(mob) {
    //         padding: 10px 14px;
    //     }
    //     button {
    //         display: flex;
    //         height: 39px;
    //         align-items: center;
    //         border: none;
    //         line-height: 1.5;
    //         font-size: 15px;
    //     }
    //     .master-pass {
    //         font-family: Inter;
    //         font-weight: 600;
    //         margin-left: 10px !important;
    //         padding-left: 15px !important;
    //         position: relative;
    //         display: inline-block;
    //         @include bp(mob) {
    //             font-size: 12px;
    //         }
    //         &::before {
    //             border-color: transparent transparent transparent #9cb1bd;
    //             border-style: solid;
    //             border-width: 5px 0 5px 8px;
    //             content: "";
    //             height: 0;
    //             left: 0;
    //             position: absolute;
    //             top: 50%;
    //             transform: translateY(-50%);
    //             width: 0;
    //         }
    //         &.first {
    //             padding-left: 0 !important;
    //             &::before {
    //                 content: unset;
    //             }
    //         }
    //     }
    // }
    .table-bordered> :not(caption)>*>* {
        border-width: 1px !important;
    }
    .single-match-body {
        height: calc(100vh - 290px);
        overflow-y: auto;
    }
}
.match_title_popup {
    font-size: 15px;
    padding: 0 15px;
    @include bp(mob) {
        font-size: 4 vw;
    }
}
.betbtn1 {
    bottom: 9%;
    position: fixed;
    z-index: 9999;
    height: 66px;
    width: 66px;
    img {
        object-fit: contain !important;
        height: 66px;
        width: 100%;
    }
}
.pagenotfound {
    .menu-btn, .header_wrp_r, .header {
        display: none;
    }
    &_container {
        height: 93vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}