.leagues {
    .leage-list {
        background-color: #f0f8ff;
        justify-content: center;
        .main-list {
            width: 100%;
            h6 {
                font-size: 1rem;
                display: block;
                width: 100%;
                font-weight: 400;
                font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
            }
            ul {
                background: #fff;
                border-radius: 12px;
                li {
                    font-size: 15px;
                    padding: 7px 26px 7px 12px;
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBR0KMSkkfpugAAACvElEQVRYw+2UX0iTURiHf+ebqExMszBDmIX0z1BM0hZI6o3OLFNTg0DQiRqBzWEkotvHZmniFJ0SYejUElORFFaWmFLZCByBmNAo0fTCQkmFLKbze7sIb4oucjOk9twezvs+v3PecwAnTpz8n6iP1Z5sbo+LU+fVlRvSlpfV1ro8w1OVCiAiYuxveXCb3chei44y+doaGjCEwx4euIEgzGi1fES9d2tYW1v+I71ML3Nz2+oAdp8UT3pZy6fYWFqkV+Tb1YVaaNDi5YUaWBFhMq29Y0N0LympYu+Vx/KK+fltF2AD1aH6j4b44GAWKAwARiOOYxHpEgkuYAajk5PCDK0IAQkJ1+MLbmcXWSyO6rvpEfqZMku+X1b/+LjtlvDEpVoqRROusptmMzohQXhgINfJLnPnTSbVN72suTgqatsF2KB8n7I9I2hu7mvMahP3LDqazrF+jPf2QoJhvPDxYSp6yD4MDKgNdbOGjowMe/tt+W/BE088cRyF7XLbX6jT4SxVUbBSCR61aCZCEU2wA2lpWl2BOLO5p+dP6zv8Bn7ThkOAMEbVYvEvSwvsvZBrs222sstWKV87WPm2qdLTkxrcd3KnOzoQAg3SExJQjFVkWq0UhEV0yeVlFkWIXNrXt20ClMTUSFtD/P1FsyJfgYxGzEOD9NBQTCGPlS0sMHf4rZ9KTtZaFEvZ/SMj9vZz2AjxpF9pDYmIEO0WfRFyzWZcxF3sCQ3FGVaFlxMTrE1wo8nwcA1TLGUH2i/usAA86QcMJ1JSSEEy4cHwMI4gBx5+flSIKqYbHGTh67O4ExmpYUqWxaanHSVudwCeai+1jCUmUgmtoqO7G95IxXOxmHUDlNrYyO34PDv1Jj7+h/jSkqPFN7DjDXDTwn1XV5SQjQ1ZrTSKHEhLS7VahVieVVMDBsVWSTtx4uQf4jsIRA4ZfaraZAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wNS0yOVQxMDo0OTo0MSswMDowMPHXZGEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMjlUMTA6NDk6NDErMDA6MDCAitzdAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIzLTA1LTI5VDEwOjQ5OjQxKzAwOjAw15/9AgAAAABJRU5ErkJggg==');
                    background-position: 100%;
                    background-repeat: no-repeat;
                    border-bottom: 1px solid #00000026;
                    cursor: pointer;
                    font-family: Ubuntu, sans-serif;
                    font-weight: 400;
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    
                    &:last-child {
                        border: none;
                    }
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .second-level-leage {
        .balance-label, .balance-label-detail {
            background: #000;
            color: #fff;
            padding: 11px 10px;
            font-size: 16px;
            padding-left: 3rem !important;
            text-align: left;
            button {
                left: 0;
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}