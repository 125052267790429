.login_main {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url('./../../assets/login.png');
    background-size: contain;
    .login_main_mobile {
        // background-image: url('./../../assets/login-bg.jfif') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100% !important;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: 480px;
        position: fixed;
        right: 0;
        .login-logo {
            height: 4.285714285714286rem;
            margin: 20px auto -0.5rem;
            max-width: 185px;
            @media screen and (max-width :576px) {
                margin: 20px auto -30px;
            }
        }
        .form_title {
            font-size: 1.7857142857142858rem !important;
            text-align: center;
            font-weight: normal;
            margin-bottom: 1rem !important;
        }
        .login_popup_image {
            width: 100%;
            height: 100%;
            background-image: url('./../../assets/login.png');
            background-color: black;
            background-size: cover;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            position: relative;
            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: unset;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.8);
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
            }
        }
        .login_form_main {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            padding: 1.2rem 8% !important;
            background-color: #fff;
            .close {
                position: absolute;
                right: 10px;
                top: 10px;
                background-position: -394px -1300px;
                height: 20px;
                width: 20px;
                background-image: url("../../../public/images/front-all-icon1.png");
                &.login-close {
                    position: fixed;
                    top: 40px;
                    right: 40px;
                }
            }
            .login_form {
                display: flex;
                flex-direction: column;
                font-family: 'Inter' !important;
                * {
                    font-family: 'Inter' !important;
                }
                .input_group {
                    margin-bottom: 10px;
                    // .MuiInputBase-root {
                    //     * {
                    //         border-color: #c0a024;
                    //         outline-color: #c0a024;
                    //         label {
                    //             color: #c0a024 !important;
                    //         }
                    //     }
                    // }
                    // .MuiInputLabel-root.Mui-focused {
                    //     color: #c0a024 !important;
                    // }
                    input {
                        width: 100%;
                        box-shadow: unset;
                        margin: 0;
                        font-family: 'Inter';
                    }
                }
                .validation {
                    display: flex;
                    align-items: top;
                    position: relative;
                    width: 100%;
                    .input_group {
                        width: 100%;
                    }
                    .validation_code {
                        position: absolute;
                        right: 0;
                        margin: auto;
                        top: 6px;
                        text-align: center;
                        max-width: 70px;
                        min-width: 70px;
                        min-height: 31px;
                        max-height: 31px;
                        font-size: 16px;
                        color: #000 !important;
                    }
                }
                button {
                    line-height: 36px;
                    font-size: 15px;
                    margin: 15px 0 0;
                    width: 100%;
                    color: #f8d61c;
                    border-radius: 4px;
                    background: #121212 url("../../../public/images/bg-send-skyEX.png") repeat-x;
                    border: 1px solid #000;
                    appearance: none;
                    cursor: pointer;
                    font-weight: bold;
                    max-width: 160px;
                    margin: auto;
                    margin-top: 20px;
                    &:hover {
                        background: #464646 url("../../../public/images/bg-send_hover-skyEX.png") repeat-x;
                    }
                    span {
                        background-position: 0 -569px;
                        height: 12px;
                        width: 12px;
                        background-image: url("../../../public/images/login-image.png");
                        background-repeat: no-repeat;
                        display: inline-block;
                        margin-left: 2px;
                    }
                }
            }
        }
        .login_popup_image {
            width: calc(100% - 30px);
            margin: auto;
            max-width: 100%;
            border-radius: 0;
            height: 165px;
            background-position: center;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-color: transparent;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            background-position: bottom;
        }
    }
    .close {
        background: white;
        border-radius: 50%;
        margin-left: 8px;
        margin-top: 8px;
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        svg {
            width: 25px;
            height: 25px;
            padding: 0 !important;
            path {
                stroke-width: 9px;
                stroke: #000;
            }
        }
    }
    &.changepass {
        align-items: center;
        background-image: url('./../../assets/change-login.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width :576px) {
            padding: 0 15px;
        }
        .login_main_mobile {
            background-color: #fff !important;
            height: auto;
            max-width: 480px;
            display: block;
            max-height: 507px;
            &_wrp {
                display: flex;
                min-height: 170px;
                padding: 30px 20px;
                padding-bottom: 40px;
                @media screen and (max-width :576px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            &_left {
                // width: 50%;
                // padding: 48px;
                // padding-left: 15px;
                // padding-right: 33px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                width: calc(100% - 230px - 27px);
                font-size: 12px;
                color: #666;
                line-height: 17px;
                margin: 40px 12px 0 15px;
                @media screen and (max-width :576px) {
                    width: 100%;
                    margin-bottom: 15px;
                    margin-top: 0;
                }
                ul {
                    // padding-left: 32px;
                    li {
                        list-style: disc;
                        font-size: 12px;
                        color: #666;
                        font-family: 'Tahoma';
                        display: list-item;
                        font-weight: 400;
                        line-height: 17px;
                    }
                }
            }
        }
        .login_form_main {
            // width: 50%;
            width: 230px;
            padding: 0;
            @media screen and (max-width :576px) {
                width: 100%;
            }
            .form_title {
                font-size: 20px;
                list-style: 24px;
                font-weight: 500;
                margin-bottom: 16px;
            }
            .input_group {
                input {
                    height: 35px;
                    background: #fff;
                    width: 100%;
                    border-radius: 4px;
                    font-size: 13px;
                    color: #212529;
                    font-weight: 400;
                    padding: 6px 12px;
                    border: 1px solid #ced4da;
                    &::placeholder {
                        font-size: 13px;
                    }
                }
            }
            button {
                margin-top: 5px !important;
                padding: 0 !important;
                border: solid 1px transparent;
                border-color: #222 !important;
                height: 36px !important;
                line-height: 36px !important;
                font-size: 15px !important;
                background-image: linear-gradient(180deg, #474747 0%, #070707 100%) !important;
                &:hover {
                    border-color: #000;
                    background-color: transparent;
                }
            }
        }
        .maintain-footer {
            background-image: linear-gradient(180deg, #383838 0%, #010101 100%);
            position: relative;
            width: 100%;
            height: 70px;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            img {
                width: 132px;
                margin: 0 auto;
            }
        }
    }
    &.mobile_screen {
        .login_footer {
            @media screen and (min-width :577px) {
                background: none !important;
            }
        }
    }
}
.form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}
.form-floating {
    .validation_code {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        // font-size: 20px;
        font-size: 35px;
    }
}
.login-panel .form-floating .form-control {
    border: 1px solid #6f8898;
    border-radius: .5rem;
    font-family: 'Inter';
    height: 2.857142857142857rem;
    min-height: 2.857142857142857rem;
    padding: .5rem .75rem .5rem 42px;
    @include bp(mob) {
        height: 10.67vw;
        font-size: 3.9vw;
    }
}
.form-floating>.form-control:not(:placeholder-shown) {
    border-color: #c0a024;
    border-width: 2px;
}
.form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-left: 2.5rem !important;
    padding-top: 10px !important
}
.login-panel .form-floating label {
    font-family: 'Inter';
    font-size: 13.44px;
    margin-left: 42px;
    opacity: .8;
    padding: 9px 0;
    line-height: 25px;
    @include bp(mob) {
        font-size: 3.9vw;
    }
}
label.form-check-label {
    font-size: 1rem;
    // margin-left: -8px;
    vertical-align: top
}
.login-panel .form-floating svg {
    font-size: 19px;
    margin-left: 12px;
    vertical-align: middle;
    path {
        fill: #9cb1bd;
    }
}
.login-panel .form-floating .form-control:focus {
    border-color: #c0a024;
    box-shadow: none;
    border-width: 2px;
}
.login-panel .form-floating .form-control:focus~svg {
    color: #c0a024;
    opacity: 1;
    path {
        fill: #c0a024;
    }
}
.form-floating>.form-control:not(:placeholder-shown)~svg {
    path {
        fill: #c0a024;
    }
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    background: #fff;
    color: #c0a024 !important;
    font-size: .8571428571428571rem;
    height: auto;
    margin-left: 38px;
    max-width: -webkit-max-content;
    max-width: max-content;
    opacity: 1 !important;
    padding: 0;
    -webkit-transform: translateY(-1.5rem) translateX(.15rem);
    transform: translateY(-1.5rem) translateX(.15rem);
    @include bp(mob) {
        font-size: 3.9vw;
    }
}
button.theme-btn:hover {
    background: transparent;
    border-color: #ffc800;
    color: #000
}
.login-panel .form-floating input[type=password] {
    padding-right: 49px
}
.form-range {
    -webkit-appearance: none;
    appearance: none;
    background-color: initial;
    height: 1.5rem;
    padding: 0;
    width: 100%
}
.form-range:focus {
    outline: 0
}
.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}
.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}
.form-range::-moz-focus-outer {
    border: 0
}
.form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}
@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}
.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}
.form-range::-webkit-slider-runnable-track {
    background-color: #f8f9fa;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}
.form-range::-moz-range-thumb {
    appearance: none;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}
@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}
.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}
.form-range::-moz-range-track {
    background-color: #f8f9fa;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}
.form-range:disabled {
    pointer-events: none
}
.form-range:disabled::-webkit-slider-thumb {
    background-color: rgba(33, 37, 41, .75);
    background-color: rgba(33, 37, 41, .75);
}
.form-range:disabled::-moz-range-thumb {
    background-color: rgba(33, 37, 41, .75);
    background-color: rgba(33, 37, 41, .75);
}
.form-floating {
    position: relative
}
.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    height: calc(3.5rem + 1px *2);
    line-height: 1.25;
    min-height: calc(3.5rem + 2px);
    min-height: calc(3.5rem + 1px *2)
}
.form-floating>label {
    display: inline-block;
    border: 1px solid transparent;
    border: 1px solid transparent;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: 1rem .75rem;
    pointer-events: none;
    position: absolute;
    text-align: start;
    text-overflow: ellipsis;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out, -webkit-transform .1s ease-in-out;
    white-space: nowrap;
    z-index: 2
}
@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}
.form-floating>.form-control, .form-floating>.form-control-plaintext {
    padding: 1rem .75rem
}
.form-floating>.form-control-plaintext::-webkit-input-placeholder, .form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}
.form-floating>.form-control-plaintext::placeholder, .form-floating>.form-control::placeholder {
    color: transparent
}
.form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.form-floating>.form-control-plaintext:-webkit-autofill, .form-floating>.form-control:-webkit-autofill {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.form-floating>.form-select {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: rgba(33, 37, 41, .65);
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.78rem) translateX(.15rem);
    @include bp(mob) {
        font-size: 3.4vw;
        line-height: 3.4vw;
    }
}
.form-floating>.form-control-plaintext~label:after, .form-floating>.form-control:focus~label:after, .form-floating>.form-control:not(:placeholder-shown)~label:after, .form-floating>.form-select~label:after {
    background-color: #fff;
    border-radius: .375rem;
    content: "";
    height: 1.5em;
    inset: 1rem .375rem;
    position: absolute;
    z-index: -1
}
.form-floating>.form-control:-webkit-autofill~label {
    color: rgba(33, 37, 41, .65);
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    @include bp(mob) {
        -webkit-transform: scale(.85) translateY(-1.5vw) translateX(.15rem);
        transform: scale(.85) translateY(-1.5vw) translateX(.15rem)
    }
}
.form-floating>.form-control-plaintext~label {
    border-width: 1px 0;
    border-width: 1px 0
}
.form-floating>.form-control:disabled~label, .form-floating>:disabled~label {
    color: #6c757d
}
.form-floating>.form-control:disabled~label:after, .form-floating>:disabled~label:after {
    background-color: #e9ecef;
}
.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}
.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%
}
.input-group>.form-control:focus, .input-group>.form-floating:focus-within, .input-group>.form-select:focus {
    z-index: 5
}
.input-group .btn {
    position: relative;
    z-index: 2
}
.input-group .btn:focus {
    z-index: 5
}
.input-group-text {
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: .375rem;
    color: #212529;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    white-space: nowrap
}
.input-group-lg>.btn, .input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text {
    border-radius: .5rem;
    font-size: 1.25rem;
    padding: .5rem 1rem
}
.input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
    border-radius: .25rem;
    font-size: .875rem;
    padding: .25rem .5rem
}
.input-group-lg>.form-select, .input-group-sm>.form-select {
    padding-right: 3rem
}
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control, .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select, .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
    margin-left: calc(1px *-1)
}
.input-group>.form-floating:not(:first-child)>.form-control, .input-group>.form-floating:not(:first-child)>.form-select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}
.valid-feedback {
    color: #198754;
    display: none;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%
}
.valid-tooltip {
    background-color: #198754;
    border-radius: .375rem;
    color: #fff;
    display: none;
    font-size: .875rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}
.is-valid~.valid-feedback, .is-valid~.valid-tooltip, .was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip {
    display: block
}
.form-control.is-valid, .was-validated .form-control:valid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    border-color: #198754;
    padding-right: calc(1.5em + .75rem)
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
    padding-right: calc(1.5em + .75rem)
}
.form-select.is-valid, .was-validated .form-select:valid {
    border-color: #198754;
}
.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
    padding-right: 4.125rem
}
.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}
.form-control-color.is-valid, .was-validated .form-control-color:valid {
    width: calc(3.75rem + 1.5em)
}
.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #198754;
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: #198754;
}
.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: #198754;
}
.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}
.input-group>.form-control:not(:focus).is-valid, .input-group>.form-floating:not(:focus-within).is-valid, .input-group>.form-select:not(:focus).is-valid, .was-validated .input-group>.form-control:not(:focus):valid, .was-validated .input-group>.form-floating:not(:focus-within):valid, .was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}
.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%
}
.invalid-tooltip {
    background-color: #dc3545;
    border-radius: .375rem;
    color: #fff;
    display: none;
    font-size: .875rem;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}
.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem)
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
    padding-right: calc(1.5em + .75rem)
}
.form-select.is-invalid, .was-validated .form-select:invalid {
    border-color: #dc3545;
}
.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
    padding-right: 4.125rem
}
.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}
.form-control-color.is-invalid, .was-validated .form-control-color:invalid {
    width: calc(3.75rem + 1.5em)
}
.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
    border-color: #dc3545;
}
.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
    background-color: #dc3545;
}
.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545;
}
.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}
.input-group>.form-control:not(:focus).is-invalid, .input-group>.form-floating:not(:focus-within).is-invalid, .input-group>.form-select:not(:focus).is-invalid, .was-validated .input-group>.form-control:not(:focus):invalid, .was-validated .input-group>.form-floating:not(:focus-within):invalid, .was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}
.login_btn {
    line-height: 36px;
    font-size: 15px;
    margin: 15px 0 0;
    width: 100%;
    color: #f8d61c;
    border-radius: 4px;
    // background: #121212 url("../../../public/images/bg-send-skyEX.png") repeat-x;
    background: #ffc800;
    color: #000 !important;
    border: 1px solid #ffc800;
    appearance: none;
    cursor: pointer;
    font-weight: bold;
    max-width: 160px;
    margin: auto;
    margin-top: 20px;
    &:hover {
        // background: #464646 url("../../../public/images/bg-send_hover-skyEX.png") repeat-x;
        background: transparent;
        border-color: #ffc800;
        color: #000;
    }
}
.login-panel input[type=checkbox] {
    border: 1px solid #c0a024;
    height: 13px !important;
    width: 13px !important;
    border-radius: 4.5px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
}
.login-panel input[type=checkbox]:checked {
    border-color: #c0a024;
    color: #000;
}
.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked {
    background-color: #0d6efd !important;
}
.eye-img {
    svg {
        width: 23px;
        height: 23px;
        path {
            fill: rgb(34, 34, 34) !important;
        }
    }
}



// color change for login input
.green {
    .form-floating>.form-control:not(:placeholder-shown) {
        border-color: #1b710d;
    }
    .form-floating .form-control:focus {
        border-color: #1b710d;
    }
    .form-floating .form-control:focus~svg {
        color: #1b710d;
        path {
            fill: #1b710d;
        }
    }
    .form-floating>.form-control:not(:placeholder-shown)~svg {
        path {
            fill: #1b710d;
        }
    }
    .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
        color: #1b710d !important;
    }
    input[type=checkbox] {
        border: 1px solid #1b710d;
    }
    input[type=checkbox]:checked {
        border-color: #1b710d;
    }
}