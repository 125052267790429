// Pre-Loader
.sd_loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include square(100%);
    @include flex;
    background: rgba(0, 0, 0, 0.15);
    z-index: 998;
    svg {
        margin: auto;
        position: relative;
        z-index: 999;
    }
}
// .main-loader {
//     background-color: #fff;
//     border-radius: 8px;
//     box-shadow: 0 5px 10px #00000080;
//     height: 150px;
//     left: 45%;
//     margin-left: -95px;
//     padding-top: 5px;
//     position: relative !important;
//     top: 50%;
//     width: 230px;
//     z-index: 50;
//     .loading {
//         li {
//             text-align: center;
//         }
//         img {
//             height: 100px;
//             width: 100px;
//             margin: 0 auto;
//         }
//     }
// }
// .loader_top {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 9999;
// }
.loading, .loading-box {
    align-items: center;
    justify-content: center;
}
.loading {
    background-color: #262c32b3;
    display: flex;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999999
}
.loading-box {
    background: rgba(27, 31, 35, .7);
    border: 1px solid #8b6b05;
    border-radius: .5rem;
    display: flex;
    height: 7rem;
    width: 7rem
}
.loading-spinner {
    height: 1.4285714286rem;
    position: relative;
    width: 50%
}
.loading-spinner:after, .loading-spinner:before {
    background-color: #ffc800;
    content: "";
    height: 1.4285714286rem;
    position: absolute;
    width: 1.4285714286rem
}
.loading-spinner:before {
    animation: spinnerLeftRight .6s 95ms infinite;
    border-radius: 50%;
    left: -10%;
    opacity: 1
}
.loading-spinner:after {
    animation: spinnerLeftRight .6s infinite;
    border-radius: 100%;
    left: -15%;
    opacity: .4
}
@keyframes spinnerLeftRight {
    0% {
        left: 75%
    }
    50% {
        left: -15%
    }
    100% {
        left: 75%
    }
}