.mb_0 {
  margin-bottom: 0 !important;
}
.mb_5 {
  margin-bottom: 5px !important;
}
.mb_10 {
  margin-bottom: 10px !important;
}
.mb_15 {
  margin-bottom: 15px !important;
}
.mr_10 {
  margin-right: 10px !important;
}
.mr_15 {
  margin-right: 15px !important;
}
.mb_25 {
  margin-bottom: 25px !important;
}
.mt_10 {
  margin-top: 10px !important;
}
.mt_15 {
  margin-top: 15px !important;
}
.mt_25 {
  margin-top: 25px !important;
}
.m_0 {
  margin: 0 !important;
}
.mr_5 {
  margin-right: 5px !important;
}
.ml_5 {
  margin-left: 5px !important;
}
.mr_10 {
  margin-right: 10px !important;
}
.ml_10 {
  margin-left: 10px !important;
}
.ml_15 {
  margin-left: 15px !important;
}
.ml_25 {
  margin-left: 25px !important;
}
.mr_25 {
  margin-right: 25px !important;
}
.ml_0 {
  margin-left: 0 !important;
}
.mt_0 {
  margin-top: 0 !important;
}
.mt_5 {
  margin-top: 5px !important;
}
.mb_5 {
  margin-bottom: 5px !important;
}
.pl_15 {
  padding-left: 15px !important;
}
.pr_20 {
  padding-right: 20px !important;
}
.pb_0 {
  padding-bottom: 0 !important;
}
.pb_10 {
  padding-bottom: 10px !important;
}
.pl_25 {
  padding-left: 25px !important;
}
.pb_15 {
  padding-bottom: 15px !important;
}
.pb_25 {
  padding-bottom: 25px !important;
}
.p_0 {
  padding: 0 !important;
}
.pt_10 {
  padding-top: 10px !important;
}
.pt_15 {
  padding-top: 15px !important;
}
.pt_25 {
  padding-top: 25px !important;
}
.pl_0 {
  padding-left: 0 !important;
}
.pr_0 {
  padding-right: 0 !important;
}
.p_5 {
  padding: 5px !important;
}
.p_15 {
  padding: 15px !important;
}
.text_white {
  color: $white;
}
.color_blue {
  color: $blueDark;
}
.color_red {
  color: $error2 !important;
}
.bg_white {
  background: $white !important;
}
.cms_bg {
  background: $gainsboro !important;
}
.page_bg {
  background: $tabbg;
}
.overflow_initial {
  overflow: initial !important;
}
.w_100 {
  width: 100% !important;
}
.w_80 {
  width: 80% !important;
}
.w_400 {
  width: 400px;
}
.w_325 {
  width: 325px;
}
.h_100 {
  height: 100%;
}
.mx_100 {
  max-width: 100% !important;
}
.tbl_layout_fix {
  table-layout: fixed;
}
.zindex2 {
  z-index: 2;
}
.zindex3 {
  z-index: 3;
}
.no_bg_dropdown .select_field {
  background: none !important;
}
.no_bg {
  background: none !important;
}
.error {
  color: red;
  font-size: 13px;
}
.table_layout_fix {
  table-layout: fixed;
}
.text_center {
  text-align: center !important;
}
.text_reset {
  font-weight: normal !important;
}
.text_right {
  text-align: right;
}
.text_left {
  text-align: left !important;
}
.uppercase {
  text-transform: uppercase;
}
.pointer {
  cursor: pointer;
}
.pointer_auto {
  cursor: auto !important;
}
.capitalize {
  text-transform: capitalize;
}
.relative {
  position: relative;
}
.fix_table_layout {
  table-layout: fixed;
}
.disp_flex {
  display: flex;
}
.d_block {
  display: block;
}
.d_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.d_flex_align_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.d_flex_align_baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.d_flex_align_start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.d_flex_justify_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.d_flex_justify_spacebt {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.d_flex_justify_unset {
  justify-content: unset !important;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
}
.d_flex_justify_end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.d_just_normal {
  -webkit-box-pack: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
}
.d_align_start {
  align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.no_border {
  border: none !important;
}
.col {
  &-2 {
    flex: 0 0 auto;
    padding: 0 10px;
    width: 16.66666667%;
  }
  &-3 {
    flex: 0 0 auto;
    width: 25%;
    padding: 0 10px;
  }
  &-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding: 0 10px;
  }
  &-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
    padding: 0 10px;
  }
  &-6 {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 10px;
  }
  &-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  &-8 {
    flex: 0 0 auto;
    width: 66.33333333%;
  }
  &-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  &-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  &-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  &-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.d_flex_fix {
  display: flex !important;
  width: fit-content;
}
.d-inline {
  display: inline !important
}
.d-inline-block {
  display: inline-block !important
}
.d-block {
  display: block !important
}
.d-grid {
  display: grid !important
}
.d-inline-grid {
  display: inline-grid !important
}
.d-table {
  display: table !important
}
.d-table-row {
  display: table-row !important
}
.d-table-cell {
  display: table-cell !important
}
.d-flex {
  display: flex !important
}
.d-inline-flex {
  display: inline-flex !important
}
.d-none {
  display: none !important
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}
.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}
.shadow-none {
  box-shadow: none !important
}
.m-0 {
  margin: 0 !important
}
.m-1 {
  margin: .25rem !important
}
.m-2 {
  margin: .5rem !important
}
.m-3 {
  margin: 1rem !important
}
.m-4 {
  margin: 1.5rem !important
}
.m-5 {
  margin: 3rem !important
}
.m-auto {
  margin: auto !important
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}
.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important
}
.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important
}
.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important
}
.my-1 {
  margin-bottom: .25rem !important;
  margin-top: .25rem !important
}
.my-2 {
  margin-bottom: .5rem !important;
  margin-top: .5rem !important
}
.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important
}
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important
}
.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important
}
.mt-0 {
  margin-top: 0 !important
}
.mt-1 {
  margin-top: .25rem !important
}
.mt-2 {
  margin-top: .5rem !important
}
.mt-3 {
  margin-top: 1rem !important
}
.mt-4 {
  margin-top: 1.5rem !important
}
.mt-5 {
  margin-top: 3rem !important
}
.mt-auto {
  margin-top: auto !important
}
.me-0 {
  margin-right: 0 !important
}
.me-1 {
  margin-right: .25rem !important
}
.me-2 {
  margin-right: .5rem !important
}
.me-3 {
  margin-right: 1rem !important
}
.me-4 {
  margin-right: 1.5rem !important
}
.me-5 {
  margin-right: 3rem !important
}
.me-auto {
  margin-right: auto !important
}
.mb-0 {
  margin-bottom: 0 !important
}
.mb-1 {
  margin-bottom: .25rem !important
}
.mb-2 {
  margin-bottom: .5rem !important
}
.mb-3 {
  margin-bottom: 1rem !important
}
.mb-4 {
  margin-bottom: 1.5rem !important
}
.mb-5 {
  margin-bottom: 3rem !important
}
.mb-auto {
  margin-bottom: auto !important
}
.ms-0 {
  margin-left: 0 !important
}
.ms-1 {
  margin-left: .25rem !important
}
.ms-2 {
  margin-left: .5rem !important
}
.ms-3 {
  margin-left: 1rem !important
}
.ms-4 {
  margin-left: 1.5rem !important
}
.ms-5 {
  margin-left: 3rem !important
}
.ms-auto {
  margin-left: auto !important
}
.p-0 {
  padding: 0 !important
}
.p-1 {
  padding: .25rem !important
}
.p-2 {
  padding: .5rem !important
}
.p-3 {
  padding: 1rem !important
}
.p-4 {
  padding: 1.5rem !important
}
.p-5 {
  padding: 3rem !important
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}
.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important
}
.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important
}
.py-1 {
  padding-bottom: .25rem !important;
  padding-top: .25rem !important
}
.py-2 {
  padding-bottom: .5rem !important;
  padding-top: .5rem !important
}
.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important
}
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important
}
.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important
}
.pt-0 {
  padding-top: 0 !important
}
.pt-1 {
  padding-top: .25rem !important
}
.pt-2 {
  padding-top: .5rem !important
}
.pt-3 {
  padding-top: 1rem !important
}
.pt-4 {
  padding-top: 1.5rem !important
}
.pt-5 {
  padding-top: 3rem !important
}
.pe-0 {
  padding-right: 0 !important
}
.pe-1 {
  padding-right: .25rem !important
}
.pe-2 {
  padding-right: .5rem !important
}
.pe-3 {
  padding-right: 1rem !important
}
.pe-4 {
  padding-right: 1.5rem !important
}
.pe-5 {
  padding-right: 3rem !important
}
.pb-0 {
  padding-bottom: 0 !important
}
.pb-1 {
  padding-bottom: .25rem !important
}
.pb-2 {
  padding-bottom: .5rem !important
}
.pb-3 {
  padding-bottom: 1rem !important
}
.pb-4 {
  padding-bottom: 1.5rem !important
}
.pb-5 {
  padding-bottom: 3rem !important
}
.ps-0 {
  padding-left: 0 !important
}
.ps-1 {
  padding-left: .25rem !important
}
.ps-2 {
  padding-left: .5rem !important
}
.ps-3 {
  padding-left: 1rem !important
}
.ps-4 {
  padding-left: 1.5rem !important
}
.ps-5 {
  padding-left: 3rem !important
}
.gap-0 {
  gap: 0 !important
}
.gap-1 {
  gap: .25rem !important
}
.gap-2 {
  gap: .5rem !important
}
.gap-3 {
  gap: 1rem !important
}
.gap-4 {
  gap: 1.5rem !important
}
.gap-5 {
  gap: 3rem !important
}
.row-gap-0 {
  row-gap: 0 !important
}
.row-gap-1 {
  row-gap: .25rem !important
}
.row-gap-2 {
  row-gap: .5rem !important
}
.row-gap-3 {
  row-gap: 1rem !important
}
.row-gap-4 {
  row-gap: 1.5rem !important
}
.row-gap-5 {
  row-gap: 3rem !important
}
.column-gap-0 {
  -webkit-column-gap: 0 !important;
  column-gap: 0 !important
}
.column-gap-1 {
  -webkit-column-gap: .25rem !important;
  column-gap: .25rem !important
}
.column-gap-2 {
  -webkit-column-gap: .5rem !important;
  column-gap: .5rem !important
}
.column-gap-3 {
  -webkit-column-gap: 1rem !important;
  column-gap: 1rem !important
}
.column-gap-4 {
  -webkit-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important
}
.column-gap-5 {
  -webkit-column-gap: 3rem !important;
  column-gap: 3rem !important
}
.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important
}
.fs-2 {
  font-size: calc(1.325rem + .9vw) !important
}
.fs-3 {
  font-size: calc(1.3rem + .6vw) !important
}
.fs-4 {
  font-size: calc(1.275rem + .3vw) !important
}
.fs-5 {
  font-size: 1.25rem !important
}
.fs-6 {
  font-size: 1rem !important
}
.fst-italic {
  font-style: italic !important
}
.fst-normal {
  font-style: normal !important
}
.fw-lighter {
  font-weight: lighter !important
}
.fw-light {
  font-weight: 300 !important
}
.fw-normal {
  font-weight: 400 !important
}
.fw-medium {
  font-weight: 500 !important
}
.fw-semibold {
  font-weight: 600 !important
}
.fw-bold {
  font-weight: 700 !important
}
.fw-bolder {
  font-weight: bolder !important
}
.lh-1 {
  line-height: 1 !important
}
.lh-sm {
  line-height: 1.25 !important
}
.lh-base {
  line-height: 1.5 !important
}
.lh-lg {
  line-height: 2 !important
}
.text-start {
  text-align: left !important
}
.text-end {
  text-align: right !important
}
.text-center {
  text-align: center !important
}
.text-decoration-none {
  text-decoration: none !important
}
.text-decoration-underline {
  text-decoration: underline !important
}
.text-decoration-line-through {
  text-decoration: line-through !important
}
.text-lowercase {
  text-transform: lowercase !important
}
.text-uppercase {
  text-transform: uppercase !important
}
.text-capitalize {
  text-transform: capitalize !important
}
.text-wrap {
  white-space: normal !important
}
.text-nowrap {
  white-space: nowrap !important
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: initial !important
}
.border-0 {
  border: 0 !important
}
.border-1 {
  border-width: 1px !important
}
.border-2 {
  border-width: 2px !important
}
.border-3 {
  border-width: 3px !important
}
.border-4 {
  border-width: 4px !important
}
.border-5 {
  border-width: 5px !important
}
.bg-yellow {
  // background-color: #ffc800 !important;
  background-color: var(--bgColor) !important;
  border: none !important;
  // color: #000 !important;
  color: var(--colorSecond) !important;
}
.position-static {
  position: static !important
}
.position-relative {
  position: relative !important
}
.position-absolute {
  position: absolute !important
}
.position-fixed {
  position: fixed !important
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}
.top-0 {
  top: 0 !important
}
.top-50 {
  top: 50% !important
}
.top-100 {
  top: 100% !important
}
.bottom-0 {
  bottom: 0 !important
}
.bottom-50 {
  bottom: 50% !important
}
.bottom-100 {
  bottom: 100% !important
}
.start-0 {
  left: 0 !important
}
.start-50 {
  left: 50% !important
}
.start-100 {
  left: 100% !important
}
.end-0 {
  right: 0 !important
}
.end-50 {
  right: 50% !important
}
.end-100 {
  right: 100% !important
}
.rounded {
  border-radius: 0.375rem !important;
}
.bg-skylight {
  background-color: #f0f8ff !important;
}
.flex-fill {
  flex: 1 1 auto !important
}
.flex-row {
  flex-direction: row !important
}
.flex-column {
  flex-direction: column !important
}
.flex-row-reverse {
  flex-direction: row-reverse !important
}
.flex-column-reverse {
  flex-direction: column-reverse !important
}
.flex-grow-0 {
  flex-grow: 0 !important
}
.flex-grow-1 {
  flex-grow: 1 !important
}
.flex-shrink-0 {
  flex-shrink: 0 !important
}
.flex-shrink-1 {
  flex-shrink: 1 !important
}
.flex-wrap {
  flex-wrap: wrap !important
}
.flex-nowrap {
  flex-wrap: nowrap !important
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}
.justify-content-start {
  justify-content: flex-start !important
}
.justify-content-end {
  justify-content: flex-end !important
}
.justify-content-center {
  justify-content: center !important
}
.justify-content-between {
  justify-content: space-between !important
}
.justify-content-around {
  justify-content: space-around !important
}
.justify-content-evenly {
  justify-content: space-evenly !important
}
.align-items-start {
  align-items: flex-start !important
}
.align-items-end {
  align-items: flex-end !important
}
.align-items-center {
  align-items: center !important
}
.align-items-baseline {
  align-items: baseline !important
}
.align-items-stretch {
  align-items: stretch !important
}
.align-content-start {
  align-content: flex-start !important
}
.align-content-end {
  align-content: flex-end !important
}
.align-content-center {
  align-content: center !important
}
.align-content-between {
  align-content: space-between !important
}
.align-content-around {
  align-content: space-around !important
}
.align-content-stretch {
  align-content: stretch !important
}
.align-self-auto {
  align-self: auto !important
}
.align-self-start {
  align-self: flex-start !important
}
.align-self-end {
  align-self: flex-end !important
}
.align-self-center {
  align-self: center !important
}
.align-self-baseline {
  align-self: baseline !important
}
.align-self-stretch {
  align-self: stretch !important
}
.w-25 {
  width: 25% !important
}
.w-50 {
  width: 50% !important
}
.w-75 {
  width: 75% !important
}
.w-100 {
  width: 100% !important
}
.w-auto {
  width: auto !important
}
.mw-100 {
  max-width: 100% !important
}
.vw-100 {
  width: 100vw !important
}
.min-vw-100 {
  min-width: 100vw !important
}
.h-25 {
  height: 25% !important
}
.h-50 {
  height: 50% !important
}
.h-75 {
  height: 75% !important
}
.h-100 {
  height: 100% !important
}
.h-auto {
  height: auto !important
}
.mh-100 {
  max-height: 100% !important
}
.vh-100 {
  height: 100vh !important
}
.min-vh-100 {
  min-height: 100vh !important
}
.text-yellow {
  // color: #ffc800
  color: var(--bgColor);
}
.g-0, .gx-0 {
  --bs-gutter-x: 0
}
.g-0, .gy-0 {
  --bs-gutter-y: 0
}
.g-1, .gx-1 {
  --bs-gutter-x: 0.25rem
}
.g-1, .gy-1 {
  --bs-gutter-y: 0.25rem
}
.g-2, .gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2, .gy-2 {
  --bs-gutter-y: 0.5rem
}
.g-3, .gx-3 {
  --bs-gutter-x: 1rem
}
.g-3, .gy-3 {
  --bs-gutter-y: 1rem
}
.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem
}
.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem
}
.g-5, .gx-5 {
  --bs-gutter-x: 3rem
}
.g-5, .gy-5 {
  --bs-gutter-y: 3rem
}
.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important
}
.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important
}
.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important
}
.overflow-scroll {
  overflow: scroll !important
}
.overflow-x-auto {
  overflow-x: auto !important
}
.overflow-x-hidden {
  overflow-x: hidden !important
}
.overflow-x-visible {
  overflow-x: visible !important
}
.overflow-x-scroll {
  overflow-x: scroll !important
}
.overflow-y-auto {
  overflow-y: auto !important
}
.overflow-y-hidden {
  overflow-y: hidden !important
}
.overflow-y-visible {
  overflow-y: visible !important
}
.overflow-y-scroll {
  overflow-y: scroll !important
}
.font-bold {
  font-weight: 700
}
.font-extrabold {
  font-weight: 800
}
.font-normal {
  font-weight: 400
}
.font-semibold {
  font-weight: 600
}
.icon-triangle-black-600 {
  border-color: transparent transparent transparent #5a5e62;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  height: 0;
  margin: 0 .2857142857rem;
  width: 0
}
.d-flex-1 {
  flex: 1 1 0%
}
.border-t {
  border-top: 1px solid
}
.border-b {
  border-bottom: 1px solid
}
.border-l {
  border-left: 1px solid
}
.border-r {
  border-right: 1px solid
}
.border-r-white, .border-t-white {
  border-right-color: rgb(255 255 255/1)
}
.bg-back-100 {
  background-color: #d0e8fa;
}
.bg-lay-100 {
  background-color: rgb(253 226 232/1)
}
.border-white {
  border-color: #fff;
}
.border-black-300 {
  border-color: #d4e0e5;
}
.overflow-x-scroll {
  overflow-x: scroll
}
.border-t-black-300 {
  border-color: #d4e0e5
}
.svgFroFancy{
  vertical-align: middle;
    margin-right: 5px;
}