.header {
    height: 40px;
    @include bp(mob) {
        max-width: 100%;
        height: 10.6vw;
    }
    &_wrp {
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        // background: linear-gradient(180deg, #ffc800, #ffc800);
        background: linear-gradient(180deg, var(--bgColor), var(--bgColor));
        max-width: 480px;
        @include bp(mob) {
            max-width: 100%;
            height: 10.6vw;
        }
        &_l {
            display: flex;
            align-items: center;
            &_logo {
                max-width: 100px;
                width: 100%;
                margin-left: 10px;
                img {
                    height: 100%;
                    width: 100%;
                    height: 32px;
                }
            }
        }
        &_r {
            .login-wrap {
                line-height: 1.1;
                clear: both;
                background-color: transparent;
                align-items: center;
                .mobile_login {
                    display: flex;
                }
                li {
                    margin: 0 6px 0 0;
                    &.user {
                        padding-left: 22px;
                        background-image: url("../../../public/images/front-all-icon.png");
                        background-position: -96px -11314px;
                    }
                    &.valid-code {
                        position: relative;
                        img, h3 {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            width: 55px;
                            height: 15px;
                            text-align: right;
                        }
                    }
                    .btn-login, .btn-signup, .btn-signup-new {
                        width: 80px;
                        height: 27px;
                        line-height: 27px;
                        color: #fff;
                        font-weight: bold;
                        text-decoration: none;
                        border-radius: 4px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            margin-left: 5px;
                        }
                    }
                    .btn-login {
                        background-color: #e83523;
                        background-image: linear-gradient(-180deg, #f72424 0%, #bb1c00 100%);
                        width: 84px;
                        height: 30px;
                        font-size: 13.0933px;
                        // border: 0.85px solid #000 !important;
                        img,
                        svg {
                            width: 14px;
                            margin-right: 5px;
                        }
                        @include bp(mob) {
                            font-size: 14.0933px;
                            font-weight: 900;
                            padding: 2.8vw;
                            width: 19.887vw;
                            height: 6.931vw;
                            font-size: 3.467vw;
                            border-radius: 0.933vw;
                            img {
                                width: 3.467vw;
                                font-size: 3.467vw;
                            }
                        }
                    }
                    .btn-signup {
                        border-radius: 4px;
                        border: 1px solid #635f5f !important;
                        width: 76px;
                        background: #121212 url("../../../public/images/bg-send-skyEX.png") repeat-x;
                        font-size: 13.0933px;
                        width: 84px;
                        height: 30px;
                        border: 0.85px solid #000 !important;
                        box-shadow: inset 0 0.85px 0 0 #ffffff80;
                        line-height: 8.53333vw;
                        background: unset;
                        background-image: linear-gradient(-180deg, #666, #333);
                    }
                    .btn-signup-new {
                        // border-radius: 4px;
                        // border: 1px solid #635f5f !important;
                        // width: 76px;
                        // background: #121212 url("../../../public/images/bg-send-skyEX.png") repeat-x;
                        font-size: 13.0933px;
                        width: 84px;
                        height: 30px;
                        border: 0.85px solid #000 !important;
                        box-shadow: inset 0 0.85px 0 0 #ffffff80;
                        line-height: 8.53333vw;
                        background: unset;
                        background-image: linear-gradient(-180deg, #666, #333);
                        background: unset;
                        background-image: unset;
                        background-color: #8b6b05;
                        border: none !important;
                        box-shadow: unset;
                        img,
                        svg {
                            width: 14px;
                            margin-right: 5px;
                        }
                        @include bp(mob) {
                            font-size: 14.0933px;
                            font-weight: 900;
                            padding: 2.8vw;
                            width: auto;
                            height: 6.931vw;
                            font-size: 3.467vw;
                            border-radius: 0.933vw;
                            img {
                                width: 3.467vw;
                                font-size: 3.467vw;
                            }
                        }
                    }
                }
                input, textarea {
                    width: 130px;
                    height: 25px;
                    margin: 0;
                }
            }
            span#errorMsg {
                display: block;
                width: 100%;
                line-height: normal;
                color: #f8d61c;
                padding-left: 22px;
                margin: 0 5px 2px 0;
            }
            .account-wrap {
                display: flex;
                align-items: center;
                @include bp(tab) {
                    align-items: unset;
                }
                ul {
                    z-index: 99;
                    li {
                        list-style: none;
                        position: relative;
                        text-indent: 0;
                        margin-left: 5px;
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    color: #1e1e1e;
                    &.a-setting.ui-link {
                        display: none;
                        border-color: #000;
                        color: $menucolor1;
                        background-color: rgba(51, 51, 51, 0.4);
                        box-shadow: inset 0 1px 0 0 #ffffff66;
                        font-weight: bold;
                        border-radius: 1.06667vw;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        background-position: center;
                        width: 36px;
                        height: 36px;
                        // background: url("../../../public/images/setting_yellow.svg") no-repeat;
                        background-position: center;
                        margin-left: 10px;
                        @include bp(mini) {
                            display: flex;
                            width: 34px;
                            height: 34px;
                            background-color: rgba(255, 255, 255, 0.1490196078);
                            border: 1px solid #000;
                        }
                        @include bp(tab) {
                            border: 0.26667vw solid #000;
                            border-radius: 1.06667vw;
                            box-shadow: inset 0 0.26667vw 0 0 hsla(0, 0%, 100%, .3);
                            color: #1e1e1e;
                            font-weight: 700;
                            height: 5.86667vw;
                            line-height: 5.33333vw;
                            height: inherit;
                            width: 9.3vw;
                            display: block;
                            display: flex;
                            height: 100%;
                            img {
                                height: 5.06667vw;
                                width: 5.06667vw;
                                object-fit: contain;
                            }
                        }
                    }
                }
                .main-wallet {
                    border-color: #000000cc;
                    flex: 1;
                    width: auto;
                    border: 1px solid #0000004d;
                    border-radius: 4px;
                    display: flex;
                    position: relative;
                    margin-left: 15px;
                    margin-right: 10px;
                    float: left;
                    border: none;
                    @include bp(mini) {
                        border: none;
                        border-radius: 1.06667vw;
                        flex: 1 1;
                        font-size: 2.66667vw;
                    }
                    ul {
                        li {
                            line-height: 24px;
                            margin-left: 15px;
                        }
                    }
                    .a-wallet {
                        height: auto;
                        line-height: initial;
                        border: 0;
                        border-radius: 4px 0 0 4px;
                        font-size: 12px;
                        position: relative;
                        &:hover {
                            text-decoration: none;
                        }
                        ul {
                            background-color: initial !important;
                            flex-wrap: wrap;
                            flex-direction: column;
                            gap: 0;
                            line-height: normal;
                            display: flex;
                            &:not(:target) {
                                display: flex;
                            }
                            li {
                                font-weight: normal;
                                margin-right: 7px;
                                margin-left: 0;
                                line-height: 15px;
                            }
                        }
                        .nums {
                            border: 1px solid $menucolor1;
                            font-size: 11px;
                            line-height: 14px;
                            border-radius: 3px;
                            padding: 0 8px;
                            margin-right: 4px;
                            span {
                                opacity: 1;
                                font-weight: bold;
                                margin-left: 1px;
                            }
                            @include bp(tab) {
                                border: 0.26667vw solid rgba(0, 0, 0, .3);
                                border-radius: 0.8vw;
                                font-size: 2.93333vw;
                                height: 3.73333vw;
                                line-height: 3.73333vw;
                                padding: 0 2.13333vw;
                                position: absolute;
                                right: 1.86667vw;
                                top: 2.4vw;
                            }
                        }
                    }
                    span:first-of-type {
                        opacity: 0.7;
                        font-weight: normal;
                    }
                }
                .wallet-detail {
                    display: none;
                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100%;
                    font-size: 12px;
                    color: #3b5160;
                    background-color: #e0e6e6;
                    box-shadow: 0 3px 10px 0 #00000080;
                    border-radius: 4px;
                    z-index: 99;
                    &:not(:target) {
                        display: none;
                    }
                    &:target {
                        display: block;
                    }
                    &-group {
                        margin: 12px 7px;
                        background-color: #fff;
                        border-radius: 3px;
                        box-shadow: 0 1px 1px 0 #0000004d;
                        .wallet-detail-content {
                            padding: 10px 7px 0;
                            dt {
                                line-height: 1.6;
                                margin-bottom: 3px;
                            }
                            dd {
                                padding-bottom: 10px;
                            }
                            .wallet-balance-num {
                                display: flex;
                                align-items: center;
                                font-size: 17px;
                                line-height: 1.2;
                                font-weight: bold;
                                padding-right: -20px;
                                color: #3b5160;
                                span:first-of-type {
                                    opacity: 0.7;
                                }
                                .badge-currency {
                                    margin-right: 3px;
                                    padding: 0 5px;
                                    line-height: 16px;
                                    font-size: 10px;
                                    font-weight: bold !important;
                                    color: #fff;
                                    background-color: #5f849d;
                                    border-radius: 3px;
                                    justify-content: center;
                                    align-items: center;
                                    display: flex;
                                }
                            }
                            .wallet-exposure {
                                display: flex;
                                justify-content: space-between;
                                font-weight: normal !important;
                                padding: 7px 0;
                                border-top: 1px solid #e0e6e6;
                            }
                        }
                        &:nth-child(2) {
                            .wallet-detail-content {
                                position: relative;
                                border-bottom: 1px solid #ccc;
                                .wallet-recall {
                                    position: absolute;
                                    top: 50%;
                                    right: 7px;
                                    transform: translateY(-50%);
                                    text-align: right;
                                    padding: 0;
                                }
                            }
                        }
                    }
                    .btn-box {
                        padding: 0 7px 10px;
                        .btn {
                            width: 100%;
                            font-size: 13px;
                            font-weight: 900;
                            margin-bottom: 0;
                            background: #eee url("../../../public/images/bg-btn.gif") repeat-x;
                            border: 1px solid #bbb;
                            border-radius: 4px;
                            color: #1e1e1e;
                            margin: 0 5px 0 0;
                            &:hover {
                                text-decoration: none;
                                background: #fff url("../../../public/images/bg-btn_hover.gif") repeat-x;
                            }
                        }
                    }
                }
                .a-refresh {
                    width: 24px;
                    height: 24px;
                    border-left: 1px solid rgb(0, 0, 0);
                    border-radius: 0 4px 4px 0;
                    box-shadow: inset 0 1px 0 0 #ffffff80;
                    background: url("../../../public/images/refresh.svg") no-repeat;
                    background-size: 12px;
                    background-position: center;
                    @include bp(mini) {
                        background: rgba(255, 255, 255, 0.1490196078) url("../../../public/images/refresh-yellow.svg") no-repeat;
                        background-position: center;
                    }
                    @include bp(tab) {
                        border: none;
                        border-left: 0.26667vw solid rgb(0, 0, 0);
                        border-radius: 0 1.06667vw 1.06667vw 0;
                        box-shadow: inset 0 0.26667vw 0 0 hsla(0, 0%, 100%, .3);
                        height: 5.86667vw;
                        line-height: 5.33333vw;
                        padding: 1.6vw 1.86667vw;
                        font-weight: 700;
                        height: 5.06667vw;
                        width: 5.06667vw;
                        display: inline-table;
                        background-size: 4.06667vw;
                        span {
                            height: 5.06667vw;
                            width: 5.06667vw;
                            display: block;
                        }
                    }
                }
                .account {
                    background-position: 0 -2025px;
                    height: 25px;
                    width: 110px;
                    background-image: url("../../../public/images/front-all-icon.png");
                    background-repeat: no-repeat;
                    position: relative;
                    margin-left: 15px;
                    text-indent: 27px;
                    margin-bottom: 0;
                    @include bp(mini) {
                        display: none;
                    }
                    a {
                        color: $menucolor1;
                        height: 100%;
                        font-size: 12px;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    ul {
                        li {
                            line-height: 25px;
                            h4, h5 {
                                line-height: 27px;
                                color: #3b5160;
                                border-bottom: 1px solid #7e97a7;
                                padding: 0 0 0 10px;
                            }
                            a {
                                color: #1e1e1e;
                                padding: 0 10px;
                                border-bottom: 1px solid #e0e6e6;
                                &:hover {
                                    text-decoration: none;
                                    background-color: #eff2f2;
                                }
                            }
                            &.logout {
                                padding: 7px 10px 5px;
                                a {
                                    background-color: #7e97a7;
                                    border-bottom-width: 0;
                                    color: #fff;
                                    font-weight: bold;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    span {
                                        width: 13px;
                                        height: 11px;
                                        margin-left: 5px;
                                        background-image: url("../../../public/images/logout.svg");
                                        background-repeat: no-repeat;
                                    }
                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
                p.loading-bar {
                    width: 80px;
                    height: 23px;
                    align-items: center;
                    margin-right: 0;
                    margin-bottom: 0;
                    padding: 0 10px 0 15px;
                    border-radius: 4px 0 0 4px;
                    font-weight: bold;
                    line-height: 1.4;
                    justify-content: space-between;
                    span {
                        background: #fff;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        opacity: 0;
                        animation: loadBar 0.8s ease infinite;
                        &:nth-child(1) {
                            animation-delay: 0s;
                        }
                        &:nth-child(2) {
                            animation-delay: 0.1s;
                        }
                        &:nth-child(3) {
                            animation-delay: 0.2s;
                        }
                        &:nth-child(4) {
                            animation-delay: 0.3s;
                        }
                        &:nth-child(5) {
                            animation-delay: 0.4s;
                        }
                        &:nth-child(6) {
                            animation-delay: 0.5s;
                        }
                        &:nth-child(7) {
                            animation-delay: 0.6s;
                        }
                        &:nth-child(8) {
                            animation-delay: 0.7s;
                        }
                    }
                }
            }
        }
    }
    .menu_part {
        margin-top: -3px;
        padding: 0 15px;
        background-image: linear-gradient(180deg, $menucolor1 8%, $menucolor2 100%);
        box-shadow: inset 0 1px 0 0 #ffffffbf;
        display: flex;
        justify-content: space-between;
        position: relative;
        @include bp(mini) {
            display: none;
        }
        ul {
            display: inline-block;
            position: relative;
            li {
                position: relative;
                border-right: 1px solid rgba(69, 32, 7, 0.2);
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;
                font-weight: 700;
                font-size: 12px;
                a {
                    padding: 5px 10px;
                    color: $menufontcolor;
                    line-height: 21px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    &.menu-drop {
                        &:after {
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 5px 3px 0 3px;
                            border-color: #a5a5a5 transparent transparent transparent;
                            margin-left: 10px;
                            vertical-align: middle;
                            content: "";
                            border-top-color: #222;
                        }
                    }
                    &.casino_class {
                        background-image: linear-gradient(#4b4b4b, #1e1e1e) !important;
                        color: #fff !important;
                        text-decoration: none !important;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 29px;
                            height: 29px;
                            background: url('../../assets/images/menu-tagnew.png') no-repeat;
                        }
                        &:hover {
                            background: #000000 !important;
                            color: #fff !important;
                            text-decoration: none !important;
                        }
                    }
                    img {
                        margin-left: 5px;
                    }
                }
                &:first-child {
                    border-left: 1px solid rgba(69, 32, 7, 0.2);
                }
                &>.selected {
                    box-shadow: inset 0 1px 3px 0 #34090980;
                    background-image: linear-gradient(180deg, $menuactivecolor1 0%, $menuactivecolor2 61%);
                    background-position: inherit;
                }
                &:hover {
                    a {
                        text-decoration: underline;
                    }
                }
                >ul {
                    display: none;
                    position: absolute;
                    left: 0;
                    width: max-content;
                    z-index: 1;
                    li {
                        border: 0;
                        border-bottom: 1px solid #383838;
                        display: block;
                        background-color: $menufontcolor;
                        color: $menucolor1;
                        &.selected {
                            background-color: $menucolor1;
                            a {
                                color: $menufontcolor;
                            }
                        }
                        a {
                            color: $menucolor1;
                        }
                        &:hover {
                            box-shadow: inset 0 1px 3px 0 #34090980;
                            background-image: linear-gradient(180deg, $menuactivecolor1 0%, $menuactivecolor2 61%) !important;
                            background-position: inherit;
                            background-color: $menucolor1 !important;
                            box-shadow: unset;
                            background-image: unset;
                            a {
                                color: $menufontcolor !important;
                            }
                        }
                        &.active {
                            box-shadow: inset 0 1px 3px 0 #34090980;
                            background-image: linear-gradient(180deg, $menuactivecolor1 0%, $menuactivecolor2 61%) !important;
                            background-position: inherit;
                            background-color: $menucolor1 !important;
                            box-shadow: unset;
                            background-image: unset;
                            a {
                                color: $menufontcolor !important;
                            }
                        }
                    }
                }
                &.logout {
                    float: right;
                    a {
                        display: flex;
                        align-items: center;
                        img {
                            margin-left: 5px;
                            width: 10px;
                            height: 10px;
                            object-fit: contain;
                        }
                    }
                }
                &.time_zone {
                    padding: 2px 10px;
                    border-left: 0;
                    span {
                        padding: 5px 10px;
                        display: inline-block;
                        font-weight: 400;
                    }
                }
            }
            a.one_click {
                border-top: 3px solid $menucolor1;
                margin-top: -3px;
                background-repeat: repeat-x;
                color: $menucolor1;
                background-image: linear-gradient(180deg, #444 13%, #1b1b1b 81%);
                background-position: inherit;
                span {
                    background-image: url("../../../public/images/front-all-icon.png");
                    background-position: -94px -8536px;
                    padding: 9px;
                    padding-right: 11px;
                }
                &.active {
                    color: #1e1e1e !important;
                    background-image: linear-gradient(180deg, #00c15d 0%, #009347 100%) !important;
                    background-position: inherit;
                    span {
                        background-position: -94px -8507px;
                    }
                    &:hover {
                        background-image: linear-gradient(180deg, #009347 100%, #00c15d 0%) !important;
                    }
                }
            }
            a.setting {
                span {
                    background: url("../../../public/images/setting.svg") no-repeat;
                }
            }
            &.setting-wrap {
                li {
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
    .mobilemenu {
        nav {
            display: none;
            position: fixed;
            bottom: 0;
            width: 100%;
            background-image: linear-gradient(-180deg, #243a48 20%, #172732 91%);
            color: #fff;
            z-index: 10;
            @include bp(mini) {
                display: block;
            }
            ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    width: 20%;
                    a {
                        width: 100%;
                        height: 50px;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 1.1;
                        padding: 7px;
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                        font-family: Helvetica, Tahoma, sans-serif;
                        @include bp(tab) {
                            color: #fff;
                            font-size: 3.2vw;
                            height: 13.33333vw;
                            line-height: 1.1;
                            padding: 1.86667vw 2.66667vw 0;
                        }
                        @include bp(tab) {
                            color: #fff;
                            font-size: 3.2vw !important;
                            height: 13.33333vw;
                            line-height: 1.1;
                            padding: 1.86667vw 2.66667vw 0;
                            font-weight: normal;
                        }
                        img {
                            width: 20px !important;
                            height: 20px;
                            margin: 0 auto 0.53333vw;
                            display: block;
                            @include bp(mob) {
                                height: 5.33333vw;
                                margin: 0 auto 0.53333vw;
                                width: 5.86667vw !important;
                                object-fit: contain;
                            }
                            @include bp(tab) {
                                height: 5.33333vw;
                                margin: 0 auto 0.53333vw;
                                width: 5.33333vw !important;
                                object-fit: contain;
                            }
                            &.icon-promote {
                                width: 16vw !important;
                                height: 6.4vw !important;
                                margin: 0 auto !important;
                                margin-top: -1vw !important;
                                margin-right: 0px !important;
                                z-index: 9999;
                                position: relative;
                                object-fit: contain;
                            }
                        }
                        @include bp(mini) {
                            font-size: 12px;
                        }
                        @include bp(mob) {
                            width: 16vw;
                            font-weight: 700;
                            color: #fff;
                            font-size: 3.2vw;
                            height: 13.33333vw !important;
                            line-height: 1.1;
                            padding: 1.86667vw 2.66667vw 0;
                            font-weight: normal;
                        }
                    }
                    & .main-nav, &.game-nav {
                        position: relative;
                        img {
                            width: 28px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: -12px;
                            width: 100%;
                            height: 25%;
                            background-image: url("../../../public/images/mainav-shape1.svg");
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            pointer-events: none;
                            z-index: -1;
                            @include bp(mob) {
                                top: -3.266667vw;
                                width: 100%;
                                height: 4.5333333333vw;
                            }
                        }
                    }
                    &.game-nav {
                        &::before {
                            @include bp(tab) {
                                background-repeat: no-repeat;
                                background-size: 100% 100%;
                                content: "";
                                height: 4.5333333333vw;
                                left: 3px;
                                pointer-events: none;
                                position: absolute;
                                top: -4.2666666667vw;
                                width: 100%;
                                z-index: -1;
                            }
                        }
                        a {
                            @include bp(tab) {
                                color: #fff;
                                font-size: 3.2vw;
                                line-height: 1.1;
                                padding: 0.86667vw 0.66667vw 0;
                                width: 16vw;
                                height: 16.8vw !important;
                                margin-top: -4.5333333333vw;
                                padding-top: 1.3333333333vw;
                                .icon-promote {
                                    height: 14.266667vw !important;
                                    margin: 0.133333vw auto 0.866667vw 1.3vw !important;
                                    width: 26.866667vw !important;
                                    z-index: 99;
                                    object-fit: cover !important;
                                }
                            }
                            @include bp(mob) {
                                .icon-promote {
                                    margin: 0.133333vw auto 0.866667vw 1.6vw !important;
                                }
                            }
                        }
                    }
                    &.main-nav {
                        &::before {
                            @include bp(mini) {
                                content: unset;
                            }
                        }
                    }
                    &.select {
                        background-image: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
                        &::before {
                            background-image: url("../../../public/images/mainav--active-shape1.svg");
                        }
                    }
                }
            }
        }
    }
}
.tag-live {
    display: flex;
    position: absolute;
    top: -6px;
    right: 3px;
    min-width: 33px;
    height: 12px;
    border-radius: 3px;
    padding-right: 3px;
    color: #ffffff;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    background-image: linear-gradient(180deg, #fb3434 0%, #e80505 100%);
    box-shadow: 0 1px 3px 0 #00000080;
    @include bp(tab) {
        background-image: linear-gradient(180deg, #fb3434, #e80505);
        border-radius: 0.8vw;
        box-shadow: 0 0.26667vw 0.8vw 0 rgba(0, 0, 0, .5);
        color: #fff;
        font-size: 2.66667vw;
        height: 3.2vw;
        line-height: 3.2vw;
        min-width: 9.33333vw;
        padding-right: 1.33333vw;
        position: absolute;
        right: 1.33333vw;
        text-align: center;
        top: 1.06667vw;
        align-items: center;
        justify-content: center;
    }
    strong {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 100%;
        margin-right: 3px;
        padding: 0 2px;
        border-radius: 3px 0px 0px 3px;
        background-image: linear-gradient(180deg, #fff 0%, #eee 89%);
        @include bp(tab) {
            background-image: linear-gradient(180deg, #fff, #eee 89%);
            border-radius: 0.8vw 0 0 0.8vw;
            flex: 1 1;
            height: 100%;
            margin-right: 1.33333vw;
            padding: 0 0.53333vw;
        }
        &::before {
            content: '';
            display: block;
            width: 14px;
            height: 8px;
            background: url("../../../public/images/live.svg") no-repeat;
            background-size: contain;
            animation: twinkling 1.4s infinite ease;
            @include bp(tab) {
                background-size: contain;
                height: 2.13333vw;
                width: 3.73333vw;
            }
        }
    }
}
.gmt {
    font-size: 11px;
    border-left: 1px solid #c5d0d7;
    padding: 0 5px;
    float: right;
}
.slip_set-pop {
    &:not(:target) {
        display: none;
    }
    position: absolute;
    top: 100px;
    right: 0;
    width: 282px;
    z-index: 99;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 5px #00000080;
    @include bp(mini) {
        top: 60px;
        right: 15px;
    }
    @include bp(tab) {
        top: 13vw;
    }
    .set-content {
        min-height: 100px;
        background-color: #e0e6e6;
        font-size: 11px;
        color: #3b5160;
        padding: 10px;
        border-radius: 0 0 4px 4px;
        .stake-set, .odds-set {
            position: relative;
            border-bottom: 1px solid #7e97a7;
            box-shadow: 0 1px 0 #ffffffcc;
            line-height: 15px;
            padding-bottom: 2px;
            margin-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            dt {
                margin-bottom: 5px;
                font-weight: bold;
                display: block;
                width: 100%;
            }
            dd {
                width: 50px;
                margin: 0 5px 5px 0;
                &.col-defult {
                    width: 100%;
                }
                &.col-edit {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    width: 42px;
                    margin-right: 0;
                    .btn-edit {
                        border: 1px solid #7e97a7;
                        box-shadow: inset 0 1px 0 0 #ffffffcc;
                        color: #3b5160;
                        height: 43px;
                        line-height: 43px;
                        border-radius: 4px;
                        display: block;
                        text-align: center;
                        span {
                            background-image: url("../../../public/images/front-all-icon.png");
                            background-position: -101px -12343px;
                            height: 9px;
                            width: 9px;
                            display: inline-block;
                        }
                    }
                }
            }
            .stake-input {
                width: 29.16667%;
                font-size: 11px;
                margin-left: 5px;
            }
            textarea {
                height: 20px;
                line-height: 20px;
                padding: 0 5px;
                margin: 0 5px 0 0;
            }
        }
        .stake-set {
            dd {
                float: left;
            }
        }
        .odds-set {
            dd {
                width: 100%;
                input {
                    margin-bottom: 0;
                    vertical-align: middle;
                }
                label {
                    cursor: pointer;
                }
            }
        }
        .btn-wrap {
            width: 100%;
            display: flex;
            position: unset;
            background-color: transparent;
            li {
                width: 50%;
                border: none;
                a {
                    width: 80%;
                    margin: 0;
                    font-weight: bold;
                    line-height: 23px;
                    background: #eee url("../../../public/images/bg-btn.gif") repeat-x;
                    text-align: center;
                    justify-content: center;
                    &:hover {
                        text-decoration: none;
                        background: #eee url("../../../public/images/bg-btn_hover.gif") repeat-x;
                    }
                }
                &.col-send {
                    a {
                        width: 100%;
                        color: #f8d61c;
                        background: #444 url("../../../public/images/btn-select-skyEX.jpg") repeat-x;
                        border-color: #222;
                        &:hover {
                            text-decoration: none;
                            background: #444 url("../../../public/images/bg-send_hover-skyEX.png") repeat-x;
                        }
                    }
                }
            }
        }
        .btn {
            line-height: 18px;
            font-weight: normal;
            font-size: 11px;
            margin: 0;
            border: 1px solid #bbb;
            background-color: #fff;
            padding: 0;
            width: 100%;
            &.select {
                color: #f8d61c;
                background: #444 url("../../../public/images/btn-select-skyEX.jpg") repeat-x;
                border-color: #222;
            }
        }
    }
}
@keyframes twinkling {
    0%,
    100% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
}
@keyframes loadBar {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 1;
        transform: scale(1.4);
    }
    100% {
        opacity: 0.1;
    }
}
.a-open_bets, .a-open_tv {
    width: 70px;
    border-color: #000;
    background-color: rgba(51, 51, 51, 0.4);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 40%);
    font-weight: bold;
    padding: 7px;
    border-left-width: 0;
    border-radius: 0 1.06667vw 1.06667vw 0;
    display: flex;
    height: 30px;
    align-items: center;
    margin-left: -5px;
    font-weight: bold;
    @include bp(mini) {
        background-color: rgba(255, 255, 255, 0.1490196078);
        border-color: #000;
        border: 1px solid rgb(0 0 0);
    }
    @include bp(tab) {
        border-left-width: 0;
        border-radius: 0 1.06667vw 1.06667vw 0;
        padding: 1.86667vw 0 1.33333vw;
        margin-left: -17px;
        max-width: 31.2vw;
        min-width: 29.33333vw;
        border: 0.26667vw solid rgb(0, 0, 0);
        box-shadow: inset 0 0.26667vw 0 0 hsla(0, 0%, 100%, .3);
        font-weight: 700;
        // height: 5.86667vw;
        height: inherit;
        line-height: 5.33333vw;
        flex: 1 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.46667vw;
    }
}
#openBetsBtn {
    img {
        background-image: url("../../../public/images/dollar.svg");
        display: block;
        width: 16px;
        height: 16px;
        float: left;
        margin-right: 5px;
        background-size: 100%;
        @include bp(tab) {
            width: 5.33333vw;
            height: 5.3vw;
            margin-right: 1.33333vw;
        }
    }
}
.icon-promote {
    width: 10vw !important;
    height: 7.4vw !important;
    margin: 0 auto !important;
    // margin-left: -1.8666666667vw !important;
    z-index: 99;
}
.menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    svg {
        font-size: calc(1.275rem + .3vw) !important;
        width: 27px;
        height: 27px;
    }
}
.sidebar-wrapper {
    background: #f0f8ff;
    bottom: 0;
    // height: 100vh;
    left: 0;
    padding: 16px;
    width: 341px;
    z-index: 99999;
    position: absolute;
    top: 0;
    transition: all 2s ease;
    width: 288px;
    // height: 100vh;
    overflow: auto;
    padding-top: 0;
    @include bp(480px) {
        height: 100vh;
        position: fixed;
    }
    .top-sidebar {
        padding-top: 16px;
        justify-content: space-between !important;
        padding-left: 0.25rem !important;
        margin-bottom: 1rem !important;
        display: flex !important;
        position: sticky;
        top: 0;
        background: #f0f8ff;
        strong {
            font-family: Inter;
            font-size: 20px;
        }
        button {
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
    ul {
        background: #fff;
        border-radius: 13px;
        overflow: hidden;
        li {
            border-bottom: 1px solid #0000001f;
            &:last-child {
                border: none;
            }
            a {
                align-items: center;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBR0KMSkkfpugAAACvElEQVRYw+2UX0iTURiHf+ebqExMszBDmIX0z1BM0hZI6o3OLFNTg0DQiRqBzWEkotvHZmniFJ0SYejUElORFFaWmFLZCByBmNAo0fTCQkmFLKbze7sIb4oucjOk9twezvs+v3PecwAnTpz8n6iP1Z5sbo+LU+fVlRvSlpfV1ro8w1OVCiAiYuxveXCb3chei44y+doaGjCEwx4euIEgzGi1fES9d2tYW1v+I71ML3Nz2+oAdp8UT3pZy6fYWFqkV+Tb1YVaaNDi5YUaWBFhMq29Y0N0LympYu+Vx/KK+fltF2AD1aH6j4b44GAWKAwARiOOYxHpEgkuYAajk5PCDK0IAQkJ1+MLbmcXWSyO6rvpEfqZMku+X1b/+LjtlvDEpVoqRROusptmMzohQXhgINfJLnPnTSbVN72suTgqatsF2KB8n7I9I2hu7mvMahP3LDqazrF+jPf2QoJhvPDxYSp6yD4MDKgNdbOGjowMe/tt+W/BE088cRyF7XLbX6jT4SxVUbBSCR61aCZCEU2wA2lpWl2BOLO5p+dP6zv8Bn7ThkOAMEbVYvEvSwvsvZBrs222sstWKV87WPm2qdLTkxrcd3KnOzoQAg3SExJQjFVkWq0UhEV0yeVlFkWIXNrXt20ClMTUSFtD/P1FsyJfgYxGzEOD9NBQTCGPlS0sMHf4rZ9KTtZaFEvZ/SMj9vZz2AjxpF9pDYmIEO0WfRFyzWZcxF3sCQ3FGVaFlxMTrE1wo8nwcA1TLGUH2i/usAA86QcMJ1JSSEEy4cHwMI4gBx5+flSIKqYbHGTh67O4ExmpYUqWxaanHSVudwCeai+1jCUmUgmtoqO7G95IxXOxmHUDlNrYyO34PDv1Jj7+h/jSkqPFN7DjDXDTwn1XV5SQjQ1ZrTSKHEhLS7VahVieVVMDBsVWSTtx4uQf4jsIRA4ZfaraZAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wNS0yOVQxMDo0OTo0MSswMDowMPHXZGEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMjlUMTA6NDk6NDErMDA6MDCAitzdAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIzLTA1LTI5VDEwOjQ5OjQxKzAwOjAw15/9AgAAAABJRU5ErkJggg==);
                background-repeat: no-repeat;
                color: #000;
                display: flex;
                font-family: Inter;
                padding: 13px 17px;
                background-position: right 11px top 17px;
                background-size: 20px;
                font-size: 15px;
                svg {
                    font-size: 20px;
                    margin-right: 12px;
                }
            }
            .rounded-count {
                background-color: #ffc800 !important;
                border: none !important;
                padding: 0.5rem !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                margin-left: 0.5rem !important;
                font-size: 1rem !important;
                border-radius: 0.375rem;
                color: #fff;
            }
        }
    }
    .logout-button {
        background: #d4e0e5;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBR0KMSkkfpugAAACvElEQVRYw+2UX0iTURiHf+ebqExMszBDmIX0z1BM0hZI6o3OLFNTg0DQiRqBzWEkotvHZmniFJ0SYejUElORFFaWmFLZCByBmNAo0fTCQkmFLKbze7sIb4oucjOk9twezvs+v3PecwAnTpz8n6iP1Z5sbo+LU+fVlRvSlpfV1ro8w1OVCiAiYuxveXCb3chei44y+doaGjCEwx4euIEgzGi1fES9d2tYW1v+I71ML3Nz2+oAdp8UT3pZy6fYWFqkV+Tb1YVaaNDi5YUaWBFhMq29Y0N0LympYu+Vx/KK+fltF2AD1aH6j4b44GAWKAwARiOOYxHpEgkuYAajk5PCDK0IAQkJ1+MLbmcXWSyO6rvpEfqZMku+X1b/+LjtlvDEpVoqRROusptmMzohQXhgINfJLnPnTSbVN72suTgqatsF2KB8n7I9I2hu7mvMahP3LDqazrF+jPf2QoJhvPDxYSp6yD4MDKgNdbOGjowMe/tt+W/BE088cRyF7XLbX6jT4SxVUbBSCR61aCZCEU2wA2lpWl2BOLO5p+dP6zv8Bn7ThkOAMEbVYvEvSwvsvZBrs222sstWKV87WPm2qdLTkxrcd3KnOzoQAg3SExJQjFVkWq0UhEV0yeVlFkWIXNrXt20ClMTUSFtD/P1FsyJfgYxGzEOD9NBQTCGPlS0sMHf4rZ9KTtZaFEvZ/SMj9vZz2AjxpF9pDYmIEO0WfRFyzWZcxF3sCQ3FGVaFlxMTrE1wo8nwcA1TLGUH2i/usAA86QcMJ1JSSEEy4cHwMI4gBx5+flSIKqYbHGTh67O4ExmpYUqWxaanHSVudwCeai+1jCUmUgmtoqO7G95IxXOxmHUDlNrYyO34PDv1Jj7+h/jSkqPFN7DjDXDTwn1XV5SQjQ1ZrTSKHEhLS7VahVieVVMDBsVWSTtx4uQf4jsIRA4ZfaraZAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wNS0yOVQxMDo0OTo0MSswMDowMPHXZGEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMjlUMTA6NDk6NDErMDA6MDCAitzdAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIzLTA1LTI5VDEwOjQ5OjQxKzAwOjAw15/9AgAAAABJRU5ErkJggg==);
        background-position: right 11px top 9px;
        background-repeat: no-repeat;
        border: none;
        border-radius: 10px;
        color: #000;
        font-size: 19px;
        padding: 10px 22px;
        text-align: left;
        width: 100%;
        cursor: pointer;
        @include bp(mob) {
            font-size: 17px;
        }
        svg {
            margin-right: 0.5rem !important;
            vertical-align: middle;
        }
    }
    .time-zone {
        align-items: center;
        color: #969ea1;
        display: flex;
        font-size: 17px;
        justify-content: center;
        padding-top: 1.5rem !important;
        text-align: center !important;
        @include bp(mob) {
            font-size: 15px;
        }
    }
}
.overlay {
    background: #0007;
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    width: 100vw;
    z-index: 99;
}